import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import getUrl from "../../../utilities/apiConstant";
import { Spin, notification } from "antd";
import ApplicationPage from "./ApplicationPage";

const IndexApplication = () => {
  const [applicationData, setApplicationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchApplicationData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${getUrl("applicationForm")}`, {
          withCredentials: true,
        });
        setApplicationData(response.data.data || []);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        notification.error({ message: "Something went wrong !" });
      }
    };
    fetchApplicationData();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>
        <Spin />
      </div>
    );
  }
  return (
    <>
      {" "}
      <Helmet>
        <title>
          Application Tracking - Online College Admission Form | Entrancezone
        </title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>{" "}
      <ApplicationPage data={applicationData} />
    </>
  );
};

export default IndexApplication;
