import React, { useState, useEffect } from "react";
import { Form, Input, Select, Divider, Icon } from "antd";
import _get from "lodash/get";
// import CommonSearch from "./CommonSearch";
import CustomDropdown from "./CustomDropdown";

const FormItem = Form.Item;
const { Option } = Select;

const TwelfthClass = (props) => {
  const {
    meta,
    data = {},
    type,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
    setFieldTouched,
  } = props;
  const [marksType, setMarksType] = useState(
    values?.[type]?.marks_type === "Percentage" ? "%" : "CGPA"
  );

  const boards = meta && meta.boards ? meta.boards : [];
  const states = meta && meta.states ? meta.states : [];
  const isTenth = type === "tenth";
  const currentYear = new Date().getFullYear();

  const yearSelector = () => {
    let optionArray = [];
    for (let i = currentYear + 2; i >= 1990; i--) {
      let optionData = (
        <Option value={i} key={`k${i}`}>
          {i}
        </Option>
      );
      optionArray.push(optionData);
    }
    return optionArray;
  };

  return (
    <section className="cpa-form__wrapper">
      <p
        style={{
          color: "#3c1a5b",
          gridColumn: "1/-1",
        }}
        className="heading-3"
      >
        {isTenth ? "CLASS 10 : " : "CLASS 12 : "}
      </p>
      {isTenth ? null : (
        <FormItem
          label="APPEARING/PASSING STATUS"
          validateStatus={
            touched?.[type]?.is_appearing && errors?.[type]?.is_appearing
              ? "error"
              : values?.[type]?.is_appearing
              ? "success"
              : null
          }
          help={touched?.[type]?.is_appearing && errors?.[type]?.is_appearing}
          required
          // hasFeedback={Boolean(
          //   touched?.[type]?.is_appearing && errors?.[type]?.is_appearing
          // )}
        >
          <Select
            size="large"
            value={
              values?.[type]?.is_appearing === undefined
                ? undefined
                : values?.[type]?.is_appearing
                ? 1
                : 0
            }
            placeholder="Graduation Status"
            onChange={(newValue) => {
              const value = newValue ? true : false;
              setFieldValue(`${type}.is_appearing`, value, true);
              setFieldTouched(`${type}.is_appearing`, false);
              if (value) {
                setFieldValue(`${type}.marks_type`, undefined, true);
                setFieldValue(`${type}.marks`, undefined, true);
                setFieldValue(`${type}.maximum_marks`, undefined, true);
              }
            }}
            // onBlur={handleBlur}
          >
            <Option value={1}>Appearing</Option>
            <Option value={0}>Passed</Option>
          </Select>
        </FormItem>
      )}
      <FormItem
        label="APPEARING/PASSING YEAR"
        validateStatus={
          touched?.[type]?.year && errors?.[type]?.year
            ? "error"
            : values?.[type]?.year
            ? "success"
            : null
        }
        help={touched?.[type]?.year && errors?.[type]?.year}
        required
        // hasFeedback={Boolean(touched?.[type]?.year && errors?.[type]?.year)}
      >
        <Select
          name={`${type}.year`}
          placeholder="Year of Appearing/Passing"
          showSearch
          onChange={(value) => {
            setFieldValue(`${type}.year`, value, true);
            setFieldTouched(`${type}.year`, false);
          }}
          value={values?.[type]?.year}
          // onBlur={handleBlur}
          size="large"
        >
          {yearSelector()}
        </Select>
      </FormItem>
      <FormItem
        label="BOARD"
        validateStatus={
          touched?.[type]?.school_board && errors?.[type]?.school_board
            ? "error"
            : values?.[type]?.school_board
            ? "success"
            : null
        }
        help={touched?.[type]?.school_board && errors?.[type]?.school_board}
        required
        // hasFeedback={Boolean(
        //   touched?.[type]?.school_board && errors?.[type]?.school_board
        // )}
      >
        <Select
          name={`${type}.school_board`}
          size="large"
          placeholder="Board (CBSE/ICSE)"
          showSearch
          optionFilterProp="children"
          value={values?.[type]?.school_board}
          onChange={(value) => {
            setFieldValue(`${type}.school_board`, value, true);
            setFieldTouched(`${type}.school_board`, false);
          }}
          // onBlur={handleBlur}
        >
          {boards.map((item, index) => {
            return (
              <Option value={item.value} key={`s${index}`}>
                {item.value}
              </Option>
            );
          })}
        </Select>
      </FormItem>
      {isTenth ? null : (
        // <CommonSearch
        //   {...props}
        //   type="stream"
        //   graduationType="twelfth"
        //   optionType="stream"
        //   options={["Science", "Commerce", "Arts"]}
        // />
        <CustomDropdown
          {...props}
          graduationType="twelfth"
          optionType="stream"
          options={["Science", "Commerce", "Arts"]}
        />
        // <FormItem
        //   label="STREAM"
        //   validateStatus={
        //     touched?.[type]?.stream && errors?.[type]?.stream
        //       ? "error"
        //       : values?.[type]?.stream
        //       ? "success"
        //       : null
        //   }
        //   help={touched?.[type]?.stream && errors?.[type]?.stream}
        //   required
        //   // hasFeedback={Boolean(
        //   //   touched?.[type]?.stream && errors?.[type]?.stream
        //   // )}
        // >
        //   <Select
        //     name={`${type}.stream`}
        //     showSearch
        //     optionFilterProp="children"
        //     placeholder="Science/Arts"
        //     onChange={(value) => {
        //       setFieldValue(`${type}.stream`, value, true);
        //       setFieldTouched(`${type}.stream`, false);
        //     }}
        //     value={values?.[type]?.stream}
        //     // onBlur={handleBlur}
        //     size="large"
        //     filterOption={(input, option) =>
        //       option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
        //     }
        //     dropdownRender={(menu) => (
        //       <div>
        //         {menu}
        //         {!searchData.length ? (
        //           <div style={{}}>
        //             <Divider style={{ margin: "4px 0" }} />
        //             <p style={{ marginLeft: "1rem" }}>
        //               Please add stream manually, if not found
        //             </p>
        //             <div
        //               style={{ padding: "4px 8px", cursor: "pointer" }}
        //               onMouseDown={(e) => e.preventDefault()}
        //               onClick={addItem}
        //             >
        //               <Icon type="plus" /> Add item
        //             </div>
        //           </div>
        //         ) : null}
        //       </div>
        //     )}
        //   >
        //     <Option key="science" value="Science">
        //       Science
        //     </Option>
        //     <Option key="commerce" value="Commerce">
        //       Commerce
        //     </Option>
        //     <Option key="arts" value="Arts">
        //       Arts
        //     </Option>
        //   </Select>
        // </FormItem>
      )}
      <FormItem
        label="SCHOOL"
        validateStatus={
          touched?.[type]?.school && errors?.[type]?.school
            ? "error"
            : values?.[type]?.school
            ? "success"
            : null
        }
        required
        help={touched?.[type]?.school && errors?.[type]?.school}
        // hasFeedback={Boolean(touched?.[type]?.school && errors?.[type]?.school)}
      >
        <Input
          name={`${type}.school`}
          placeholder="School Name"
          size="large"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values?.[type]?.school}
        />
      </FormItem>
      <FormItem
        label="STATE"
        required
        validateStatus={
          touched?.[type]?.state_id && errors?.[type]?.state_id
            ? "error"
            : values?.[type]?.state_id
            ? "success"
            : null
        }
        help={touched?.[type]?.state_id && errors?.[type]?.state_id}
        // hasFeedback={Boolean(touched?.[type]?.state && errors?.[type]?.state)}
      >
        <Select
          name={`${type}.state_id`}
          placeholder="State"
          showSearch
          optionFilterProp="children"
          size="large"
          onChange={(value) => {
            setFieldValue(`${type}.state_id`, value, false);
            setFieldTouched(`${type}.state_id`, false);
          }}
          value={values?.[type]?.state_id}
          filterOption={(input, option) =>
            option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
          // onBlur={handleBlur}
        >
          {states.map((item, index) => {
            return (
              <Option value={item.id} key={item.value}>
                {item.value}
              </Option>
            );
          })}
        </Select>
      </FormItem>
      {values[type]?.is_appearing ||
      values[type]?.is_appearing === undefined ? null : (
        <React.Fragment>
          <FormItem
            label="MARKING SCHEME"
            validateStatus={
              touched?.[type]?.marks_type && errors?.[type]?.marks_type
                ? "error"
                : values?.[type]?.marks_type
                ? "success"
                : null
            }
            help={touched?.[type]?.marks_type && errors?.[type]?.marks_type}
            required
            // hasFeedback={Boolean(
            //   touched?.[type]?.marks_type && errors?.[type]?.marks_type
            // )}
          >
            <Select
              placeholder="CGPA/Percentage"
              name={`${type}.marks_type`}
              size="large"
              value={values?.[type]?.marks_type}
              onChange={(value) => {
                const data = value === "Percentage" ? "%" : "CGPA";
                const totalMarks = value === "Percentage" ? 100 : 10;
                setMarksType(data);
                setFieldValue(`${type}.marks_type`, value);
                setFieldValue(`${type}.maximum_marks`, totalMarks);
                setFieldValue(`${type}.marks`, undefined);
              }}
              onBlur={handleBlur}
            >
              <Option value="Percentage">Percentage</Option>
              <Option value="CGPA">CGPA</Option>
            </Select>
          </FormItem>

          <FormItem
            label="MARKS OBTAINED"
            validateStatus={
              touched?.[type]?.marks && errors?.[type]?.marks
                ? "error"
                : values?.[type]?.marks
                ? "success"
                : null
            }
            help={touched?.[type]?.marks && errors?.[type]?.marks}
            required
            // hasFeedback={Boolean(
            //   touched?.[type]?.marks && errors?.[type]?.marks
            // )}
          >
            <Input
              addonAfter={marksType}
              name={`${type}.marks`}
              placeholder="Marks"
              size="large"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.[type]?.marks}
            />
          </FormItem>
          {/* <FormItem
            label="TOTAL MARKS"
            validateStatus={
              touched?.[type]?.maximum_marks && errors?.[type]?.maximum_marks
                ? "error"
                : values?.[type]?.maximum_marks
                ? "success"
                : null
            }
            help={
              touched?.[type]?.maximum_marks && errors?.[type]?.maximum_marks
            }
        required
            // hasFeedback={Boolean(
            //   touched?.[type]?.maximum_marks && errors?.[type]?.maximum_marks
            // )}
          >
            <Input
              addonAfter={marksType}
              value={values?.[type]?.maximum_marks}
              name={`${type}.maximum_marks`}
              placeholder="Total Marks"
              size="large"
              onBlur={handleBlur}
            />
          </FormItem> */}
        </React.Fragment>
      )}
    </section>
  );
};

export default TwelfthClass;
