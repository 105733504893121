import React, { useState } from "react";
import ApplicationCard from "./ApplicationCard";
import CounselorSection from "./CounselorSection";
import Breadcrumbs from "../../BreadCrumbs";
const ApplicationPage = ({ data }) => {
  const [showCounselorSection, setCounselorSection] = useState(false);
  const [counselorData, setCounselorData] = useState({});

  const handleCounselorSection = (show, counselorData) => {
    setCounselorSection(show);
    setCounselorData(counselorData);
  };

  if (!data.length) {
    return (
      <>
        <Breadcrumbs />
        <h2 className="heading-2 application-cards__heading">
          Applied Applications
        </h2>
        <p>No application found!</p>
      </>
    );
  }
  return (
    <>
      {!showCounselorSection ? (
        <div>
          <Breadcrumbs />
          <h2 className="heading-2 application-cards__heading">
            Applied Applications
          </h2>
          <div style={{ height: "72vh", overflowY: "auto" }}>
            {data.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ApplicationCard
                    data={item}
                    handleCounselorSection={handleCounselorSection}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      ) : (
        <CounselorSection
          data={counselorData}
          handleCounselorSection={handleCounselorSection}
        />
      )}
    </>
  );
};

export default ApplicationPage;
