import React, { useState } from "react";
import axios from "axios";
import { Button, Modal } from "antd";
import LightBox from "react-image-lightbox";
import UploadDocument from "./UploadDocument";
import getUrl from "../../utilities/apiConstant";
import "react-image-lightbox/style.css";

const ListOfDocuments = ({ edu_id, data: item = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState(undefined);

  const handleViewDocument = async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: getUrl("uploads"),
        params: {
          document_id: id,
        },
        withCredentials: true,
      });
      const isPdf = response?.data?.data
        ? response.data.data.toString().includes(".pdf")
        : false;

      if (isPdf) {
        window.open(response.data.data, "_blank");
        return;
      }
      setDocumentUrl(response?.data?.data.toString());
      setIsOpen(response?.data?.data ? true : false);
    } catch (error) {}
  };

  return (
    <>
      <div className="document-section__single-element">
        <span>
          <span
            style={{
              marginRight: "1rem",
              color: "black",
              fontWeight: "500",
            }}>
            Document:
          </span>
          {item.document_type}
        </span>
        <div style={{ display: "flex" }}>
          <UploadDocument edu_id={edu_id} layout="replace" />
          <Button
            onClick={() => handleViewDocument(item.id)}
            style={{
              height: "3rem",
              border: "none",
              backgroundColor: "#3c1a58",
              color: "white",
            }}>
            View
          </Button>
        </div>
      </div>
      {isOpen ? (
        <>
          <LightBox
            mainSrc={documentUrl}
            onCloseRequest={() => {
              setIsOpen(false);
              setDocumentUrl(undefined);
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default ListOfDocuments;
