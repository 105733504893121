import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import DashboardUpgradeInfoCard from "./DashboardUpgradeInfoCard";
import { Context } from "../../../utilities/Context";
import DashboardPricing from "./DashboardPricing";
import { notification } from "antd";
import BreadCrumbs from "../../BreadCrumbs";
const credit = require("../../../img/credits.svg");
const current_pack = require("../../../img/current-pac.svg");
const valid_till = require("../../../img/valid-till.svg");
const tick = require("../../../img/Tick.svg");
const benefits = [
  "Save time and money.",
  "Apply to as many colleges and courses you want.",
  "Get professional help throughout your admissions.",
  "Track all your applications from one place."
];
const DashboardUpgradeInfo = ({ location }) => {
  const { userData, active_package_id } = useContext(Context);

  const { user } = userData;
  const [nextScreen, setNextScreen] = useState(undefined);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.get("type") && params.get("msg")) {
        if (params.get("type") === "success")
          notification.success({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "fail")
          notification.error({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "pending")
          notification.warning({ message: decodeURI(params.get("msg")) });
      } else return;
    }
    if (!active_package_id) {
      setNextScreen(true);
      return;
    }
    setNextScreen(false);
  }, [active_package_id]);

  const totalBenefits = benefits.map((data, index) => (
    <div key={index} style={{ display: "flex", margin: "2rem 0" }}>
      <img
        alt="check"
        src={tick}
        style={{ width: "2rem", height: "2rem", marginRight: "1.5rem" }}
      />
      <p className="dashboard-upgrade-info-para">{data}</p>
    </div>
  ));
  return (
    <>
      <Helmet>
        <title>
          Packages Upgrade - Online College Admission Form | Entrancezone
        </title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      <BreadCrumbs />
      {nextScreen ? (
        <DashboardPricing location={location} />
      ) : (
        <div className="dashboard-upgrade-info-main-div">
          <div className="dashboard-upgrade-info-main-div__item_0">
            Upgrade Plan
          </div>
          <div className="dashboard-upgrade-info-main-div__item_1">
            <div className="dashboard-upgrade-info-main-div__item_1__child">
              <img
                className="dashboard-upgrade-info-main-div__item_1__child__image"
                alt="credits"
                src={current_pack}
              />
              <div className="dashboard-upgrade-info-main-div__item_1__child__div">
                <div className="dashboard-upgrade-info-main-div__item_1__child__div__child_1">
                  Current Pack
                </div>
                <div className="dashboard-upgrade-info-main-div__item_1__child__div__child_2">
                  {user.active_package_name ? user.active_package_name : "-"}
                </div>
              </div>
            </div>
            <div className="dashboard-upgrade-info-main-div__item_1__child">
              <img
                className="dashboard-upgrade-info-main-div__item_1__child__image"
                alt="credits"
                src={credit}
              />
              <div className="dashboard-upgrade-info-main-div__item_1__child__div">
                <div className="dashboard-upgrade-info-main-div__item_1__child__div__child_1">
                  Credits
                </div>
                <div className="dashboard-upgrade-info-main-div__item_1__child__div__child_2">
                  {user.forms_left}
                </div>
              </div>
            </div>
            <div className="dashboard-upgrade-info-main-div__item_1__child">
              <img
                className="dashboard-upgrade-info-main-div__item_1__child__image"
                alt="credits"
                src={valid_till}
              />
              <div className="dashboard-upgrade-info-main-div__item_1__child__div">
                <div className="dashboard-upgrade-info-main-div__item_1__child__div__child_1">
                  Valid Till
                </div>
                <div className="dashboard-upgrade-info-main-div__item_1__child__div__child_2">
                  {user.package_expiry ? user.package_expiry : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-upgrade-info-main-div__item_2">
            <h2 className="dashboard-upgrade-info-main-div__item_2__h1">
              Why Upgrade ?{" "}
            </h2>
            {totalBenefits}
          </div>
          <DashboardUpgradeInfoCard setNextScreen={setNextScreen} />
        </div>
      )}
    </>
  );
};
export default DashboardUpgradeInfo;
