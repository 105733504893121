import React, { useState, useRef } from "react";
import { Carousel, Icon } from "antd";
import Card from "../FilterPage/Card/AppliedFormCard";

const HomeCarousel = ({ data, admission_mode }) => {
  const slider = useRef();
  const [showButtons, setShowButtons] = useState("hidden");

  const previous = () => {
    slider.current.prev();
  };

  const next = () => {
    slider.current.next();
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <div
      className="custom-carousel"
      onMouseEnter={() => setShowButtons("visible")}
      onMouseLeave={() => setShowButtons("hidden")}
    >
      <Carousel
        ref={slider}
        {...settings}
        className="college-apply-card-carousel"
      >
        {data.map((element, index) => (
          <Card key={index} data={element} mode={admission_mode} homepage />
        ))}
      </Carousel>
      <div
        className="previous-button"
        onClick={() => previous()}
        style={{ visibility: showButtons }}
      >
        <div style={{ transform: "translate(2rem, 2.2rem)" }}>
          <Icon type="left" style={{ fontSize: "2.2rem" }} />
        </div>
      </div>
      <div
        className="nxt-button"
        onClick={() => next()}
        style={{ visibility: showButtons }}
      >
        <div style={{ transform: "translate(2.6rem, 2.2rem)" }}>
          <Icon type="right" style={{ fontSize: "2.2rem" }} />
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
