import React, { useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
import getUrl from "./utilities/apiConstant";
import { UserProvider } from "./utilities/Context";
import routes from "./routes";
import Navigation from "./components/Navigation";
import { Spin } from "antd";
import "./styles/allless.less";

const App = () => {
  const [userData, setUserData] = useState({});
  const [isLoading, setLoading] = useState(true);

  ReactGA.initialize("UA-83305228-11", {
    debug: process.env.REACT_APP_CUSTOM_NODE_ENV !== "production",
  });
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${getUrl("user")}`, {
          withCredentials: true,
        });
        setUserData(
          response && response.data && response.data.data
            ? response.data.data
            : {}
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  return (
    <div className="container">
      {isLoading ? (
        <div className="home-page-spinner">
          <Spin />
        </div>
      ) : (
        <UserProvider data={userData}>
          <Router>
            <Navigation />
            {renderRoutes(routes)}
          </Router>
        </UserProvider>
      )}
    </div>
  );
};

export default App;
