import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import getUrl from "../utilities/apiConstant";
import MultiScreens from "../components/MultiScreens";
import WhyApply from "../components/WhyApply";
import Process from "../components/Process";
// import Pricing from "../components/PaymentGateway/Pricing";
// import { SuccessfulScreen } from "../components/CPAForm/FormSteps/SuccessfulScreen";
import Footer from "./Footer";
import ApplicationForms from "../components/HomePage/ApplicationForms";
import { notification, Spin } from "antd";

const HomePage = ({ location }) => {
  const [homeData, setHomeData] = useState(undefined);

  useEffect(() => {
    if (location?.search) {
      const params = new URLSearchParams(location.search);
      if (params.get("type") && params.get("msg")) {
        if (params.get("type") === "success")
          notification.success({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "fail")
          notification.error({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "pending")
          notification.warning({ message: decodeURI(params.get("msg")) });
      } else return;
    }
    const fetchHomeData = async () => {
      try {
        const { data = {} } = await axios.get(`${getUrl("landing")}`);
        setHomeData(data?.data ?? []);
      } catch (error) {
        setHomeData([]);
      }
    };
    fetchHomeData();
  }, []);

  if (!homeData)
    return (
      <div className="home-page-spinner">
        <Spin />
      </div>
    );
  return (
    <>
      <Helmet>
        <title>
          Common Application Form - Online College Admission Form | Entrancezone
        </title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      <div className="home-page" style={{ gridColumn: "1/-1" }}>
        <MultiScreens data={homeData?.filters} />
        <ApplicationForms data={homeData?.colleges} />
        {/* <div className="pdf-make">
          <PdfMake />
        </div> */}
        {/* <SuccessfulScreen /> */}

        <WhyApply />
        {/* <div className="background-image">
          <Pricing />
        </div> */}
        <Process />
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
