import React, { useState, useEffect, forwardRef } from "react";
import { Select, Divider, Icon } from "antd";
import axios from "axios";
import getUrl from "../../utilities/apiConstant";
const debounce = require("lodash.debounce");

const { Option } = Select;

const CommonSearchItems = forwardRef((props, ref) => {
  const { type, handleIds, OptionName } = props;
  const [optionValue, setOptionValue] = useState(OptionName);
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState();

  useEffect(() => {
    fetchData();
  }, [name]);

  useEffect(() => {
    setOptionValue(OptionName);
  }, [OptionName]);

  async function fetchData() {
    setIsLoading(true);
    const response = await axios.get(`${getUrl("root")}/search`, {
      params: { type, name }
    });
    const data = response.data.data;
    setSearchData(data || []);
    setIsLoading(false);
  }

  const handleSearchingItems = debounce(async function(e) {
    setName(e);
  }, 800);

  const handleSelect = (id, name, type) => {
    setOptionValue(name);
    handleIds(id, name, type);
  };

  const children =
    searchData && searchData.length
      ? searchData.map((item, i) => {
          return (
            <Option
              key={i}
              value={item.id}
              onClick={() => handleSelect(item.id, item.name, type)}>
              {item.name}
            </Option>
          );
        })
      : null;

  const addItem = () => {
    setOptionValue(name);
    setSearchData([{ id: 0, name: name }]);
  };

  return (
    <>
      <Select
        showSearch
        showArrow={false}
        notFoundContent={null}
        placeholder={type}
        value={optionValue}
        optionFilterProp="children"
        onSearch={e => handleSearchingItems(e, type)}
        loading={isLoading}
        dropdownRender={menu => (
          <div>
            {menu}
            {!searchData.length ? (
              <div style={{}}>
                <Divider style={{ margin: "4px 0" }} />
                <p style={{ marginLeft: "1rem" }}>
                  Please add {type}, if not found
                </p>
                <div
                  style={{ padding: "4px 8px", cursor: "pointer" }}
                  onMouseDown={e => e.preventDefault()}
                  onClick={addItem}>
                  <Icon type="plus" /> Add item
                </div>
              </div>
            ) : null}
          </div>
        )}>
        {children}
      </Select>
    </>
  );
});

export default CommonSearchItems;
