import React, { useState, useEffect, useContext } from "react";
import getUrl from "../../utilities/apiConstant";
import axios from "axios";
import { enquireScreen } from "enquire-js";
import { Carousel } from "antd";
import { Context } from "../../utilities/Context";
import PricingCard from "./PricingCard";
import FAQ from "./FAQ";
const Pricing = ({ isPricingRoute = undefined, data = undefined }) => {
  const { userData } = useContext(Context);
  const [isMobile, setIsMobile] = useState(undefined);
  const [totalCards, setTotalCards] = useState([]);
  const [faqs, setFAQ] = useState(undefined);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true
  };
  const fetchPackagesData = async () => {
    try {
      const { data } = await axios.get(`${getUrl("packages")}`, {
        withCredentials: true
      });
      const cards = data.packages.map((data, index) => (
        <PricingCard key={index} Data={data} />
      ));
      setFAQ(data.faqs);
      setTotalCards(cards);
    } catch (e) {}
  };
  useEffect(() => {
    enquireScreen(status => setIsMobile(status));
    if (isPricingRoute && data) {
      const cards = data.packages.map((data, index) => (
        <PricingCard key={index} Data={data} />
      ));
      setFAQ(data.faqs);
      setTotalCards(cards);
      return;
    }
    fetchPackagesData();
  }, [userData]);
  return (
    <>
      {totalCards.length ? (
        <div className="pricing-main-div">
          <div className="pricing-main-div__first-child">Pricing</div>
          <div className="pricing-main-div__second-child">
            Choose the plan that best suites your needs.
          </div>
          {isMobile ? (
            <Carousel
              {...settings}
              className="dashboard-pricing-carousel pricing-carousel"
            >
              {totalCards}
            </Carousel>
          ) : (
            <div className="pricing-main-div__third-child">{totalCards}</div>
          )}
        </div>
      ) : null}
      {faqs ? <FAQ faqs={faqs} /> : null}
    </>
  );
};
export default Pricing;
