import HomePage from "./pages/HomePage";
import CollegeDetail from "./components/CollegeDetails/index";
import ApplicationCard from "./components/profile/ProfileApplication/index";
import Profile from "./pages/Profile";
import HomeProfile from "./components/profile/HomeProfile";
import PersonalInformation from "./components/profile/PersonalInformation";
import AcademicInterests from "./components/profile/AcademicInterests";
import CollegeSelection from "./components/CollegeSelection/index";
import ErrorPage from "./components/ErrorPage";
import HOF from "./components/HOF/HOF";
import FilterMainLayout from "./components/FilterPage/MainLayout";
import CollegeCourseSelection from "./components/profile/ShortlistedColleges/CollegeCourseSelection";
import Counselor from "./pages/Counselor";
import DashboardUpgradeInfo from "./components/PaymentGateway/Dashboard/DashboardUpgradeInfo";
import PrivacyPolicy from "./components/PrivacyPolicy";
import RefundPolicy from "./components/RefundPolicy";
import TermsAndConditionsRoute from "./components/TermsandConditionRoute";
import PricingPage from "./pages/Pricing";
import ApplyFormWrapper from "./components/ApplyForm";
import CPAFormWrapper from "./components/CPAForm";
import { SignInPage } from "./components/Login/SignInPage";
import { SignUpPage } from "./components/Login/SignUpPage";
import { DetailView } from "./components/profile/DetailView";
import ResetPassword from "./components/Login/ResetPassword";

export default [
  { path: "/", exact: true, component: HomePage },
  { path: "/colleges", exact: true, component: FilterMainLayout },
  { path: "/pricing", exact: true, component: PricingPage },
  { path: "/privacy-policy", exact: true, component: PrivacyPolicy },
  { path: "/return-refund-cancellation", exact: true, component: RefundPolicy },
  {
    path: "/terms-and-conditions",
    exact: true,
    component: TermsAndConditionsRoute
  },
  {
    path: "/apply/:college",
    exact: true,
    component: CPAFormWrapper
  },
  {
    path: "/:college/apply",
    exact: true,
    component: ApplyFormWrapper
  },
  { path: "/colleges/*", exact: true, component: ErrorPage },
  {
    path: "/profile",
    component: HOF(Profile),
    routes: [
      {
        path: "/profile",
        exact: true,
        component: HomeProfile
      },
      {
        path: "/profile/personal-information",
        exact: true,
        component: PersonalInformation
      },
      {
        path: "/profile/academic-information",
        exact: true,
        component: AcademicInterests
      },
      {
        path: "/profile/college-selection",
        exact: true,
        component: CollegeSelection
      },
      {
        path: "/profile/college-selection/:college_name",
        exact: true,
        component: CollegeCourseSelection
      },
      {
        path: "/profile/application/:id",
        exact: true,
        component: DetailView
      },
      {
        path: "/profile/applications",
        exact: true,
        component: ApplicationCard
      },
      // {
      //   path: "/profile/upgrade",
      //   exact: true,
      //   component: DashboardUpgradeInfo,
      // },
      {
        path: "/profile/counselor",
        exact: true,
        component: Counselor
      }
    ]
  },
  { path: "/signin", exact: true, component: HOF(SignInPage, true) },
  { path: "/signup", exact: true, component: HOF(SignUpPage, true) },
  { path: "/reset-password", exact: true, component: ResetPassword },
  { path: "/error", exact: true, component: ErrorPage },
  { path: "/:college_name", exact: true, component: CollegeDetail }
];
