import React from "react";
import { Tabs } from "antd";
import CourseCardHOF from "./CourseCardHOF";
import _groupBy from "lodash/groupBy";
const Course = ({ college_id, college_data, courses, facilities }) => {
  const course = Object.entries(
    _groupBy(courses, level => level.education_level)
  );
  const { TabPane } = Tabs;
  const Education_Level = course.map((item, index) => (
    <TabPane tab={item[0]} key={index}>
      <CourseCardHOF
        college_id={college_id}
        college_data={college_data}
        data={item[1]}
      />
    </TabPane>
  ));
  return (
    <div className="course-div-child-1">
      <Tabs defaultActiveKey="0">{Education_Level}</Tabs>
    </div>
  );
};
export default Course;
