import React, { useState } from "react";
import { Button, Divider, notification } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import getUrl from "utilities/apiConstant";

export const AppliedComp = props => {
  const [ackLoading, setAckLoading] = useState(false);
  const { data } = props;
  const history = useHistory();

  const college_name = data?.college_name ?? undefined;
  const course_name = data?.course_name ?? undefined;
  const requested_branches = data?.branches ?? [];
  const branches = requested_branches?.length
    ? requested_branches.map(item => item.name)
    : null;
  const created_at = data?.created_at ?? undefined;
  const application_id = data?.application_id ?? undefined;
  const acknowledgement_receipt_id =
    data?.acknowledgement_receipt_id ?? undefined;
  // const form_id = data?.form_id ?? undefined;
  const logo_url = data?.logo?.display_url ?? undefined;
  const logo_alt_text = data?.logo?.alt_text ?? undefined;
  const slug = data?.slug ?? undefined;

  const handleClick = () => {
    if (application_id) {
      history.push(`/profile/application/${application_id}`);
      return;
    }
    history.push(`${slug}/apply`);
  };

  const downloadAckReceipt = async e => {
    try {
      e.stopPropagation();
      setAckLoading(true);
      const response = await axios.get(
        `${getUrl("root_v2")}/caf/user/uploads`,
        {
          params: { document_id: acknowledgement_receipt_id },
          withCredentials: true
        }
      );
      window.open(response?.data?.data);
      // setHomeOverview(response?.data?.data);
      setAckLoading(false);
    } catch (error) {
      notification.error(
        "Could not Download Acknowledgement Receipt. Please Try Again Later."
      );
      setAckLoading(false);
    }
  };

  return (
    <div className="applied-card" onClick={handleClick}>
      <img src={logo_url} alt={logo_alt_text} className="logo" />
      <div className="content">
        <div className="block-1">
          <h3 className="clg-name">{college_name}</h3>
        </div>
        {acknowledgement_receipt_id ? (
          <div
            className="buttons"
            style={{ alignSelf: "center", justifySelf: "flex-end" }}
          >
            <Button
              type="primary"
              icon="download"
              onClick={downloadAckReceipt}
              loading={ackLoading}
            >
              Ack. Receipt
            </Button>
            {/* <Button type="primary" icon="download">
              App. Form
            </Button> */}
          </div>
        ) : null}
        <div
          className="block-1"
          style={{
            gridColumn: "1/-1",
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)"
          }}
        >
          <div style={{ gridColumn: "1/2" }}>
            <p className="branch-heading">Course Applied</p>
            <p className="branch-value">{course_name}</p>
          </div>
          <div style={{ gridColumn: "2/-1" }}>
            <p className="branch-heading">Branches Requested</p>
            <p className="branch-value">
              {branches?.length ? branches.join(", ") : null}
            </p>
          </div>
        </div>
        <Divider style={{ gridColumn: "1/-1" }} />
        <div className="card-footer">
          <div>
            <p className="label">Date Applied</p>
            <p className="value">{moment(created_at).format("DD-MM-YY")}</p>
          </div>
          {/* <div>
            <p className="label">Last Activity</p>
            <p className="value">{moment(created_at).format("DD-MM-YY")}</p>
          </div> */}

          <div>
            <p className="label">Status</p>
            <p className="status-value">
              {application_id ? "Complete" : "Pending"}
            </p>
          </div>

          {application_id ? (
            <div>
              <p className="label">Application ID</p>
              <p className="value">{application_id}</p>
            </div>
          ) : null}

          <Button className="card-footer__btn">
            {application_id ? "View" : "Complete"}
          </Button>
        </div>
      </div>
    </div>
  );
};
