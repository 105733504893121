import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import axios from "axios";
import _get from "lodash/get";
import getUrl from "../../utilities/apiConstant";

const FormItem = Form.Item;

const CAFEmailModal = (props) => {
  const {
    form: { getFieldDecorator, setFieldsValue },
    visible,
    onCancel,
    email,
    fun_setEmailId,
  } = props;
  const [isLoading, setLoading] = useState(false);

  const handleSendEmail = async (e) => {
    e.preventDefault();
    const values = await props.form.validateFields();
    try {
      setLoading(true);
      const { email } = values;
      const response = await axios.post(
        `${getUrl("root")}/user/profile/email`,
        {
          email,
          source: "caf",
        },
        { withCredentials: true }
      );
      setLoading(false);
      fun_setEmailId(email);
      onCancel();
      notification.success({
        message: _get(response, "data.message") || "Something went wrong",
      });
    } catch (error) {
      setLoading(false);
      notification.error({
        message: _get(error.response, "data.message") || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    if (email) {
      setFieldsValue({
        email: email,
      });
    }
  }, [email]);

  return (
    <Modal
      title="Enter Your Email"
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}>
      <Form
        onSubmit={handleSendEmail}
        className="phone-modal-form"
        style={{ padding: " 2rem ", display: "grid" }}>
        <FormItem>
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                type: "email",
                message: "Please input your email-id",
              },
            ],
          })(<Input placeholder="Email" disabled={email ? true : false} />)}
        </FormItem>
        <Button type="primary" onClick={handleSendEmail} loading={isLoading}>
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

export default Form.create()(CAFEmailModal);
