import React from "react";
const Review = ({ course, branches, first_year_fee }) => {
  const branchesLayout = branches.map((data, index) => (
    <li className="college-stream-course-li" key={index}>
      {data.name}
    </li>
  ));
  return (
    <div className="review-main-div">
      <div className="review-child-div-1">
        <div className="college-applied-fees-div">
          <h3 className="college-stream-course-h4">Course Applied : </h3>
          <p className="college-stream-course-para">{course.name}</p>
        </div>
        <div className="college-applied-fees-div">
          <h4 className="college-stream-course-h4">Course Fee : </h4>
          <p className="college-stream-course-para">{first_year_fee}</p>
        </div>
        <div className="college-applied-fees-div">
          <h4 className="college-stream-course-h4">Branch Selected : </h4>
          <ol className="college-stream-course-ol">{branchesLayout}</ol>
        </div>
      </div>
    </div>
  );
};
export default Review;
