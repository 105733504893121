import React, { useState, useContext, useEffect } from "react";
//import { useHistory } from "react-router-dom";
import { Context } from "../../../utilities/Context";
import { Icon } from "antd";
import axios from "axios";
import getUrl from "../../../utilities/apiConstant";
import CAFAuthenticationModal from "../../Login/CAFAuthenticationModal";
const CoursesOffered = ({ college_id, college_data, data }) => {
  const {
    isLogin,
    setCollegeData,
    educationLevel,
    selectedCourse,
    selectedStream,
    selectedCollegesData,
  } = useContext(Context);
  let selectedCollege = {};
  if (selectedCollegesData.length) {
    selectedCollege = selectedCollegesData.find(
      (ele) => ele.college_id === college_id
    );
  }
  const [showModal, setShowModal] = useState(false);
  const [isSelected, setIsSelected] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  // const history = useHistory();
  const { course, first_year_fee } = data;
  const handleApplyNow = async () => {
    if (!isLogin()) return setShowModal(true);
    const checkCollege = selectedCollegesData.find(
      (ele) => ele.college_id === college_id
    );
    setIsLoading(true);
    await axios.post(
      `${getUrl("root")}/caf/user/shortlist`,
      {
        college_id: college_id,
        course_id: data.id,
        education_level_id:
          educationLevel && educationLevel.id ? educationLevel.id : undefined,
        stream_id:
          selectedStream && selectedStream.id ? selectedStream.id : undefined,
      },
      { withCredentials: true }
    );
    setIsLoading(false);
    setIsSelected(true);
    if (checkCollege) {
      setCollegeData({ ...college_data, course_id: data.id }, "course");
    } else {
      setCollegeData({ ...college_data, course_id: data.id }, "college");
    }
    //history.push("/profile");
  };
  useEffect(() => {
    if (selectedCollege && selectedCollege.course_id) {
      setIsSelected(selectedCollege.course_id === data.id);
    } else if (selectedCollege && selectedCollege.selected_course) {
      setIsSelected(selectedCollege.selected_course.id === data.id);
    } else {
      setIsSelected(selectedCourse === data.id);
    }
  }, [selectedCourse, selectedCollegesData]);
  const handleSelected = async () => {
    if (!isLogin()) return setShowModal(true);
    setIsLoading(true);
    await axios({
      method: "DELETE",
      url: `${getUrl("root")}/caf/user/shortlist`,
      data: { college_id: college_id },
      withCredentials: true,
    });
    setIsLoading(false);
    setIsSelected(false);
    setCollegeData(college_data, "college");
    return;
  };
  return (
    <div className="courses-offered-main-div">
      <div className="courses-offered-child-div-1">
        <div className="course-name-div">{course}</div>
        {first_year_fee ? (
          <div className="tuition-fee-div">
            Annual Tuition Fee : {first_year_fee}
          </div>
        ) : null}
      </div>
      {/* <div className="courses-offered-child-div-2">
        {isSelected ? (
          <button
            className="secondary-btn antd-button-apply-now-selected"
            onClick={handleSelected}>
            {isLoading ? (
              <>
                {" "}
                <Icon
                  type="check-circle"
                  theme="filled"
                  style={{ marginRight: "1rem", fontSize: "1.8rem" }}
                />
                Selected
                <Icon type="loading" style={{ marginLeft: "0.5rem" }} />
              </>
            ) : (
              <>
                {" "}
                <Icon
                  type="check-circle"
                  theme="filled"
                  style={{ marginRight: "1rem", fontSize: "1.8rem" }}
                />
                Selected
              </>
            )}
          </button>
        ) : (
          <button
            className="primary-btn antd-button-apply-now"
            onClick={handleApplyNow}>
            {isLoading ? (
              <>
                <Icon type="loading" style={{ marginRight: "0.5rem" }} />
                Select
              </>
            ) : (
              <>Select</>
            )}
          </button>
        )}
      </div> */}
      <CAFAuthenticationModal
        showAuthModal={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};
export default CoursesOffered;
