import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd";
import flow from "lodash/flow";
import { Icon } from "antd";
const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};
const cardTarget = {
  drop(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    const clientOffset = monitor.getClientOffset();

    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveCard(dragIndex, hoverIndex);

    monitor.getItem().index = hoverIndex;
  },
};
class SetPriority extends Component {
  render() {
    const {
      index,
      branch,
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props;
    const opacity = isDragging ? 1 : 1;
    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div className="set-priority-main-div" style={{ opacity }}>
            <div style={{ display: "flex" }}>
              <Icon type="menu" style={{ fontSize: "1.8rem" }} />
              <div className="set-priority-name-div">{`${
                branch?.name ?? branch?.full_name ?? "---"
              }`}</div>
            </div>
            <div className="set-priority-name-div">{`Priority : ${
              index + 1
            } `}</div>
          </div>
        )
      )
    );
  }
}
export default flow(
  DragSource("branch", cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget("branch", cardTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(SetPriority);
