import React, { useState, useContext } from "react";
import HomeScreen from "./multiscreens/HomeScreen";
import EducationLevelScreen from "./multiscreens/EducationLevelScreen";
import StreamScreen from "./multiscreens/StreamScreen";
import { Context } from "../utilities/Context";

const MultiScreens = ({ data }) => {
  const { current } = useContext(Context);
  const [educationId, setEducationId] = useState(0);
  const [eduLevelName, setEduLevelName] = useState("");

  const selectEducationLevelId = (id, value) => {
    setEducationId(id);
    setEduLevelName(value);
  };
  return (
    <div className="multi-screens background-image">
      <h1 className="heading-1 home-screen__heading">
        Common Application Form
      </h1>
      {current === 1 ? <HomeScreen data={data} /> : null}
      {current === 2 ? (
        <EducationLevelScreen
          data={data}
          setEducationId={selectEducationLevelId}
        />
      ) : null}
      {current === 3 ? (
        <StreamScreen
          data={data}
          educationId={educationId}
          eduLevelName={eduLevelName}
        />
      ) : null}
    </div>
  );
};

export default MultiScreens;
