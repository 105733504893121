import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Pricing from "../components/PaymentGateway/Pricing";
import axios from "axios";
import getUrl from "../utilities/apiConstant";
import { Spin, notification } from "antd";
const PricingPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pricingData, setData] = useState([]);
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${getUrl("packages")}`, {
        withCredentials: true
      });
      setData(data);
      setIsLoading(false);
    } catch (e) {
      notification.error({ message: "Something went wrong !" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          gridColumn: "1/-1"
        }}>
        <Spin />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>Pricing - Online College Admission Form | Entrancezone</title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      <div style={{ gridColumn: "1/-1" }}>
        <div className="background-image">
          <Pricing isPricingRoute data={pricingData} />
        </div>
        <Footer />
      </div>
    </>
  );
};
export default PricingPage;
