import React, { memo } from "react";
import _startCase from "lodash/startCase";

const ThrowError = ({ error }) => {
  const getErrors = (errors) => {
    return Object.keys(errors ?? {}).map((item, index) =>
      typeof errors[item] === "object" ? (
        getErrors(errors[item])
      ) : (
        <li key={index}>{_startCase(errors?.[item])}</li>
      )
    );
  };

  return <ul>{getErrors(error)}</ul>;
};
export default memo(ThrowError);
