import React, { useState } from "react";
import { Row, Col, Upload, Icon, message, notification, Button } from "antd";
import getUrl from "../../utilities/apiConstant";

const UploadDocument = ({ edu_id, layout, marksheet }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [showPdfFrame, setShowPdfFrame] = useState(false);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    if (file.type === "application/pdf") setShowPdfFrame(true);
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPEG/JPG/PNG/PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPngOrPdf && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "error") {
      setLoading(false);
      notification.error({ message: "Something went wrong" });
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageUrl(imageUrl);
        notification.success({ message: "Document is uploaded" });
      });
    }
  };
  const getExtraData = () => {
    return {
      education_level_id: edu_id,
      document_type: "Marksheet",
    };
  };

  const uploadButton = (
    <div>
      {layout === "replace" ? (
        <Button
          loading={loading}
          style={{
            height: "3rem",
            border: "none",
            border: "0.1rem solid #3c1a5b",
            backgroundColor: "white",
            color: "#3c1a5b",
            marginRight: "1rem",
          }}>
          Replace
        </Button>
      ) : (
        <>
          <Icon type={loading ? "loading" : "plus"} />
          <div>Upload</div>
        </>
      )}
    </div>
  );
  return (
    <Row>
      {layout !== "replace" ? (
        <>
          <h3
            className="heading-3"
            style={{
              marginBottom: "0.5rem",
              color: "#3c1a5b",
              fontWeight: "500",
              fontSize: "1.6rem",
            }}>
            Upload Document
          </h3>
          <p style={{ marginBottom: "1rem", fontSize: "1.2rem" }}>
            *{marksheet}
          </p>
        </>
      ) : null}

      <Col xs={24} md={24}>
        <Upload
          className="upload-document"
          name="file"
          listType="picture-card"
          listType={!layout ? "picture-card" : undefined}
          showUploadList={false}
          action={`${getUrl("uploads")}`}
          method="post"
          data={getExtraData}
          withCredentials={true}
          beforeUpload={beforeUpload}
          onChange={handleChange}>
          {layout === "replace" ? (
            uploadButton
          ) : imageUrl ? (
            showPdfFrame ? (
              <iframe src={imageUrl} />
            ) : (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  //  width: "100%",
                  height: "20rem",
                }}
              />
            )
          ) : (
            uploadButton
          )}
        </Upload>
        {layout !== "replace" ? (
          <>
            <p className="upload-document__content">
              Supported formats: .jpeg, .jpg, .png, .pdf
            </p>
            <p className="upload-document__content">File size limit: 5MB</p>
          </>
        ) : null}
      </Col>
    </Row>
  );
};

export default UploadDocument;
