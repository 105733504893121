import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Carousel, Icon } from "antd";
import Card from "../FilterPage/Card/AppliedFormCard";
import HomeCarousel from "./HomeCarousel";

const ApplicationForms = ({ data }) => {
  const history = useHistory();

  const admissionMode = {
    cpl: "Latest Enquiry Form",
    cpa: "Latest Application Form",
    cps: "Latest Admission Form",
  };

  return (
    <section>
      {data?.length
        ? data.map(({ admission_mode = "", colleges = [] }, itemIndex) =>
            colleges?.length ? (
              <section
                key={itemIndex}
                className="application-form-card-wrapper">
                <section className="application-form-heading-wrapper">
                  <h2 className="heading-2 admission-form-heading">
                    {admissionMode?.[admission_mode]?.toUpperCase() ?? "---"}
                  </h2>
                  <section
                    className="application-form-heading-wrapper"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/colleges")}
                    // style={{ display: "flex", alignItems: "center" }}
                  >
                    <p style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}>
                      Show All
                    </p>
                    {/* <Icon className="show-more-icon" type="right" /> */}
                    <Icon
                      type="right-circle"
                      theme="filled"
                      className="right-circle-icon"
                    />
                  </section>
                </section>
                <HomeCarousel admission_mode={admission_mode} data={colleges} />
              </section>
            ) : (
              []
            )
          )
        : []}
    </section>
  );
};

export default ApplicationForms;
