import React, { useState, useEffect, forwardRef } from "react";
import { Select, Divider, Icon, Form } from "antd";
import axios from "axios";
import getUrl from "utilities/apiConstant";
const debounce = require("lodash.debounce");

const { Option } = Select;
const FormItem = Form.Item;

const CommonSearch = forwardRef((props, ref) => {
  const {
    type,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    graduationType,
    optionType,
    options,
  } = props;
  // const [optionValue, setOptionValue] = useState(OptionName);
  const [searchData, setSearchData] = useState(options ? options : []);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(null);

  useEffect(() => {
    fetchData(options);
  }, [name]);

  async function fetchData() {
    if (options) {
      if (name === null) return;
      const searchedItems = options?.filter((item) =>
        item.includes(name?.toLowerCase() ?? name)
      );
      setSearchData(searchedItems);
    } else {
      setIsLoading(true);
      const response = await axios.get(`${getUrl("root")}/search`, {
        params: { type, name },
      });
      const data = response?.data?.data ?? {};
      setSearchData(data || []);
      setIsLoading(false);
    }
  }

  const handleSearchingItems = debounce(async function (e) {
    setName(e?.toLowerCase() ?? e);
  }, 800);

  const children =
    !options && searchData?.length
      ? searchData.map((item, i) => {
          return (
            <Option key={i} value={item.name}>
              {item.name}
            </Option>
          );
        })
      : null;

  // For case if we dont have to make a api call i.e. we have static options like in case of 12th stream :

  const optionToRender =
    options && searchData?.length
      ? searchData.map((item) => {
          return (
            <Option key={item} value={item}>
              {item}
            </Option>
          );
        })
      : null;

  const addItem = () => {
    setSearchData(options ? [name] : [{ id: 0, name: name }]);
  };

  return (
    <FormItem
      label={`${type}`.toUpperCase()}
      validateStatus={
        touched?.[graduationType]?.[optionType] &&
        errors?.[graduationType]?.[optionType]
          ? "error"
          : values?.[graduationType]?.[optionType]
          ? "success"
          : null
      }
      help={
        touched?.[graduationType]?.[optionType] &&
        errors?.[graduationType]?.[optionType]
      }
      required
    >
      <Select
        showSearch
        size="large"
        showArrow={false}
        notFoundContent={null}
        placeholder={type}
        value={values?.[graduationType]?.[optionType]}
        optionFilterProp="children"
        onSearch={(e) => handleSearchingItems(e, type)}
        onChange={(value) => {
          setFieldValue(`${graduationType}.${optionType}`, value, true);
          setFieldTouched(`${graduationType}.${optionType}`, false);
        }}
        loading={isLoading}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {!searchData.length ? (
              <div style={{}}>
                <Divider style={{ margin: "4px 0" }} />
                <p style={{ marginLeft: "1rem" }}>
                  Please add {type}, if not found
                </p>
                <div
                  style={{ padding: "4px 8px", cursor: "pointer" }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={addItem}
                >
                  <Icon type="plus" /> Add item
                </div>
              </div>
            ) : null}
          </div>
        )}
      >
        {options ? optionToRender : children}
      </Select>
    </FormItem>
  );
});

export default CommonSearch;
