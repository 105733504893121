import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { Table, Button, notification } from "antd";
import getUrl from "utilities/apiConstant";
import successful_image from "img/Successful.png";
import { SpinnerComp } from "components/SpinnerComp";

const SuccessfulScreen = ({ form_id }) => {
  const history = useHistory();
  const [successData, setSuccessData] = useState(undefined);
  useEffect(() => {
    showPaymentResponse();
    getSuccessData();
  }, []);

  const getSuccessData = async () => {
    try {
      const { data = {} } = await axios.get(
        `${getUrl("cpa_form")}/${form_id}/success`,
        { withCredentials: true }
      );
      setSuccessData(data?.data ?? {});
    } catch (err) {
      setSuccessData({});
    }
  };

  const showPaymentResponse = () => {
    if (window?.location?.href) {
      const params = new URLSearchParams(window.location.search);
      if (params.get("type") && params.get("msg")) {
        if (params.get("type") === "success")
          notification.success({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "fail")
          notification.error({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "pending")
          notification.warning({ message: decodeURI(params.get("msg")) });
      } else return;
    }
  };

  const columns = [
    {
      title: "App. Form",
      dataIndex: "course_name",
      key: "course_name",
    },
    {
      title: "App. No.",
      dataIndex: "application_id",
      key: "application_id",
    },
    {
      title: "Submitted on",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Fees",
      dataIndex: "amount",
      key: "amount",
    },
  ];
  let rows = [];
  if (Object.keys(successData ?? {})?.length) {
    const dataObject = {};
    Object.keys(successData).forEach((item) => {
      if (
        item === "amount" ||
        item === "course_name" ||
        item === "application_id"
      )
        dataObject[item] = successData[item];
      else if (item === "created_at")
        dataObject[item] = moment(successData?.[item]).format("LL") ?? "---";
      dataObject.key = item;
    });
    rows.push(dataObject);
  }

  if (!successData) return <SpinnerComp />;
  return (
    <section className="success-screen-wrapper">
      <img
        src={successful_image}
        alt=""
        className=""
        style={{ width: "100%" }}
      />
      <div style={{ margin: "6rem" }}>
        <Table dataSource={rows} columns={columns} pagination={false} />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5rem",
          }}
        >
          {/* <div style={{ display: "flex" }}>
            <Button
              icon="download"
              type="primary"
              style={{
                backgroundColor: "#f5f5f5",
                color: "black",
                marginRight: "2rem",
                border: "none",
              }}
            >
              Ack. Receipt
            </Button>
            <Button
              icon="download"
              type="primary"
              style={{
                backgroundColor: "#f5f5f5",
                color: "black",
                border: "none",
              }}
            >
              Application Form
            </Button>
          </div> */}

          <Button
            type="primary"
            style={{
              backgroundColor: "#faca45",
              color: "#44337a",
              borderColor: "#faca45",
              fontSize: "1.4rem",
              padding: "0 4rem",
            }}
            onClick={() => history.push("/profile")}
          >
            GO TO DASHBOARD
          </Button>
        </div>
      </div>
    </section>
  );
};
export default SuccessfulScreen;
