import React from "react";
import { Modal } from "antd";
import ShowAcademic from "./ShowAcademic";

const ApplyModal = ({ showVisible, onClose, data, func_handleApply }) => {
  return (
    <div>
      <Modal
        title="Fill your Academic Detail"
        width={800}
        visible={showVisible}
        onCancel={onClose}
        footer={null}>
        <ShowAcademic
          data={data}
          func_handleApply={func_handleApply}
          onClose={onClose}
        />
      </Modal>
    </div>
  );
};

export default ApplyModal;
