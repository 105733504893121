import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Steps, message, Spin, notification, Icon } from "antd";
import CourseDetails from "./Course/Application-Course-Details";
import CourseBranchWrapper from "./CourseBranchWrapper";
import SetPriorityWrapper from "./SetPriorityWrapper";
import ReviewDisclaimer from "./../../CollegeSelection/Review-Disclaimer/Review-Disclaimer";
import Apply from "../../../components/CollegeSelection/Review-Disclaimer/Apply-Succes";
import getUrl from "../../../utilities/apiConstant";
import axios from "axios";
import { Context } from "../../../utilities/Context";
import ApplyModal from "../../ApplyModal/ApplyModal";
import { useHistory } from "react-router-dom";

const stepStyle = {
  marginBottom: 60,
  boxShadow: "0px -1px 0 0 #e8e8e8 inset",
};

const { Step } = Steps;
const steps = [
  {
    content: "Select Course",
  },
  {
    content: "Select Branch",
  },
  {
    content: "Set Priority",
  },
  {
    content: "Application Review",
  },
];
const CollegeCourseSelection = (props) => {
  const [collegeInfo, setCollegeInfo] = useState({});
  const {
    location: { pathname },
  } = props;
  const {
    college_id,
    full_name,
    courses,
    selected_course,
    address = {},
    logo = {},
    accreditation_and_regulations,
  } = collegeInfo;
  const history = useHistory();
  const { alt_text, display_url } = logo;
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckBox] = useState([]);
  const [selectCourse, setSelectCourse] = useState({});
  const [current, setCurrent] = useState(0);
  const [apply, setApply] = useState({});
  const [checkedBranch, setCheckedBranch] = useState([]);
  const [branch_priority, setBranches] = useState([]);
  const [showReview, setShowReview] = useState(false);
  const [modalAcademicData, setModalAcademicData] = useState([]);
  const [visible, setVisible] = useState(false);
  const {
    is_academic_detail_complete: academicDetail,
    is_personal_detail_complete: personalDetail,
    setCollegeData,
    selectedCollegesData,
    updateContextData,
  } = useContext(Context);

  const next = () => {
    setCurrent(() => current + 1);
  };

  const branches_priority = (branches) => {
    setBranches(branches);
  };

  const handleSubmit = async () => {
    if (Array.isArray(checkbox) && checkbox.length === 2) {
      const finaldata = branch_priority.map((data, index) => {
        return { ...data, priority: index + 1 };
      });
      try {
        setLoading(true);
        await axios.post(
          `${getUrl("root")}/caf/user/application-form`,
          {
            college_id,
            course_id: apply.id,
            branches: finaldata,
          },
          { withCredentials: true }
        );
        setLoading(false);
        setCollegeData(collegeInfo, "college");
        updateDataToContext();
        setShowReview(true);
      } catch (e) {
        setLoading(false);
        if (e.response && e.response.data) {
          const statusCode = e.response.data.statusCode;
          // for academic error
          if (statusCode === 412) {
            notification.error({ message: e.response.data.message });
            setModalAcademicData(e.response.data.data || []);
            setVisible(true);
            return;
          }
          // for payment error
          if (statusCode === 402) {
            notification.error({ message: e.response.data.respnse });
            return;
          }
          notification.error({ message: e.response.data.message });
          return;
        }
      }
    } else {
      message.info("You have to agree for the terms and conditions");
    }
  };

  useEffect(() => {
    const checkData = selectedCollegesData.find(
      (college) => college.college_id === college_id
    );
    if (checkData && checkData.selected_course) {
      handleApply(checkData.selected_course);
    }
  }, [collegeInfo]);

  useEffect(() => {
    if (!personalDetail || !academicDetail) {
      history.push("/profile/college-selection");
      return;
    }
    const fetchData = async () => {
      const college_slug = props.location.pathname.split("/")[3];
      try {
        setLoading(true);
        const response = await axios.get(
          `${getUrl("root")}/caf/user/colleges/${college_slug}`,
          {
            withCredentials: true,
          }
        );
        setLoading(false);
        setCollegeInfo(response.data.data);
      } catch (e) {
        setLoading(false);
        if (e.response && e.response.data) {
          const { error, message } = e.response.data;
          notification.error({ message: error, description: message });
          history.push("/profile/college-selection");
          return;
        }
      }
    };
    fetchData();
    if (selected_course) {
      handleApply(selected_course);
    }
  }, [pathname]);

  const updateDataToContext = async () => {
    try {
      const response = await axios(getUrl("user"), {
        withCredentials: true,
      });
      const data = response && response.data ? response.data.data : {};
      updateContextData(data);
    } catch (error) {
      // notification.error({
      //   message: "Something went wrong"
      // });
    }
  };

  const handleSelectedBranch = (id, name) => {
    const checkedBranchesCopy = [...checkedBranch];
    const data = checkedBranchesCopy.find((data) => id === data.id);
    const index = checkedBranchesCopy.indexOf(data);
    if (index === -1) {
      checkedBranchesCopy.push({ id, name });
    } else {
      checkedBranchesCopy.splice(index, 1);
    }
    setCheckedBranch(checkedBranchesCopy);
    setBranches([]);
  };

  const prev = () => {
    setCurrent(() => current - 1);
    if (current === 1) return setCheckedBranch([]);
  };

  let selectedCourse = [];
  const handleApply = (value) => {
    setApply(value);
    selectedCourse = courses.find((data) => data.id === value.id);
    setSelectCourse(selectedCourse);
    next();
  };
  return (
    <>
      <Helmet>
        <title>
          {`${
            full_name ? full_name : "Institute"
          } - Online College Admission Form | Entrancezone`}
        </title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>{" "}
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "90vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            bottom: "0",
          }}>
          <Spin tip="Please Wait..." />
        </div>
      ) : showReview ? (
        <Apply college_name={full_name} />
      ) : (
        <div style={{ position: "relative" }}>
          <div className="college-profile-child-div-1">
            <img
              alt={alt_text}
              src={display_url}
              className="college-profile-child-div-1__image"
            />
            <div className="logo-icon-div">
              <p className="college-name-para">{full_name}</p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="icon-detail-div">
                  <Icon
                    type="environment"
                    theme="filled"
                    style={{ marginRight: "0.5rem" }}
                  />
                  <p
                    style={{
                      fontFamily: "Montserrat",
                    }}>{`${address.city}, ${address.state}`}</p>
                </div>
                <div className="icon-detail-div">
                  <Icon
                    type="check-circle"
                    theme="filled"
                    style={{ marginRight: "0.5rem" }}
                  />
                  <p style={{ fontFamily: "Montserrat" }}>
                    {accreditation_and_regulations}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="college-course-selection-step-div">
            <Steps
              type="navigation"
              size="small"
              current={current}
              style={stepStyle}>
              {steps.map((item, index) => (
                <Step
                  key={index}
                  title={`Step ${index + 1}`}
                  description={item.content}
                />
              ))}
            </Steps>
          </div>
          <div className="steps-content">
            {current === 0 ? (
              <CourseDetails courses={courses} handleApply={handleApply} />
            ) : null}
            {current === 1 ? (
              <CourseBranchWrapper
                courses={selectCourse}
                handleSelectedBranch={handleSelectedBranch}
                isSpaceAvailable={checkedBranch.length}
                branchPriority={branch_priority}
              />
            ) : null}
            {current === 2 ? (
              <SetPriorityWrapper
                handleDone={branches_priority}
                branches={checkedBranch}
                branchPriority={branch_priority}
              />
            ) : null}
            {current === 3 ? (
              <ReviewDisclaimer
                course={apply}
                branches={branch_priority}
                first_year_fee={selectCourse.first_year_fee}
                setCheckBox={setCheckBox}
              />
            ) : null}
          </div>
          <div className="steps-action">
            {(current === steps.length - 3 ||
              current === steps.length - 2 ||
              current === steps.length - 1) && (
              <button className="back-button" onClick={prev} type="primary">
                <Icon
                  type="left"
                  style={{ fontSize: "1.5rem", marginRight: "1rem" }}
                />
                BACK
              </button>
            )}
            {(current === steps.length - 2 || current === steps.length - 3) && (
              <button
                className="next-button"
                onClick={() => {
                  if (Array.isArray(checkedBranch) && checkedBranch.length) {
                    next();
                  } else {
                    message.info("Please Select at least one branch ! ");
                  }
                }}
                type="primary">
                NEXT
                <Icon
                  type="right"
                  style={{ fontSize: "1.5rem", marginLeft: "1rem" }}
                />
              </button>
            )}
            {current === steps.length - 1 && (
              <button
                className="next-button"
                onClick={handleSubmit}
                type="primary">
                Apply
                <Icon
                  type="check"
                  style={{ fontSize: "1.5rem", marginLeft: "1rem" }}
                />
              </button>
            )}
          </div>
        </div>
      )}
      <ApplyModal
        showVisible={visible}
        onClose={() => setVisible(false)}
        data={modalAcademicData}
        func_handleApply={handleSubmit}
      />
    </>
  );
};
export default CollegeCourseSelection;
