import React from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "antd";
import moment from "moment";
// import axios from "axios";
// import getUrl from "../../../utilities/apiConstant";
// import { Context } from "../../../utilities/Context";
const Card = ({ data, collegesData, mode, homepage }) => {
  const history = useHistory();
  const {
    college_id,
    title,
    city,
    state,
    slug,
    logo = {},
    featured_image = {},
    admission_mode = "",
  } = data;
  const {
    alt_text: logo_alt_text,
    display_url: logo_display_url,
    title: logo_title,
  } = logo;
  const {
    alt_text: featured_alt_text,
    display_url: featured_display_url,
    title: featured_title,
  } = featured_image;
  const handleShowMore = (e) => {
    e.preventDefault();
    if (admission_mode === "cpl") window.open(`/${data?.apply_url}`, "_self");
    else window.open(`${data?.detail_url}`);
  };
  return (
    <div
      className="single-card-main-div"
      onClick={handleShowMore}
      style={{
        margin: homepage ? "2rem" : "unset",
      }}
    >
      <div className="image-div">
        <img
          className="image-div__1"
          alt={featured_alt_text}
          src={featured_display_url}
          title={featured_title}
          width="100%"
          style={{ borderRadius: "0.3rem", height: "13rem" }}
        />
      </div>
      <img
        className="college-apply-logo-image"
        alt={logo_alt_text}
        src={logo_display_url}
        title={logo_title}
      />
      <div className="college-intro-div college-intro-wrapper">
        <div className="college-intro-address-title-wrapper">
          <div className="college-apply-address ">
            <div className="college-intro-child-div-2-child">
              <Icon
                type="environment"
                theme="filled"
                className="college-apply-icons"
              />
              <p className="college-application-amount">
                {city}, {state}
              </p>
            </div>
          </div>
          <div className="college-apply-title">{title}</div>
        </div>
      </div>
      <div
        className="college_startend-date"
        // style={homepage ? { columnGap: "2rem" } : {}}
      >
        <Icon type="calendar" />
        <p className="card-date-para">
          {data?.start_date
            ? moment(data.start_date).format(homepage ? "MMM DD, YYYY" : "LL")
            : "---"}
        </p>
        -
        <p className="card-date-para">
          {data?.end_date
            ? moment(data.end_date).format(homepage ? "MMM DD, YYYY" : "LL")
            : "---"}
        </p>
      </div>
      <div className="apply-college-footer-button">
        {mode !== "cpl" ? (
          <div>
            <p className="college-application-amount">APPLICATION AMOUNT</p>
            {data?.actual_fees || data?.discounted_fees ? (
              data?.discounted_fees < data?.actual_fees ? (
                <span style={{ display: "flex" }}>
                  <p
                    style={{
                      textDecoration: "line-through",
                      marginRight: "1rem",
                    }}
                  >
                    {`₹ ${parseInt(data.actual_fees)}`}
                  </p>
                  <strong className="college-apply-amount">
                    ₹ {parseInt(data.discounted_fees)}
                  </strong>
                </span>
              ) : (
                <p
                  className="college-apply-amount"
                  style={{ textAlign: "left" }}
                >
                  {`₹ ${parseInt(data.actual_fees)}`}
                </p>
              )
            ) : (
              <strong className="college-apply-amount"> ₹ 0</strong>
            )}
          </div>
        ) : null}

        <button
          onClick={(e) => {
            if (mode === "cpl") return;
            e.stopPropagation();
            history.push(`/${data?.apply_url}`);
          }}
          className="primary-btn"
          style={{
            fontWeight: "500",
            height: "4rem",
          }}
        >
          Apply Now
        </button>
      </div>
    </div>
  );
};
export default Card;
