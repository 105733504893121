import React from "react";
import { Helmet } from "react-helmet";
const RefundPolicy = () => {
  return (
    <div className="privacy-policy-main-div">
      <Helmet>
        <title>Return, Refund, & Cancellation policy | EntranceZone | Scuben Ecommerce Private Limited</title>
        <meta name="description" content=" Return, Refund, & Cancellation policies with regards to EntarnceZone various services. Refunds, where eligible, are typically issued via the same instrument" />
      </Helmet>
      <h2 className="privacy-policy-main-heading">
        Refund and Cancellation Policy
      </h2>
      <h3 className="privacy-policy-headings" style={{ border: "unset" }}>
        Cancellation Policy
      </h3>{" "}
      <p className="privacy-policy-main-text">
        For Cancellations please contact us via{" "}
        <a href="https://www.entrancezone.com/contact-us">contact us link.</a>
      </p>
      <p className="privacy-policy-main-text">
        Requests received later than 7 business days prior to the end of the
        current service period will be treated as cancellation of services for
        the next service period.
      </p>
      <h3 className="privacy-policy-headings">Refund Policy</h3>
      <p className="privacy-policy-main-text">
        We will try our best to create the suitable design concepts for our
        clients.
      </p>
      <p className="privacy-policy-main-text">
        In case any client is not completely satisfied with our products we can
        provide a refund.{" "}
      </p>
      <p className="privacy-policy-main-text">
        If paid by credit card, refunds will be issued to the original credit
        card provided at the time of purchase and in case of payment gateway
        name payments refund will be made to the same account.
      </p>
      <h3 className="refund-policy-company-name">
        Scuben Ecommerce Private Limited
      </h3>
    </div>
  );
};

export default RefundPolicy;
