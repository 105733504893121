import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Menu, Icon, Avatar, Upload, message } from "antd";
import getUrl from "../../utilities/apiConstant";
import { Context } from "../../utilities/Context";

const { SubMenu } = Menu;

const Data = [
  { name: "Home", icon_path: "home", route_path: "/profile" },
  {
    name: "College Selection",
    icon_path: "select",
    route_path: "/profile/college-selection"
  },
  {
    name: "Student Profile",
    icon_path: "user",
    nested_menu: [
      {
        name: "Personal Info",
        icon_path: "form",
        route_path: "/profile/personal-information"
      },
      {
        name: "Academic Info",
        icon_path: "book",
        route_path: "/profile/academic-information"
      }
    ]
  },
  {
    name: "Application Tracking",
    icon_path: "read",
    route_path: "/profile/applications"
  }
  // {
  //   name: "Upgrade",
  //   icon_path: "cloud-upload",
  //   route_path: "/profile/upgrade",
  // },
  // ,{
  //   name: "Counselor",
  //   icon_path: "read",
  //   route_path: "/profile/counselor"
  // }
];

const SideMenuProfile = ({ pathName }) => {
  let activeKey;
  const { userData, profile_image_exist = false } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const fetchImageUrl = async () => {
    try {
      const response = await axios.get(getUrl("image"), {
        withCredentials: true
      });
      setImageUrl(response?.data ?? "");
    } catch (err) {}
  };

  useEffect(() => {
    if (profile_image_exist) {
      fetchImageUrl();
    }
  }, []);

  const handleChange = info => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, image => {
        setImageUrl(image);
        setLoading(false);
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const menuItems = Data.map((item, index) => {
    if (pathName === item.route_path) activeKey = item.name;
    const { nested_menu = [] } = item;
    if (nested_menu.length) {
      return (
        <SubMenu
          key={item.name}
          title={
            <span>
              <Icon type={item.icon_path} />
              <span>{item.name}</span>
            </span>
          }
        >
          {nested_menu.map(nested => {
            if (pathName === nested.route_path) activeKey = nested.name;
            return (
              <Menu.Item key={nested.name}>
                <Link to={nested.route_path}>
                  <span>
                    <Icon type={nested.icon_path} />
                    <span>{nested.name}</span>
                  </span>
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={item.name}>
        <Link to={item.route_path}>
          <span>
            <Icon type={item.icon_path} />
            <span>{item.name}</span>
          </span>
        </Link>
      </Menu.Item>
    );
  });

  const uploadButton = (
    <div>
      {loading ? (
        <Icon type="loading" style={{ color: "#ffffff", fontSize: "2rem" }} />
      ) : (
        <Avatar size={50}>
          {userData.user && userData.user.name
            ? userData.user.name.charAt(0).toUpperCase()
            : "EZ"}
        </Avatar>
      )}
    </div>
  );

  return (
    <>
      <Menu
        className="side-menu"
        theme="dark"
        defaultOpenKeys={["Student Profile"]}
        selectedKeys={activeKey}
        mode="inline"
      >
        <div className="profile-picture-main-div">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={`${getUrl("uploads")}/profile`}
            method="post"
            withCredentials={true}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            style={{
              width: "20px",
              height: "20px"
            }}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt={
                  userData.user.name.charAt(0).toUpperCase() +
                  userData.user.name.slice(1)
                }
                style={{
                  width: "100%",
                  height: "56px",
                  borderRadius: "50%"
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
          <div className="profile-picture-main-div-child">
            {userData.user
              ? userData.user.name.charAt(0).toUpperCase() +
                userData.user.name.slice(1)
              : null}
          </div>
        </div>
        {menuItems}
      </Menu>
    </>
  );
};

export default SideMenuProfile;
