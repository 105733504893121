import React, { useEffect, useState } from "react";
import { Input, Checkbox, Row, Col } from "antd";
const checkboxStyle = {
  fontFamily: "NunitoSans",
  height: "25px",
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "0.25px",
  color: "#3c1a5b",
  display: "flex",
  alignItems: "center",
  width: "100%",
};
const MultiLayout = ({
  fieldName,
  fields = [],
  keyName,
  type,
  addUpdateFilterCallback,
  selectedFilters,
  RemoveFiltersCallback,
}) => {
  const { Search } = Input;
  const { location } = window;
  const CheckboxGroup = Checkbox.Group;
  const [selectedChecked, setSelectedChecked] = useState([]);
  const [fieldsToShow, setFieldsToShow] = useState([]);
  const [allFields, setAllFields] = useState(fieldsToShow);
  const handleChange = (checkedValues) => {
    const selectedData = fields.filter((item) =>
      checkedValues.find((data) => data === item.id)
    );
    setSelectedChecked(checkedValues);
    addUpdateFilterCallback(type, keyName, selectedData);
  };
  useEffect(() => {
    const totalFields = fields?.length
      ? fields.map((field, id) => (
          <Row key={id} span={8}>
            <Checkbox
              key={`${field.id}-checkbox`}
              style={checkboxStyle}
              value={field.id}
            >
              <div className="table-child-div">{`${field.name} (${field.count})`}</div>
            </Checkbox>
          </Row>
        ))
      : [];
    setFieldsToShow(totalFields);
    setAllFields(totalFields);
  }, [fields]);
  useEffect(() => {
    if (!location.search) return;
    const query = location.search.substr(1).split("&");
    const mappedQuery = query.map((data) => data.split("="));
    const addDataToFilter = fields.filter((data) =>
      mappedQuery.find(
        (item) => item[0] === keyName && parseInt(item[1]) === data.id
      )
    );
    if (addDataToFilter.length) {
      const checkedValues = addDataToFilter.map((data) => data.id);
      setSelectedChecked(checkedValues);
      addUpdateFilterCallback(type, keyName, addDataToFilter);
    }
  }, []);

  useEffect(() => {
    if (!selectedChecked.length) return;
    const newData = selectedChecked.filter((selectedCheckedItem) =>
      selectedFilters.some(
        (filter) => filter.id === selectedCheckedItem && filter.key === keyName
      )
    );
    setSelectedChecked(newData);
  }, [selectedFilters]);
  const handleSearchChange = (e) => {
    setFieldsToShow(allFields);
    const searchedValue = e.target.value.toLowerCase();
    let result = [];
    if (searchedValue) {
      result = allFields.filter((data) => {
        if (
          data &&
          data.props.children.props.children.props.children
            .toLowerCase()
            .includes(searchedValue)
        ) {
          return data;
        }
      });
      if (!result.length) {
        result = <div style={{ margin: "1rem" }}>No result's Found</div>;
      }
      setFieldsToShow(result);
    } else {
      setFieldsToShow(allFields);
    }
  };
  const handleClearAll = () => {
    RemoveFiltersCallback(keyName);
  };
  const isCheckboxSelected = selectedFilters.find(
    (data) => data.key === keyName
  );
  return (
    <div className="parent-div">
      <div className="education-search-div">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="search-bar-alternative">{fieldName}</div>
          {isCheckboxSelected ? (
            <div
              className="clearall-div-single-multi-layout"
              onClick={handleClearAll}
            >
              Clear All
            </div>
          ) : null}
        </div>
        {fields && fields.length > 10 ? (
          <Search
            placeholder={`Search ${fieldName}`}
            onChange={handleSearchChange}
            style={{ paddingRight: "0.7rem" }}
          />
        ) : null}
      </div>
      <div className="education-parent-div">
        <CheckboxGroup onChange={handleChange} value={selectedChecked}>
          <Col>{fieldsToShow}</Col>
        </CheckboxGroup>
      </div>
    </div>
  );
};
export default MultiLayout;
