import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import get from "lodash/get";
import { Form, Input, notification, Icon, Spin } from "antd";
import { Context } from "../../utilities/Context";
import getUrl from "../../utilities/apiConstant";

const FormItem = Form.Item;
const ResetPassword = (props) => {
  const history = useHistory();
  const { updateContextData } = useContext(Context);
  const {
    form: { validateFields, getFieldDecorator },
    auth_page = "auth-page",
  } = props;
  const {
    location: { search },
  } = history;
  const [isLoading, setIsLoading] = useState(false);

  const url = new URL(`https://caf.entrancezone.com/reset-password${search}`);
  const token = url.searchParams.get("token");
  const email = url.searchParams.get("email");

  const handleRstPassword = async (e) => {
    e.preventDefault();
    const values = await validateFields();
    try {
      setIsLoading(true);
      const { password } = values;
      const response = await axios.post(
        `${getUrl("authForgotPassword")}/reset`,
        {
          token,
          email,
          password,
        }
      );
      setIsLoading(false);

      notification.success({
        message: get(response, "data.message") || "Something went wrong",
      });
      updateDataToContext();
      history.push("/");
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: get(error.response, "data.message") || "Something went wrong",
      });
    }
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  const updateDataToContext = async () => {
    try {
      const response = await axios(getUrl("user"), {
        withCredentials: true,
      });
      const data = response && response.data ? response.data.data : {};
      updateContextData(data);
    } catch (error) {
      // notification.error({
      //   message: "Something went wrong"
      // });
    }
  };
  return (
    <div className={`login-comp ${auth_page}`}>
      <div className="login-content">
        <h2 className="login-content__heading">Please Reset Your Password</h2>
        <div className="login-details">
          <Form onSubmit={handleRstPassword} style={{ display: "grid" }}>
            <FormItem>
              <Input
                className="login-details__input"
                placeholder="Email"
                value={email}
                readOnly
                prefix={
                  <Icon className="login-details__prefix-icon" type="mail" />
                }
              />
            </FormItem>
            <FormItem>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    min: 8,
                    message: "At least 8 characters!",
                  },
                ],
              })(
                <Input
                  className="login-details__input"
                  placeholder="Enter New Password"
                  type="password"
                  prefix={
                    <Icon className="login-details__prefix-icon" type="lock" />
                  }
                />
              )}
            </FormItem>
            <FormItem hasFeedback>
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(
                <Input.Password
                  className="login-details__input"
                  placeholder="Confirm Password"
                  type="password"
                  prefix={
                    <Icon className="login-details__prefix-icon" type="lock" />
                  }
                />
              )}
            </FormItem>

            <button
              className="register-submit__button"
              onClick={handleRstPassword}>
              RESET PASSWORD
            </button>
          </Form>
        </div>
      </div>
      {isLoading ? (
        <div className="login-comp__loading">
          <Spin size="large" />
        </div>
      ) : null}
    </div>
  );
};

export default Form.create()(ResetPassword);
