import React from "react";
import CollegeSelection from "./CollegeSelectionCard";

const RecommendedWrapper = ({ allColleges = [] }) => {
  const getRecommendedColleges = () => {
    //get all the colleges where is_applied is null and is_shortlist is null.
    const recommendedColleges = allColleges.filter(
      item => item.is_applied === false && item.is_shortlisted === false
    );
    return recommendedColleges.map((data, index) => (
      <CollegeSelection data={data} key={index} />
    ));
  };
  return (
    <div style={{ marginBottom: "4rem" }}>
      <div
        className="shortlisted-recommended-text"
        style={{ marginBottom: "2rem" }}
      >
        Recommended Colleges
      </div>
      {getRecommendedColleges()}
    </div>
  );
};
export default RecommendedWrapper;
