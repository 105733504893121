import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tabs, Dropdown, Icon, Menu, Checkbox } from "antd";
import ProfileStepper from "./ProfileStepper";
import TenthClass from "./TenthClass";
import TwelfthClass from "./TwelfthClass";
import AddAnotherEducation from "./AddAnotherEducation";
import Breadcrumbs from "../BreadCrumbs";
import Diploma from "./Diploma";
const { TabPane } = Tabs;

const AcademicInterests = (props) => {
  const {
    profileProps: { data = {} },
  } = props;
  const { meta = {}, detail = [] } = data;
  const [showDiploma, setShowDiploma] = useState(false);
  const [showIntermediate, setShowIntermediate] = useState(false);
  const [academicInterestsData = {}] =
    detail.filter((item) => item.type === "academic_information") || [];

  const tenthClassData = (academicInterestsData.value || []).find(
    (item) => item.id === 4
  );
  const twelfthClassData = (academicInterestsData.value || []).find(
    (item) => item.id === 5
  );
  const diplomaData = (academicInterestsData.value || []).find(
    (item) => item.id === 6
  );
  const collegesData = (academicInterestsData.value || []).filter(
    (item) => item.institution_type === "college"
  );

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setShowIntermediate(true);
          setShowDiploma(false);
        }}>
        12th
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setShowDiploma(true);
          setShowIntermediate(false);
        }}>
        Diploma
      </Menu.Item>
    </Menu>
  );
  const diplomaDataFirst = diplomaData ? (
    <Diploma key={`diploma`} data={diplomaData} meta={meta} />
  ) : showDiploma ? (
    <Diploma key={`diploma_1`} data={diplomaData} meta={meta} />
  ) : null;
  const twelfthClassDataFirst = twelfthClassData ? (
    <TwelfthClass key={`twelfth`} data={twelfthClassData} meta={meta} />
  ) : showIntermediate ? (
    <TwelfthClass key={`twelfth_1`} data={twelfthClassData} meta={meta} />
  ) : null;
  return (
    <>
      <Helmet>
        <title>
          Academic Interests - Online College Admission Form | Entrancezone
        </title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      <div>
        <Breadcrumbs />
        {/* <ProfileStepper /> */}
        <p style={{ fontSize: "1.2rem", marginTop: "1rem" }}>
          * We will not, in any circumstances, share your personal information
          with other individuals or organizations without your permission.
        </p>
        <Tabs
          defaultActiveKey="academic"
          style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <TabPane
            tab={
              <Link
                to="/profile/personal-information"
                style={{ padding: "1.2rem 0" }}>
                Personal Information
              </Link>
            }
            key="personal"
          />
          <TabPane
            tab={
              <Link
                to="/profile/academic-information"
                style={{ padding: "1.2rem 0" }}>
                Academic Information
              </Link>
            }
            key="academic"
          />
        </Tabs>
        <h2
          className="heading-2"
          style={{ marginBottom: "2rem", color: "#3c1a5b", fontWeight: "500" }}>
          Academic Information
        </h2>
        {/* <div style={{ marginBottom: "1rem" }}>
        <Checkbox
          checked={
            tenthClassData && tenthClassData.detail_exists
              ? tenthClassData.detail_exists
              : undefined
          }>
          10th
        </Checkbox>
        <Checkbox
          checked={
            twelfthClassData && twelfthClassData.detail_exists
              ? twelfthClassData.detail_exists
              : diplomaData && diplomaData.detail_exists
              ? diplomaData.detail
              : undefined
          }>
          12th or Diploma{" "}
        </Checkbox>
      </div> */}
        <TenthClass data={tenthClassData} meta={meta} />
        {!(twelfthClassData && diplomaData) ? (
          <div style={{ marginBottom: "5rem" }}>
            <Dropdown overlay={menu} className="academic-info-dropdown">
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}>
                Select 12th or Diploma{" "}
                <Icon
                  type="down"
                  style={{ marginLeft: "0.8rem", fontSize: "1.6rem" }}
                />
              </a>
            </Dropdown>
          </div>
        ) : null}

        {twelfthClassData
          ? [diplomaDataFirst, twelfthClassDataFirst]
          : [twelfthClassDataFirst, diplomaDataFirst]}
        <AddAnotherEducation data={collegesData} meta={meta} />
      </div>
    </>
  );
};

export default AcademicInterests;
