import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../utilities/Context";
import { Tag } from "antd";
const tagStyle = {
  padding: "0.5rem 1rem",
  borderRadius: "1.4rem",
  border: "solid 0.13rem #3c1a5b",
  backgroundColor: "#ffffff",
  marginTop: "1rem"
};
const Tags = ({
  selectedFilters = [],
  deleteFilterCallback,
  setSelectedFilters,
  totalFilters
}) => {
  const [totalTags, setTotalTags] = useState([]);
  const handleTagClose = data => {
    deleteFilterCallback(data);
  };
  const { setCollegeData } = useContext(Context);
  useEffect(() => {
    const allTags = selectedFilters.map((tag, id) => (
      <Tag
        key={id}
        visible={true}
        style={tagStyle}
        closable
        onClose={() => handleTagClose(tag)}
      >
        {tag.name}
      </Tag>
    ));
    setTotalTags(allTags);
  }, [selectedFilters]);
  return (
    <div className="parent-div-tags">
      <div className="total-tags-div">{totalTags}</div>
      {totalTags.length ? (
        <div
          className="clearall-div-right-side"
          onClick={() => {
            setSelectedFilters([]);
            totalFilters = [];
            setCollegeData({}, "education");
            setCollegeData({}, "stream");
          }}
        >
          CLEAR ALL
        </div>
      ) : null}
    </div>
  );
};
export default Tags;
