import React from "react";
import { Divider, Row, Col } from "antd";
const applyImage = require("../img/whyApplyImage.svg");
const WhyApply = () => {
  return (
    <Row className="why-apply" style={{ gridColumn: "1/-1" }}>
      <Col xs={24} md={12}>
        <img src={applyImage} alt="" className="why-apply__image" />
      </Col>
      <Col xs={24} md={12} className="why-apply-content">
        <h2 className="heading-1 why-apply-content__heading">Why Apply ?</h2>
        <div className="why-apply-content__divider">
          <Divider className="why-apply-content__divider--length" />
        </div>
        <p className="heading-2 why-apply-content__para">
          Save money on college applications
        </p>
        <div className="why-apply-content__divider">
          <Divider className="why-apply-content__divider--length" />
        </div>
        <p className="heading-2 why-apply-content__para">
          Save hours of your time
        </p>
        <div className="why-apply-content__divider">
          <Divider className="why-apply-content__divider--length" />
        </div>
        <p className="heading-2 why-apply-content__para">
          Track your college applications with ease
        </p>
        <div className="why-apply-content__divider">
          <Divider className="why-apply-content__divider--length" />
        </div>
        <p className="heading-2 why-apply-content__para">
          Single application for all your choices
        </p>
      </Col>
    </Row>
  );
};

export default WhyApply;
