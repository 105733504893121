import React, { useState, useEffect } from "react";
import { message } from "antd";
const CourseBranch = ({
  data,
  handleSelectedBranch,
  isSpaceAvailable,
  branchPriority
}) => {
  const { id, full_name } = data;
  const [selected, setSelected] = useState(false);
  const handleSelect = () => {
    if (isSpaceAvailable >= 5) {
      message.warn("You have selected 5 colleges !");
      return;
    }
    setSelected(true);
    handleSelectedBranch(id, full_name);
  };
  const handleCancel = () => {
    setSelected(false);
    handleSelectedBranch(id, full_name);
  };
  useEffect(() => {
    if (branchPriority.length) {
      const isSelected = branchPriority.some(data => data.id === id);
      setSelected(isSelected);
    }
  }, []);
  return (
    <div
      className={
        selected
          ? "shortlist-course-branch-main-div-selected"
          : "shortlist-course-branch-main-div"
      }
      onClick={selected ? handleCancel : handleSelect}
    >
      <div className="set-priority-name-div">{full_name}</div>
      {selected ? (
        <button className="antd-button-select-now">Cancel</button>
      ) : (
        <button
          className={
            isSpaceAvailable < 5
              ? "antd-button-select-now"
              : "antd-button-select-now-inactive"
          }
        >
          Select
        </button>
      )}
    </div>
  );
};
export default CourseBranch;
