import React, { useState, useEffect, useContext } from "react";
import { Steps, Spin, Carousel, notification, Icon } from "antd";
import PricingCardProfile from "./PricingCardProfile";
import ReviewSelection from "./ReviewSelection";
import { enquireScreen } from "enquire-js";
import { Context } from "../../../utilities/Context";
import axios from "axios";
import getUrl from "../../../utilities/apiConstant";
// import Footer from "../../../pages/Footer";
const { Step } = Steps;
const stepsData = [
  {
    title: " Choose a plan",
    description: "Select a plan that suits your needs.",
  },
  {
    title: "  Review selection",
    description: "Review your selection.",
  },
  {
    title: "  Payment",
    description: "Choose a payment gateway.",
  },
];
const DashboardPricing = ({ location = {} }) => {
  const {
    selectedPackContext,
    setSelectedPackContext,
    redirect_url,
    setRedirectUrl,
  } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(undefined);
  const [current, setCurrent] = useState(0);
  const [totalCards, setTotalCards] = useState([]);
  const handleBack = () => {
    setCurrent(() => current - 1);
    setSelectedPackContext({});
  };
  const handleSelectedPack = (data) => {
    if (!redirect_url) {
      setRedirectUrl(window.location.pathname);
    }
    setSelectedPackContext(data);
    setCurrent(() => current + 1);
  };
  const fetchPackagesData = async () => {
    try {
      setIsLoading(true);
      const { data = [] } = await axios.get(`${getUrl("packages")}`, {
        withCredentials: true,
      });
      const cards = data.packages.map((data, index) => (
        <PricingCardProfile
          key={index}
          Data={data}
          handleSelectedPackCallback={handleSelectedPack}
        />
      ));
      setTotalCards(cards);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({ message: "Something went wrong !" });
    }
  };
  useEffect(() => {
    enquireScreen((status) => setIsMobile(status));
    if (location.state && location.state.currentValue && selectedPackContext) {
      setCurrent(location.state.currentValue);
    }
    if (selectedPackContext) {
      setCurrent(1);
    }
    fetchPackagesData();
  }, []);
  const totalSteps = stepsData.map((data, index) => (
    <Step key={index} title={data.title} description={data.description} />
  ));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          gridColumn: "1/-1",
        }}>
        <Spin />
      </div>
    );
  }
  return (
    <>
      <div className="dashboard-pricing-main-div">
        {!isMobile ? (
          <div className="dashboard-pricing-main-div__item-1">
            <Steps
              className="dashboard-pricing-stepper-container"
              current={current}>
              {totalSteps}
            </Steps>
          </div>
        ) : null}
        {isMobile ? (
          current === 0 ? (
            <Carousel {...settings} className="dashboard-pricing-carousel">
              {totalCards}
            </Carousel>
          ) : null
        ) : current === 0 ? (
          totalCards
        ) : null}
        {current === 1 ? (
          <div className="dashboard-pricing-main-div__item-2">
            <ReviewSelection selectedPack={selectedPackContext} />
          </div>
        ) : null}
        <div className="dashboard-pricing-main-div__item-3">
          {current > 0 ? (
            <button className="secondary-btn back-btn" onClick={handleBack}>
              <Icon type="left" style={{ marginRight: ".5rem" }} />
              Package Selection
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default DashboardPricing;
