import React from "react";
import CollegeSelection from "./CollegeSelectionCard";
const AppliedCollegeWrapper = ({ appliedData = [] }) => {
  const appliedColleges = appliedData.map((data, index) => (
    <CollegeSelection data={data} key={index} />
  ));
  if (!appliedData.length) {
    return <div />;
  }
  return (
    <div style={{ marginBottom: "4rem" }}>
      <div
        className="shortlisted-recommended-text"
        style={{ marginBottom: "2rem" }}>
        Applied Colleges
      </div>
      {appliedColleges}
    </div>
  );
};
export default AppliedCollegeWrapper;
