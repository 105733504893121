import React from "react";
// import { Context } from "../utilities/Context";

const Counselor = () => {
  // const { counselorData } = useContext(Context);
  return (
    <div>
      <h2>Counselor Information:</h2>
      <p style={{ color: "#3c1a5b" }}>
        <span style={{ color: "#faca45", fontWeight: "500" }}>Name:</span>{" "}
        Harshvardhan Soni
      </p>
      <p style={{ color: "#3c1a5b" }}>
        <span style={{ color: "#faca45", fontWeight: "500" }}>Contact No:</span>{" "}
        +91- 98780-37141
      </p>
    </div>
  );
};

export default Counselor;
