import React, { useState, useContext } from "react";
import { Input, Icon, Form, Spin, notification, Row, Col } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import getUrl from "../../utilities/apiConstant";
import get from "lodash/get";
import CAFPhoneAuth from "./CAFPhoneAuth";
import { Context } from "../../utilities/Context";

const FormItem = Form.Item;

const CAFSignUp = (props) => {
  const history = useHistory();
  const { updateContextData, selectedCollegesData } = useContext(Context);
  const {
    form: { getFieldDecorator },
    auth_page,
    onProceed,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [response, setResponse] = useState(null);
  const [phoneVerify, setPhoneVerify] = useState(false);

  const pathName = window.location.pathname;

  const handleSignup = async (e) => {
    e.preventDefault();
    const values = await props.form.validateFields();
    try {
      setLoading(true);
      const { email, password, first_name, last_name, phone } = values;
      const response = await axios.post(
        `${getUrl("authSignup")}`,
        {
          first_name,
          last_name,
          email,
          phone,
          password,
          source: "caf",
          path: pathName,
        },
        { withCredentials: true }
      );
      setLoading(false);
      setResponse(response.data.message);
      updateDataToContext();
      if (onProceed) {
        shortlistApi();
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data.statusCode === 400) {
        setErrMsg(error.response.data.message);
      } else {
        notification.error({
          message:
            get(error.response, "data.message") || "Something went wrong",
        });
      }
    }
  };

  const displayMessage = () => {
    if (response) {
      return (
        <div className="register-content" style={{ height: "50rem" }}>
          <h2 className="login-content__heading">
            Please verify your account:
          </h2>
          <div className="register-details">
            <div style={{ textAlign: "center" }}>
              <h4>{response}</h4>
            </div>
          </div>
        </div>
      );
    } else if (errMsg) {
      return (
        <div className="register-content" style={{ height: "50rem" }}>
          <h2 className="login-content__heading">
            You are already Registered:
          </h2>
          <div className="register-details">
            <div style={{ textAlign: "center" }}>
              <h4>{errMsg}</h4>
            </div>
          </div>
        </div>
      );
    }
  };

  // handleFacebookVerify = async () => {
  //   window.open(getUrl("authFacebook"), "sharer", "width=548,height=400");
  //   window.loginCallBack = this.loginCallBack;
  // };

  // const handleGoogleVerify = async () => {
  //   window.open(getUrl("authGoogle"), "sharer", "width=548,height=400");
  //   window.loginCallBack = loginCallBack;
  // };

  // const loginCallBack = status => {
  //   if (status === "success" && !props.auth_page) {
  //     props.onCloseModal();
  //     updateDataToContext();
  //     notification.success({
  //       message: "Signed In"
  //     });
  //   } else if (status === "success" && props.auth_page) {
  //     // Router.push("/");
  //     // this.updateDataToContext();
  //     notification.success({
  //       message: "Signed In"
  //     });
  //   }
  // };

  const updateDataToContext = async () => {
    try {
      const response = await axios(getUrl("user"), {
        withCredentials: true,
      });
      const data = response.data.data;
      updateContextData(data);
    } catch (error) {
      // notification.error({
      //   message: "Something went wrong"
      // });
    }
  };

  const shortlistApi = async () => {
    const selectedData = selectedCollegesData;
    try {
      await axios.post(
        `${getUrl("root")}/caf/user/shortlist/proceed`,
        selectedData,
        {
          withCredentials: true,
        }
      );
      props.history.push("/profile/college-selection");
      // this.updateDataToContext();
    } catch (e) {
      notification.error({ message: "Something went wrong !" });
    }
  };

  const handlePhoneAuth = (value, closeModal) => {
    setPhoneVerify(value);
    // for closing auth modal & update userData
    if (closeModal) {
      // this.props.auth_page ? Router.push("/") : this.props.onCloseModal();
      props.onCloseModal();
      updateDataToContext();
    }
  };

  const handleLoadingLayout = () => {
    return isLoading ? (
      <div className="login-comp__loading">
        <Spin size="large" />
      </div>
    ) : null;
  };

  const handlePhoneVerifyLayout = () => {
    return phoneVerify ? (
      <div className="register-content__phone-verify">
        <CAFPhoneAuth
          type="signup"
          nameInput={true}
          handlePhoneAuth={handlePhoneAuth}
        />
      </div>
    ) : null;
  };

  const validateMobileNo = (rule, value, callback) => {
    if (isNaN(value)) {
      callback("!! No. should only be in digits !!");
      return;
    }
    callback();
  };

  const onSignInClick = () => {
    if (auth_page) {
      history.push("/signin");
      return;
    }
    props.showLoginScreen();
  };

  return (
    <div className={`register-comp ${auth_page}`}>
      {/* <CAFAuthFeature
        auth_page={auth_page}
        page="signup"
        authFeatureCallback={props.showLoginScreen}
      /> */}
      {!response && !errMsg ? (
        <div className="register-content">
          <h2 className="login-content__heading">Create Account</h2>
          <div className="login-content__social-icon">
            {/* <div
              className="login-content__social-icon--border"
              onClick={handleGoogleVerify}
            >
              <Icon
                type="google"
                style={{ fontWeight: "bolder", fontSize: "1.7rem" }}
              />
            </div> */}

            <div
              className="login-content__social-icon--border"
              onClick={() => handlePhoneAuth(true)}>
              <Icon
                type="phone"
                theme="filled"
                style={{ fontWeight: "bolder", fontSize: "1.7rem" }}
              />
            </div>
          </div>
          <p style={{ color: "#A9A9A9" }}>
            or use your email for registration:
          </p>
          <div className="register-details">
            <Form style={{ display: "grid" }}>
              <Row gutter={16}>
                <Col xs={12}>
                  <FormItem>
                    {getFieldDecorator("first_name", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your First name!",
                        },
                      ],
                    })(
                      <Input
                        className="register-details__input"
                        placeholder="First Name"
                        prefix={
                          <Icon
                            className="register-details__prefix-icon"
                            type="user"
                          />
                        }
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem>
                    {getFieldDecorator("last_name")(
                      <Input
                        className="register-details__input"
                        placeholder="Last Name"
                        prefix={
                          <Icon
                            className="register-details__prefix-icon"
                            type="user"
                          />
                        }
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              {/* <FormItem>
                  {getFieldDecorator('last_name', {
                    rules: [
                      { required: false, message: 'Please input Last name!' }
                    ]
                  })(
                    <Input
                      className="register-details__input"
                      placeholder="Last Name"
                      prefix={
                        <Icon
                          className="register-details__prefix-icon"
                          type="user"
                        />
                      }
                    />
                  )}
                </FormItem> */}

              <FormItem>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "Please input a valid email!",
                    },
                  ],
                })(
                  <Input
                    className="register-details__input"
                    placeholder="Email"
                    autoComplete="useremail"
                    prefix={
                      <Icon
                        className="register-details__prefix-icon"
                        type="mail"
                      />
                    }
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("phone", {
                  rules: [
                    {
                      required: true,
                      message: "Phone is required",
                    },
                    {
                      len: 10,
                      type: "string",
                      message: "Please enter 10 digit number",
                    },
                    {
                      validator: validateMobileNo,
                    },
                  ],
                })(
                  <Input
                    className="register-details__phone"
                    addonBefore="+91 - "
                    placeholder="Enter Your Phone No..."
                    maxLength={10}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      min: 8,
                      message: "At least 8 characters!",
                    },
                  ],
                })(
                  <Input
                    className="register-details__input"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    prefix={
                      <Icon
                        className="register-details__prefix-icon"
                        type="lock"
                      />
                    }
                  />
                )}
              </FormItem>
              <p onClick={onSignInClick} style={{ cursor: "pointer" }}>
                or <span style={{ color: "#1890ff" }}>Sign In</span>
              </p>
              <button
                className="register-submit__button"
                onClick={handleSignup}
                style={{ marginTop: "2rem" }}>
                SIGN UP
              </button>
            </Form>
          </div>
        </div>
      ) : (
        displayMessage()
      )}
      {handleLoadingLayout()}
      {handlePhoneVerifyLayout()}
    </div>
  );
};

export default Form.create()(CAFSignUp);
