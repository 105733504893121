import React, { useState, useEffect, useContext } from "react";
import { Steps } from "antd";
import { useHistory } from "react-router-dom";
import { Context } from "../../utilities/Context";

const { Step } = Steps;

const ProfileStepper = () => {
  const history = useHistory();
  const {
    is_academic_detail_complete,
    is_personal_detail_complete,
    active_package_id,
    setRedirectUrl
  } = useContext(Context);
  const [current, setCurrent] = useState(-1);

  useEffect(() => {
    if (!is_personal_detail_complete) {
      setCurrent(-1);
    } else if (!is_academic_detail_complete) {
      setCurrent(0.9);
    } else if (active_package_id) {
      setCurrent(3.5);
    } else {
      setCurrent(1.9);
    }
  }, [
    is_personal_detail_complete,
    is_academic_detail_complete,
    active_package_id
  ]);

  const stepperControl = current => {
    switch (current) {
      case 0:
        history.push("/profile/personal-information");
        return;
      case 1:
        history.push("/profile/academic-information");
        break;
      case 2:
        setRedirectUrl("/profile/college-selection");
        history.push("/profile/upgrade");
        break;
      case 3:
        history.push("/profile/applications");
        break;
      default:
        return;
    }
  };

  return (
    <div className="profile-stepper">
      <Steps
        size="small"
        initial={0}
        current={current}
        onChange={stepperControl}
      >
        <Step
          title={"Personal Information"}
          subTitle={
            is_personal_detail_complete ? "(Completed)" : "(Incomplete)"
          }
        />
        <Step
          title={"Academic Information"}
          subTitle={
            is_academic_detail_complete ? "(Completed)" : "(Incomplete)"
          }
        />
        <Step
          title={"Select Package"}
          subTitle={active_package_id ? "(Selected)" : "(Not Selected)"}
        />
        <Step title={"Application Tracking"} />
      </Steps>
    </div>
  );
};

export default ProfileStepper;
