import React, { useEffect, useState } from "react";
import { Button, notification } from "antd";
// import { useHistory } from "react-router-dom";
import axios from "axios";
import { UploadDoc } from "./UploadDoc";
import getUrl from "utilities/apiConstant";
import { SpinnerComp } from "components/SpinnerComp";
import LoadingOverlay from "components/LoadingOverlay";

export const UploadDocWrapper = (props) => {
  // const history = useHistory();
  const { form_id, setFormIdCallback } = props;
  const [loading, setLoading] = useState(false);
  const [uploadData, setUploadData] = useState(undefined);
  const [newUploadData, setNewUploadData] = useState([]);
  const educationLevelIds =
    uploadData?.documents_required?.map((item) => item?.id) ?? [];
  useEffect(() => {
    const fetchUploadDocumentData = async () => {
      try {
        const response = await axios.get(
          `${getUrl("root_v2")}/caf/forms/${form_id}/document`,
          { withCredentials: true }
        );
        setUploadData(response?.data?.data);
        if (response?.data?.data?.documents?.length) {
          const marksheetData = response.data.data?.documents.filter(
            (item) => item.document_type === "Marksheet"
          );
          setNewUploadData(marksheetData);
        }
      } catch (error) {
        setUploadData(undefined);
      }
    };

    fetchUploadDocumentData();
  }, []);

  const handleUploadDocument = async () => {
    try {
      const required_document_ids = uploadData?.documents_required?.map(
        (item) => item.id
      );
      const verify =
        uploadData?.documents_required?.length === newUploadData?.length &&
        newUploadData?.every((item) =>
          required_document_ids?.includes(item?.education_level_id)
        );
      if (!verify)
        throw { message: "To Proceed, first add all the required documents !" };
      setLoading(true);
      await axios.post(
        `${getUrl("root_v2")}/caf/forms/${form_id}/documents`,
        newUploadData,
        { withCredentials: true }
      );
      setFormIdCallback("checkout");
      window.scrollTo(0, 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: error?.message ?? "Something went wrong !",
      });
    }
  };
  const updateDocuments = (education_level_id) => {
    const isDocumentAlreadyAvailable = newUploadData.some(
      (item) => item.education_level_id === education_level_id
    );
    if (isDocumentAlreadyAvailable) return;
    const data = [...newUploadData, { education_level_id }];
    setNewUploadData(data);
  };

  const documentsData = uploadData?.documents ?? [];
  const uploadList = [
    {
      highlighter: "10th Marksheet",
      education_level_id: 4,
    },
    {
      highlighter: "12th Marksheet",
      education_level_id: 5,
      is_divider: true,
    },
    {
      highlighter: "Diploma Marksheet",
      education_level_id: 6,
    },
    {
      highlighter: "Under Graduation (UG)",
      education_level_id: 1,
    },
    {
      highlighter: "Post Graduation (PG)",
      education_level_id: 2,
    },
  ];

  const data =
    uploadList?.filter((item) =>
      educationLevelIds?.includes(item.education_level_id)
    ) ?? [];

  if (!uploadData) return <SpinnerComp />;
  return (
    <LoadingOverlay active={loading}>
      {data?.map((item, index) => (
        <UploadDoc
          key={index}
          text="Upload Your"
          document_type="Marksheet"
          data={documentsData}
          form_id={form_id}
          updateDocumentsCallback={updateDocuments}
          {...item}
        />
      )) ?? []}
      <div className="academic-button-wrapper" style={{ margin: "3rem 0" }}>
        <div className="academic-button-wrapper__1">
          <Button
            className="secondary-btn personal-back-btn"
            onClick={() => setFormIdCallback("academic")}
            disabled={loading}
          >
            Back
          </Button>
          {/* <Button
            className="secondary-btn personal-save-btn"
            onClick={() => history.push("/")}
          >
            Save and Exit
          </Button> */}
        </div>

        <Button
          className="yellow-btn academic-button-wrapper__2"
          onClick={handleUploadDocument}
          loading={loading}
        >
          Proceed
        </Button>
      </div>
    </LoadingOverlay>
  );
};
