import React from "react";
import Course from "./Course/Course";
import Facilities from "../CollegeDetails/Facilities/Facilities";
const CourseDetails = ({
  college_complete_data,
  college_id,
  courses,
  facilities,
  description
}) => {
  return (
    <div className="main-div-course-details">
      <p className="course-details-main-div-para">Courses Offered</p>
      <div className="course-wrapper-div">
        <Course
          college_id={college_id}
          college_data={college_complete_data}
          courses={courses}
        />
      </div>
      <div
        className="content-div"
        style={{ marginBottom: "4rem", textAlign: "justify" }}
      >
        <h2
          className="course-details-main-div-para"
          style={{ marginBottom: "2rem" }}
        >
          Description
        </h2>
        {description}
      </div>

      {facilities ? (
        <p className="course-details-main-div-para">Facilities</p>
      ) : null}
      {facilities ? (
        <Facilities college_id={college_id} facilities={facilities} />
      ) : null}
    </div>
  );
};
export default CourseDetails;
