import React from "react";
import Course from "./Application-Course";
const CourseDetails = ({ courses, handleApply }) => {
  return (
    <div className="main-div-course-details">
      <p className="course-details-main-div-para">Courses Offered</p>
      <div className="course-wrapper-div">
        <Course courses={courses} handleApply={handleApply} />
      </div>
    </div>
  );
};
export default CourseDetails;
