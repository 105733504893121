import React, { useContext } from "react";
import { Row, Col } from "antd";
import { Context } from "../utilities/Context";
const stepOne = require("../img/process-one.svg");
const stepTwo = require("../img/process-two.svg");
const stepThree = require("../img/process-three.svg");
const stepFour = require("../img/process-four.svg");
const bottomImg = require("../img/process-bottom.png");

const processData = [
  {
    path: stepOne,
    name: "1. Tell us About Yourself",
    description:
      "Click Get Started and tell us what are you looking for. We'll help you get there!"
  },
  {
    path: stepTwo,
    name: "2. Filter and Select Colleges",
    description:
      "You are shown colleges relevant to your preferences. Select them to proceed."
  },
  {
    path: stepThree,
    name: "3. Fill The One Time Application",
    description:
      "Enter your personal & academic details. Now you can apply to as many colleges you want."
  },
  {
    path: stepFour,
    name: "4. Pack Selection & Submission",
    description:
      "You must select between a free and a paid pack, then proceed to submit your application."
  }
];

const Process = () => {
  const { current, setCurrent } = useContext(Context);
  return (
    <div className="processes" style={{ gridColumn: "1/-1", margin: "0rem" }}>
      <h1
        className="heading-1 processes__heading"
        style={{ gridColumn: "1/-1" }}>
        The Process
      </h1>
      <p className="processes__description">
        Our Common Application Form allows students to apply to any number of
        colleges in minimal steps. We ask you to fill your information once and
        then apply to colleges in just a few clicks, have a look:
      </p>
      <div className="process">
        {processData.map((item, index) => {
          return (
            <div className="process-content" key={index}>
              <img src={item.path} alt="" className="process-content__image" />
              <p className="heading-3 process-content__name">{item.name}</p>
              <p className="process-content__description">{item.description}</p>
            </div>
          );
        })}
      </div>
      <button
        className="home-screen__btn-get-started process-btn"
        onClick={() => {
          setCurrent(current !== 3 ? 2 : 3);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}>
        Get Started
      </button>
      <img
        src={bottomImg}
        alt=""
        className=""
        style={{
          width: "100%",
          gridColumn: "center-start/center-end"
        }}
      />
    </div>
  );
};

export default Process;
