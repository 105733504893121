import React from "react";
import { Form, Input, Button, notification, Icon } from "antd";
import axios from "axios";
import _get from "lodash/get";
import getUrl from "../../utilities/apiConstant";

const FormItem = Form.Item;

class CAFPhoneAuth extends React.Component {
  state = {
    isLoading: false,
    showVerifyOTP: false,
    sendOTP: false,
    resendOTP: false,
    seconds: 30,
    phone: null,
  };

  handleSendOTP = async (e) => {
    e.preventDefault();
    const values = await this.props.form.validateFields();
    try {
      this.setState({ isLoading: true });
      const { phone, first_name, last_name } = values;
      const response = await axios.post(
        `${getUrl("root")}/auth/phone/${this.props.type}`,
        { phone, first_name, last_name },
        { withCredentials: true }
      );
      this.setState({
        isLoading: false,
        showVerifyOTP: true,
        sendOTP: true,
        phone: phone,
      });
      this.startTimer();
      notification.success({
        message: _get(response, "data.message") || "Something went wrong",
      });
    } catch (error) {
      this.setState({ isLoading: false });
      notification.error({
        message: _get(error.response, "data.message") || "Something went wrong",
      });
    }
  };

  handleVerifyOTP = async (e) => {
    e.preventDefault();
    const values = await this.props.form.validateFields();
    try {
      this.setState({ isLoading: true });
      const { phone, otp } = values;
      const response = await axios.post(
        `${getUrl("root")}/auth/phone/verify-otp`,
        { phone, OTP: otp },
        { withCredentials: true }
      );
      this.setState({ isLoading: false });
      notification.success({
        message: _get(response, "data.message") || "Something went wrong",
      });
      if (this.props.changeHeading) {
        this.props.fun_setPhoneNumber(phone);
        this.props.onCancel();
      } else {
        this.props.handlePhoneAuth(false, true);
      }

      // this.props.phoneAuth(true);
      // this.props.phoneNumber(phone);
      // this.props.onCancel();
    } catch (error) {
      this.setState({ isLoading: false });
      notification.error({
        message: _get(error.response, "data.message") || "Something went wrong",
      });
    }
  };

  handleResendOTP = async (e) => {
    e.preventDefault();
    const values = await this.props.form.getFieldsValue();
    try {
      const { phone } = values;
      const response = await axios.post(
        `${getUrl("root")}/auth/phone/resend-otp`,
        { phone },
        { withCredentials: true }
      );
      this.setState({ resendOTP: false, sendOTP: true });
      this.startTimer();
      notification.success({
        message: _get(response, "data.message") || "Something went wrong",
      });
    } catch (error) {
      notification.error({
        message: _get(error.response, "data.message") || "Something went wrong",
      });
    }
  };

  startTimer = () => {
    this.handleInterval = setInterval(() => {
      this.countDown();
    }, 1000);
  };
  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({ seconds: seconds });
    if (seconds === 0) {
      this.stopTimer();
      this.setState({ seconds: 30, resendOTP: true, sendOTP: false });
    }
  };
  stopTimer = () => {
    clearInterval(this.handleInterval);
  };

  componentDidMount() {
    if (this.props.changeHeading) {
      this.props.form.setFieldsValue({
        phone: this.props.phone,
      });
    }
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  handlePhoneEdit = () => {
    this.stopTimer();
    this.setState({
      seconds: 30,
      showVerifyOTP: false,
      sendOTP: false,
      resendOTP: false,
    });
  };

  validateMobileNo = (rule, value, callback) => {
    if (isNaN(value)) {
      callback("!! No. should only be in digits !!");
      return;
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      nameInput,
      handlePhoneAuth,
      changeHeading,
      phone: contact,
    } = this.props;
    const {
      isLoading,
      showVerifyOTP,
      sendOTP,
      seconds,
      resendOTP,
      phone,
    } = this.state;
    return (
      <Form
        className="phone-modal-form"
        style={{ padding: " 2rem ", display: "grid" }}>
        <div
          className="send-otp"
          style={{
            gridColumn: "1/-1",
            gridRow: "1/-1",
            display: "grid",
            justifyContent: "center",
          }}>
          {/* <img
            src="../../../static/img/verify_phone.png"
            alt=""
            className="verification"
            style={{
              justifySelf: 'center',
              width: '20rem',
              height: '17rem',
              marginBottom: '2rem'
            }}
          /> */}
          <h2
            className="login-content__heading"
            style={{ marginBottom: "2rem" }}>
            {changeHeading
              ? changeHeading
              : nameInput
              ? "Sign up through Phone"
              : "Sign in through Phone"}
          </h2>
          {nameInput ? (
            <>
              <FormItem className="phone-input">
                {getFieldDecorator("first_name", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your first name",
                    },
                  ],
                })(
                  <Input
                    className="phone__input-name"
                    placeholder="First Name"
                    prefix={
                      <Icon
                        className="register-details__prefix-icon"
                        type="user"
                      />
                    }
                  />
                )}
              </FormItem>
              <FormItem className="phone-input">
                {getFieldDecorator("last_name")(
                  <Input
                    className="phone__input-name"
                    placeholder="Last Name"
                    prefix={
                      <Icon
                        className="register-details__prefix-icon"
                        type="user"
                      />
                    }
                  />
                )}
              </FormItem>
            </>
          ) : null}
          <FormItem className="phone">
            {getFieldDecorator("phone", {
              rules: [
                {
                  required: true,
                  message: "Phone is required",
                },
                {
                  len: 10,
                  type: "string",
                  message: "Please enter 10 digit number",
                },
                {
                  validator: this.validateMobileNo,
                },
              ],
            })(
              <Input
                className="phone__input"
                addonBefore="+91 - "
                placeholder="Enter Your Phone No..."
                maxLength={10}
              />
            )}
          </FormItem>
          <div style={{ display: "grid", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={this.handleSendOTP}
              loading={isLoading}
              style={{
                justifySelf: "center",
                backgroundColor: "#3c1a5b",
                border: "none",
              }}>
              Send OTP
            </Button>
            {!changeHeading ? (
              <p
                onClick={() => handlePhoneAuth(false)}
                style={{
                  marginTop: "2rem",
                  cursor: "pointer",
                  color: "#1890ff",
                }}>
                {nameInput
                  ? "Other options for sign up"
                  : "Other options for sign in"}
              </p>
            ) : null}
          </div>
        </div>
        {showVerifyOTP ? (
          <div
            style={{
              gridColumn: "1/-1",
              gridRow: "1/-1",
              backgroundColor: "#f7f8f9",
              zIndex: "2",
              display: "grid",
              rowGap: "2rem",
              justifyContent: "center",
              textAlign: "center",
            }}>
            <h2
              className="login-content__heading"
              style={{ marginBottom: "2rem" }}>
              {changeHeading
                ? changeHeading
                : nameInput
                ? "Sign up through Phone"
                : "Sign in through Phone"}
            </h2>
            <p>
              {`+91- ${phone}`}
              <Icon
                type="edit"
                theme="twoTone"
                onClick={this.handlePhoneEdit}
              />
            </p>
            <p style={{ fontSize: "2rem", color: "#1d1b1c" }}>
              Enter 4 - Digit Code
            </p>
            <FormItem>
              {getFieldDecorator("otp", {
                rules: [
                  {
                    required: true,
                    message: "Four digit OTP",
                  },
                  {
                    len: 4,
                    type: "string",
                    message: "Please Enter 4 Digit OTP",
                  },
                  {
                    validator: this.validateMobileNo,
                  },
                ],
              })(
                <Input className="otp-input" placeholder="XXXX" maxLength={4} />
              )}
            </FormItem>
            <Button
              type="default"
              onClick={this.handleVerifyOTP}
              loading={isLoading}
              style={{
                justifySelf: "center",
                color: "#3c1a5b",
                borderColor: "#3c1a5b",
              }}>
              Verify OTP
            </Button>
            <div>
              {sendOTP ? (
                <div>{`You will receive OTP within ${seconds}s`}</div>
              ) : null}
              {resendOTP ? (
                <div
                  onClick={this.handleResendOTP}
                  style={{ cursor: "pointer" }}>
                  Didn't receive the code?{" "}
                  <strong style={{ color: "#3c1a5b" }}>Resend Code</strong>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </Form>
    );
  }
}

export default Form.create()(CAFPhoneAuth);
