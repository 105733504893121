import React from "react";
import { Helmet } from "react-helmet";
import { Result } from "antd";

const ErrorPage = () => {
  return (
    <>
      <Helmet>
        <title>Error Page - Online College Admission Form | Entrancezone</title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      <div style={{ gridColumn: "1/-1" }}>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </div>
    </>
  );
};

export default ErrorPage;
