import React, { Fragment, useEffect } from "react";
import { Select, Input, Divider, Form, DatePicker } from "antd";
import _startCase from "lodash/startCase";

const FormItem = Form.Item;
const { Option } = Select;
function Personaldetails({
  meta,
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  setFieldTouched,
  handleBlur,
  type
}) {
  // useEffect(() => {
  //   setFieldTouched("salutation", false);
  // }, []);
  const category = meta?.reservation_categories ?? [];
  const blood_groups = ["AB+", "AB-", "O+", "O-", "A+", "A-", "B+", "B-"];
  return (
    <Fragment>
      <h1
        className="heading-3"
        style={{ gridColumn: "1/-1", color: "#3c1a5b", marginBottom: "1rem" }}
      >
        {`${_startCase(type)} Details`}
      </h1>
      <FormItem
        label="SALUTATION"
        validateStatus={
          touched?.salutation && errors?.salutation
            ? "error"
            : values?.salutation
            ? "success"
            : null
        }
        help={touched?.salutation && errors?.salutation}
        // hasFeedback={Boolean(touched?.salutation && errors?.salutation)}
        required
      >
        <Select
          onChange={value => {
            setFieldValue(`salutation`, value, true);
            setFieldTouched(`salutation`, false);
          }}
          value={values?.salutation}
          size="large"
          name={`salutation`}
          placeholder="Select Salutation"
          // onBlur={handleBlur}
        >
          <Option value="Mr.">Mr.</Option>
          <Option value="Mrs.">Mrs.</Option>
          <Option value="Ms.">Miss</Option>
        </Select>
      </FormItem>
      <FormItem
        label="FIRST NAME"
        validateStatus={
          touched?.first_name && errors?.first_name
            ? "error"
            : values?.first_name
            ? "success"
            : null
        }
        help={touched?.first_name && errors?.first_name}
        required
        // hasFeedback={Boolean(touched?.first_name && errors?.first_name)}
      >
        <Input
          placeholder="First Name"
          value={values?.first_name}
          name={`first_name`}
          size="large"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="MIDDLE NAME"
        validateStatus={
          touched?.middle_name && errors?.middle_name
            ? "error"
            : values?.middle_name
            ? "success"
            : null
        }
        help={touched?.middle_name && errors?.middle_name}
        // hasFeedback={Boolean(touched?.middle_name && errors?.middle_name)}
      >
        <Input
          name={`middle_name`}
          placeholder="Middle Name"
          size="large"
          value={values?.middle_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="LAST NAME"
        validateStatus={
          touched?.last_name && errors?.last_name
            ? "error"
            : values?.last_name
            ? "success"
            : null
        }
        help={touched?.last_name && errors?.last_name}
        // hasFeedback={Boolean(touched?.last_name && errors?.last_name)}
      >
        <Input
          name={`last_name`}
          placeholder="Last Name"
          size="large"
          value={values?.last_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="EMAIL"
        validateStatus={
          touched?.email && errors?.email
            ? "error"
            : values?.email
            ? "success"
            : null
        }
        required
        help={touched?.email && errors?.email}
        // hasFeedback={Boolean(touched?.email && errors?.email)}
      >
        <Input
          name={`email`}
          placeholder="E-mail"
          size="large"
          value={values?.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="PHONE NUMBER"
        validateStatus={
          touched?.phone && errors?.phone
            ? "error"
            : values?.phone
            ? "success"
            : null
        }
        required
        help={touched?.phone && errors?.phone}
        // hasFeedback={Boolean(touched?.phone && errors?.phone)}
      >
        <Input
          name={`phone`}
          placeholder="Phone Number"
          size="large"
          maxLength={10}
          value={values?.phone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="ALTERNATE PHONE"
        validateStatus={
          touched?.alternate_phone && errors?.alternate_phone
            ? "error"
            : values?.alternate_phone
            ? "success"
            : null
        }
        help={touched?.alternate_phone && errors?.alternate_phone}
        // hasFeedback={Boolean(
        //   touched?.alternate_phone && errors?.alternate_phone
        // )}
      >
        <Input
          name={`alternate_phone`}
          size="large"
          maxLength={10}
          value={values?.alternate_phone}
          placeholder="Alternate Phone Number"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="GENDER"
        validateStatus={
          touched?.gender && errors?.gender
            ? "error"
            : values?.gender
            ? "success"
            : null
        }
        help={touched?.gender && errors?.gender}
        required
        // hasFeedback={Boolean(touched?.gender && errors?.gender)}
      >
        <Select
          size="large"
          name={`gender`}
          placeholder="Select Gender"
          value={values?.gender}
          // onBlur={handleBlur}
          onChange={value => {
            setFieldValue(`gender`, value, true);
            // setFieldTouched(`gender`, false);
          }}
        >
          <Option value="Male">Male</Option>
          <Option value="Female">Female</Option>
          <Option value="Do not wish to disclose">
            Do not wish to disclose
          </Option>
        </Select>
      </FormItem>
      <FormItem
        label="DOB"
        validateStatus={
          touched?.dob && errors?.dob ? "error" : values?.dob ? "success" : null
        }
        help={touched?.dob && errors?.dob}
        required
        // hasFeedback={Boolean(touched?.dob && errors?.dob)}
      >
        <DatePicker
          name="dob"
          placeholder="Select DOB"
          allowClear={false}
          value={values?.dob}
          format="DD-MM-YYYY"
          className="dob_datepicker"
          onBlur={handleBlur}
          onChange={value => {
            setFieldValue("dob", value, true);
            setFieldTouched("dob", false);
          }}
        />
      </FormItem>
      <FormItem
        label="BLOOD GROUP"
        validateStatus={
          touched?.blood_group && errors?.blood_group
            ? "error"
            : values?.blood_group
            ? "success"
            : null
        }
        help={touched?.blood_group && errors?.blood_group}
        // hasFeedback={Boolean(touched?.blood_group && errors?.blood_group)}
      >
        <Select
          onChange={value => {
            setFieldValue(`blood_group`, value, true);
            setFieldTouched(`blood_group`, false);
          }}
          value={values?.blood_group}
          size="large"
          name="blood_group"
          placeholder="Select Blood Group"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
        >
          {blood_groups.map(item => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          })}
        </Select>
      </FormItem>
      <FormItem
        label="CATEGORY"
        validateStatus={
          touched?.category_id && errors?.category_id
            ? "error"
            : values?.category_id
            ? "success"
            : null
        }
        required
        help={touched?.category_id && errors?.category_id}
        // hasFeedback={Boolean(touched?.category && errors?.category)}
      >
        <Select
          onChange={value => {
            setFieldValue(`category_id`, value, true);
            setFieldTouched(`category_id`, false);
          }}
          // onBlur={handleBlur}
          value={values?.category_id}
          size="large"
          name={`category_id`}
          placeholder="Select Category"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
        >
          {category.map((item, index) => {
            return (
              <Option value={item.id} key={item.value}>
                {item.value}
              </Option>
            );
          })}
        </Select>
      </FormItem>
      <FormItem
        label="NATIONALITY"
        validateStatus={
          touched?.nationality && errors?.nationality
            ? "error"
            : values?.nationality
            ? "success"
            : null
        }
        required
        help={touched?.nationality && errors?.nationality}
        // hasFeedback={Boolean(touched?.nationality && errors?.nationality)}
      >
        <Select
          onChange={value => {
            setFieldValue("nationality", value, true);
            setFieldTouched("nationality", false);
          }}
          value={values?.nationality}
          size="large"
          name="nationality"
          placeholder="Select Nationality"
          // onBlur={handleBlur}
        >
          <Option value="Indian">Indian</Option>
        </Select>
      </FormItem>
      <Divider style={{ gridColumn: "1/-1" }} />
    </Fragment>
  );
}

export default Personaldetails;
