import React, { Fragment } from "react";
import { Select, Input, Divider, Form } from "antd";
import _startCase from "lodash/startCase";

const FormItem = Form.Item;
const { Option } = Select;
function Parent({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  setFieldTouched,
  type,
  handleBlur,
}) {
  return (
    <Fragment>
      <Divider style={{ gridColumn: "1/-1" }} />
      <h1
        className="heading-3"
        style={{ gridColumn: "1/-1", color: "#3c1a5b", marginBottom: "1rem" }}
      >
        {`${_startCase(type)}'s Details`}{" "}
      </h1>
      <FormItem
        label="NAME"
        validateStatus={
          touched?.parent_detail?.[type]?.name &&
          errors?.parent_detail?.[type]?.name
            ? "error"
            : values?.parent_detail?.[type]?.name
            ? "success"
            : null
        }
        help={
          touched?.parent_detail?.[type]?.name &&
          errors?.parent_detail?.[type]?.name
        }
        required
        // hasFeedback={Boolean(
        //   touched?.parent_detail?.[type]?.name &&
        //     errors?.parent_detail?.[type]?.name
        // )}
      >
        <Input
          placeholder="Name"
          name={`parent_detail.${type}.name`}
          size="large"
          value={values?.parent_detail?.[type]?.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="EMAIL"
        validateStatus={
          touched?.parent_detail?.[type]?.email &&
          errors?.parent_detail?.[type]?.email
            ? "error"
            : values?.parent_detail?.[type]?.email
            ? "success"
            : null
        }
        help={
          touched?.parent_detail?.[type]?.email &&
          errors?.parent_detail?.[type]?.email
        }
        // hasFeedback={Boolean(
        //   touched?.parent_detail?.[type]?.email &&
        //     errors?.parent_detail?.[type]?.email
        // )}
      >
        <Input
          name={`parent_detail.${type}.email`}
          placeholder="E-mail"
          size="large"
          value={values?.parent_detail?.[type]?.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="PHONE NUMBER"
        validateStatus={
          touched?.parent_detail?.[type]?.phone &&
          errors?.parent_detail?.[type]?.phone
            ? "error"
            : values?.parent_detail?.[type]?.phone
            ? "success"
            : null
        }
        help={
          touched?.parent_detail?.[type]?.phone &&
          errors?.parent_detail?.[type]?.phone
        }
        // hasFeedback={Boolean(
        //   touched?.parent_detail?.[type]?.phone &&
        //     errors?.parent_detail?.[type]?.phone
        // )}
      >
        <Input
          name={`parent_detail.${type}.phone`}
          placeholder="Phone Number"
          size="large"
          maxLength={10}
          value={values?.parent_detail?.[type]?.phone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="EDUCATION"
        validateStatus={
          touched?.parent_detail?.[type]?.education &&
          errors?.parent_detail?.[type]?.education
            ? "error"
            : values?.parent_detail?.[type]?.education
            ? "success"
            : null
        }
        help={
          touched?.parent_detail?.[type]?.education &&
          errors?.parent_detail?.[type]?.education
        }
        // hasFeedback={Boolean(
        //   touched?.parent_detail?.[type]?.education &&
        //     errors?.parent_detail?.[type]?.education
        // )}
      >
        <Input
          name={`parent_detail.${type}.education`}
          size="large"
          value={values?.parent_detail?.[type]?.education}
          placeholder="Education"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
      <FormItem
        label="OCCUPATION"
        validateStatus={
          touched?.parent_detail?.[type]?.occupation &&
          errors?.parent_detail?.[type]?.occupation
            ? "error"
            : values?.parent_detail?.[type]?.occupation
            ? "success"
            : null
        }
        help={
          touched?.parent_detail?.[type]?.occupation &&
          errors?.parent_detail?.[type]?.occupation
        }
        // hasFeedback={Boolean(
        //   touched?.parent_detail?.[type]?.occupation &&
        //     errors?.parent_detail?.[type]?.occupation
        // )}
      >
        <Input
          name={`parent_detail.${type}.occupation`}
          size="large"
          value={values?.parent_detail?.[type]?.occupation}
          placeholder="Occupation"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormItem>
    </Fragment>
  );
}

export default Parent;
