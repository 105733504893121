import React, { useContext } from "react";
import { Context } from "utilities/Context";
import { Redirect } from "react-router-dom";

const HOF = (Component, guestGuard) =>
  function Wrapper(props) {
    const { isLogin } = useContext(Context);
    return guestGuard ? (
      isLogin() ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} />
      )
    ) : isLogin() ? (
      <Component {...props} />
    ) : (
      <Redirect to="/" />
    );
  };
export default HOF;
