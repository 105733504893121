import React, { Fragment } from "react";
import { Select, Input, Divider, Form } from "antd";
import _startCase from "lodash/startCase";
const FormItem = Form.Item;
const { Option } = Select;
function Address(props) {
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    type,
    setFieldTouched,
    handleBlur,
    meta,
  } = props || {};

  const states = meta && meta.states ? meta.states : [];
  const handleSameAddress = (value, type) => {
    setFieldValue(`permanent_address.${type}`, value, false);
    setFieldTouched(`permanent_address.${type}`, false);
  };

  return (
    <Fragment>
      <h4
        className="heading-4"
        style={{ gridColumn: "1/-1", color: "#3c1a5b" }}
      >
        {_startCase(type?.replace(/_/g, " ")) ?? "---"}
      </h4>
      <FormItem
        label="LINE 1"
        validateStatus={
          touched?.[type]?.line_1 && errors?.[type]?.line_1
            ? "error"
            : values?.[type]?.line_1
            ? "success"
            : null
        }
        required
        help={touched?.[type]?.line_1 && errors?.[type]?.line_1}
        // hasFeedback={Boolean(touched?.[type]?.line_1 && errors?.[type]?.line_1)}
      >
        <Input
          name={`${type}.line_1`}
          placeholder="Address Line 1"
          size="large"
          onChange={(e) => {
            handleChange(e);
            if (values?.both_address_same && type === "current_address") {
              handleSameAddress(e?.target?.value, "line_1");
            }
          }}
          value={values?.[type]?.line_1}
          onBlur={handleBlur}
          // style={{ gridColumn: "2/-1" }}
        />
      </FormItem>
      <FormItem
        label="LINE 2"
        validateStatus={
          touched?.[type]?.line_2 && errors?.[type]?.line_2
            ? "error"
            : values?.[type]?.line_2
            ? "success"
            : null
        }
        help={touched?.[type]?.line_2 && errors?.[type]?.line_2}
        // hasFeedback={Boolean(touched?.[type]?.line_2 && errors?.[type]?.line_2)}
      >
        <Input
          name={`${type}.line_2`}
          placeholder="Address Line 2"
          size="large"
          onChange={(e) => {
            handleChange(e);
            if (values?.both_address_same && type === "current_address") {
              handleSameAddress(e?.target?.value, "line_2");
            }
          }}
          value={values?.[type]?.line_2}
          onBlur={handleBlur}
          // style={{ gridColumn: "2/-1" }}
        />
      </FormItem>
      <FormItem
        label="CITY"
        validateStatus={
          touched?.[type]?.city && errors?.[type]?.city
            ? "error"
            : values?.[type]?.city
            ? "success"
            : null
        }
        required
        help={touched?.[type]?.city && errors?.[type]?.city}
        // hasFeedback={Boolean(touched?.[type]?.city && errors?.[type]?.city)}
      >
        <Input
          name={`${type}.city`}
          placeholder="Enter City"
          size="large"
          onChange={(e) => {
            handleChange(e);
            if (values?.both_address_same && type === "current_address") {
              handleSameAddress(e?.target?.value, "city");
            }
          }}
          onBlur={handleBlur}
          value={values?.[type]?.city}
        />
      </FormItem>
      <FormItem
        label="DISTRICT"
        validateStatus={
          touched?.[type]?.district && errors?.[type]?.district
            ? "error"
            : values?.[type]?.district
            ? "success"
            : null
        }
        required
        help={touched?.[type]?.district && errors?.[type]?.district}
        // hasFeedback={Boolean(
        //   touched?.[type]?.district && errors?.[type]?.district
        // )}
      >
        <Input
          name={`${type}.district`}
          placeholder="Enter District"
          size="large"
          onChange={(e) => {
            handleChange(e);
            if (values?.both_address_same && type === "current_address") {
              handleSameAddress(e?.target?.value, "district");
            }
          }}
          onBlur={handleBlur}
          value={values?.[type]?.district}
        />
      </FormItem>
      <FormItem
        label="PIN CODE"
        validateStatus={
          touched?.[type]?.pincode && errors?.[type]?.pincode
            ? "error"
            : values?.[type]?.pincode
            ? "success"
            : null
        }
        required
        help={touched?.[type]?.pincode && errors?.[type]?.pincode}
        // hasFeedback={Boolean(
        //   touched?.[type]?.pincode && errors?.[type]?.pincode
        // )}
      >
        <Input
          name={`${type}.pincode`}
          placeholder="Enter PinCode"
          size="large"
          maxLength={6}
          onChange={(e) => {
            handleChange(e);
            if (values?.both_address_same && type === "current_address") {
              handleSameAddress(e?.target?.value, "pincode");
            }
          }}
          onBlur={handleBlur}
          value={values?.[type]?.pincode}
        />
      </FormItem>{" "}
      <FormItem
        label="STATE"
        validateStatus={
          touched?.[type]?.state && errors?.[type]?.state
            ? "error"
            : values?.[type]?.state
            ? "success"
            : null
        }
        required
        help={touched?.[type]?.state && errors?.[type]?.state}
        // hasFeedback={Boolean(touched?.[type]?.state && errors?.[type]?.state)}
      >
        <Select
          onChange={(value) => {
            setFieldValue(`${type}.state`, value, true);
            setFieldTouched(`${type}.state`, false);
            if (values?.both_address_same && type === "current_address") {
              handleSameAddress(value, "state");
            }
          }}
          // onBlur={handleBlur}
          showSearch
          value={values?.[type]?.state}
          size="large"
          name={`${type}.state`}
          placeholder="Select State"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
        >
          {states.map((item, index) => {
            return (
              <Option value={item.value} key={item.value}>
                {item.value}
              </Option>
            );
          })}
        </Select>
      </FormItem>
    </Fragment>
  );
}

export default Address;
