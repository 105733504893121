import React, { useState } from "react";
import startCase from "lodash/startCase";
import { Select, Divider, Input, Icon, Form } from "antd";

const { Option } = Select;
const FormItem = Form.Item;
export default function CustomDropdown({
  options,
  graduationType,
  optionType,
  selectionMode,
  touched,
  errors,
  values,
  setFieldTouched,
  setFieldValue,
}) {
  const [items, setItems] = useState(options);
  const [name, setName] = useState("");

  const onNameChange = (value) => {
    setName(value);
  };

  const addItem = () => {
    if (!name) return;
    setItems([...items, name]);
    setName("");
  };
  return (
    <FormItem
      label={`${optionType}`.toUpperCase()}
      validateStatus={
        touched?.[graduationType]?.[optionType] &&
        errors?.[graduationType]?.[optionType]
          ? "error"
          : values?.[graduationType]?.[optionType]
          ? "success"
          : null
      }
      help={
        touched?.[graduationType]?.[optionType] &&
        errors?.[graduationType]?.[optionType]
      }
      required
    >
      <Select
        style={{ width: "100%" }}
        mode={selectionMode ?? "single"}
        notFoundContent={null}
        showSearch
        size="large"
        value={values?.[graduationType]?.[optionType]}
        placeholder={startCase(optionType)}
        onSearch={onNameChange}
        onChange={(value) => {
          setFieldValue(`${graduationType}.${optionType}`, value, true);
          setFieldTouched(`${graduationType}.${optionType}`, false);
        }}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div
              onMouseDown={(e) => e.preventDefault()}
              style={{
                display: "flex",
                flexWrap: "nowrap",
                padding: 8,
                zIndex: 10,
              }}
            >
              {/* <Input
                style={{ flex: "auto" }}
                value={name}
                onChange={onNameChange}
              /> */}
              <a
                style={{
                  flex: 1,
                  padding: "8px",
                  display: "block",
                  cursor: "pointer",
                }}
                onClick={addItem}
              >
                <Icon type="plus" /> Add item
              </a>
            </div>
          </div>
        )}
      >
        {items.map((item) => (
          <Option key={item}>{item}</Option>
        ))}
      </Select>
    </FormItem>
  );
}
