import React, { useEffect, useState } from "react";
import { Icon } from "antd";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { SpinnerComp } from "../../components/SpinnerComp";
import getUrl from "../../utilities/apiConstant";

export const DetailView = props => {
  const [data, setData] = useState({});

  const {
    match: {
      params: { id }
    }
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${getUrl("root_v2")}/caf/user/application-form/${id}`,
          { withCredentials: true }
        );
        setData(response?.data?.data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const logo_url = data?.logo?.display_url ?? undefined;
  const logo_alt = data?.logo?.alt_text ?? undefined;
  const title = data?.title ?? undefined;
  const city = data?.address?.city ?? undefined;
  const state = data?.address?.state ?? undefined;
  const course = data?.user_data?.course?.name ?? undefined;
  const requested_branches = data?.user_data?.course?.requested_branches ?? [];
  const branches = requested_branches?.length
    ? requested_branches.map(item => item.name)
    : null;
  const fees = data?.fees ?? undefined;
  const created_at = data?.created_at ?? undefined;
  // const status = data?.status ?? undefined;
  const application_id = id;

  if (isEmpty(data)) {
    return <SpinnerComp />;
  }
  return (
    <div className="detail-view">
      <div className="detail-header">
        <img src={logo_url} alt={logo_alt} className="clg-logo" />
        <div className="detail-content">
          <h3 className="clg-name">{title}</h3>
          <div className="address">
            <p className="location">
              <Icon
                type="environment"
                theme="filled"
                style={{ marginRight: "1rem" }}
              />
              {city}, {state}
            </p>
            {/* <Button type="primary" className="edit-btn">
              Edit
            </Button> */}
          </div>
        </div>
      </div>

      <div className="detail-block-content">
        <div>
          <div className="detail-block">
            <div className="item">
              <p className="label heading">Course Info</p>
            </div>
            <div className="item">
              <p className="label">Course Applied</p>
              <p className="value">{course}</p>
            </div>

            <div className="item">
              <p className="label">Branches Selected</p>
              <p className="value">
                {branches?.length ? branches.join(", ") : null}
              </p>
            </div>

            <div className="item">
              <p className="label">Fees</p>
              <p className="value">{fees}</p>
            </div>
          </div>

          <div className="detail-block" style={{ marginTop: "3rem" }}>
            <div className="item">
              <p className="label heading">Counsellor Info</p>
            </div>
            <div className="item">
              <p className="label">Name</p>
              <p className="value">Harshvardhan Soni</p>
            </div>

            <div className="item">
              <p className="label">Email</p>
              <p className="value">tech2@entrancezone.com</p>
            </div>

            <div className="item">
              <p className="label">Phone number</p>
              <p className="value">+91-9878037141</p>
            </div>
          </div>
        </div>

        <div>
          <div className="detail-block">
            <div className="item">
              <p className="label heading">Application Info</p>
            </div>
            <div className="item">
              <p className="label">Date Applied</p>
              <p className="value">{moment(created_at).format("DD-MM-YY")}</p>
            </div>

            <div className="item">
              <p className="label">Status</p>
              <p className="status">Complete</p>
            </div>

            <div className="item">
              <p className="label">Application ID</p>
              <p className="value">{`#${application_id}`}</p>
            </div>

            <div className="item">
              <p className="label">Last Activity</p>
              <p className="value">22-03-2020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
