import React, { useEffect, useState } from "react";
import { AppliedComp } from "../HomeSection/AppliedComp";
import { useHistory } from "react-router-dom";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import { Button } from "antd";
import { SpinnerComp } from "../../SpinnerComp";
import CounsellorInfo from "./CounsellorInfo";
import Inprogrss from "../../../img/inProgress.svg";
import completed_img from "../../../img/completed.svg";
import total_img from "../../../img/total.svg";
import emptyForm from "../../../img/homeOverview.svg";
import getUrl from "../../../utilities/apiConstant";

export const HomeOverview = () => {
  const history = useHistory();
  const [homeOverview, setHomeOverview] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${getUrl("root_v2")}/caf/user/forms?limit=20`,
          { withCredentials: true }
        );
        setHomeOverview(response?.data?.data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const total = homeOverview?.total ?? 0;
  const pending = homeOverview?.pending ?? 0;
  const completed = homeOverview?.completed ?? 0;
  const forms = homeOverview?.forms ?? [];

  if (isEmpty(homeOverview)) {
    return <SpinnerComp />;
  }

  return (
    <div className="home-overview">
      <CounsellorInfo />

      <div>
        <p className="section-heading">Overview</p>
        <div className="overview">
          <div className="block">
            <img src={Inprogrss} alt="" className="block-image" />
            <div className="block-content">
              <p className="label">In Progress</p>
              <p className="value">{pending}</p>
            </div>
          </div>

          <div className="block">
            <img src={completed_img} alt="" className="block-image" />
            <div className="block-content">
              <p className="label">Completed</p>
              <p className="value">{completed}</p>
            </div>
          </div>
          <div className="block">
            <img src={total_img} alt="" className="block-image" />
            <div className="block-content">
              <p className="label">Total</p>
              <p className="value">{total}</p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "4rem" }}>
        <p className="section-heading">Applied</p>
        {forms?.length ? (
          forms.map((item, index) => <AppliedComp key={index} data={item} />)
        ) : (
          <div className="empty-content">
            <img src={emptyForm} alt="" className="empty-content__img" />
            <div>
              <p className="text">This section seems empty right now</p>
              <Button
                onClick={() => history.push("/colleges")}
                type="primary"
                className="empty-content__btn"
              >
                Start Now
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
