// import React from "react";
// import { Link } from "react-router-dom";
// import { Menu, Icon } from "antd";

// const MobileData = [
//   { name: "home", text: "Home", route_path: "/profile" },
//   {
//     name: "user",
//     text: "Study",
//     route_path: "/profile/personal-information"
//   },
//   {
//     name: "select",
//     text: "Colleges",
//     route_path: "/profile/college-selection"
//   },
//   {
//     name: "read",
//     text: "Track",
//     route_path: "/profile/applications"
//   },
//   {
//     name: "cloud-upload",
//     text: "Upgrade",
//     route_path: "/profile/upgrade"
//   }
// ];
// const MobileMenu = ({ pathName }) => {
//   let activeKey;

//   const phoneMenuItems = MobileData.map((item, index) => {
//     if (pathName === item.route_path) activeKey = item.name;
//     if (pathName === "/profile/academic-information") activeKey = "user";
//     return (
//       <Menu.Item key={item.name}>
//         <Link to={item.route_path}>
//           <Icon type={item.name} style={{ marginRight: "0rem" }} />
//           <p>{item.text}</p>
//         </Link>
//       </Menu.Item>
//     );
//   });

//   return (
//     <Menu
//       className="mobile-side-menu"
//       theme="dark"
//       selectedKeys={activeKey}
//       mode="horizontal">
//       {phoneMenuItems}
//     </Menu>
//   );
// };

// export default MobileMenu;

import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";

const MobileData = [
  { name: "home", text: "Home", route_path: "/profile" },
  {
    name: "user",
    text: "Study",
    route_path: "/profile/personal-information",
  },
  {
    name: "select",
    text: "Colleges",
    route_path: "/profile/college-selection",
  },
  {
    name: "read",
    text: "Track",
    route_path: "/profile/applications",
  },
  // {
  //   name: "cloud-upload",
  //   text: "Upgrade",
  //   route_path: "/profile/upgrade"
  // }
];
const MobileMenu = ({ pathName }) => {
  let activeKey;

  const phoneMenuItems = MobileData.map((item, index) => {
    if (pathName === item.route_path) activeKey = item.name;
    if (pathName === "/profile/academic-information") activeKey = "user";
    return (
      <div key={item.name} className="wrapper">
        <Link to={item.route_path}>
          <Icon
            type={item.name}
            className="mobile-profile-icon"
            style={{ color: item.name === activeKey ? "white" : undefined }}
          />
          <p
            className="mobile-profile-text"
            style={{ color: item.name === activeKey ? "white" : undefined }}>
            {item.text}
          </p>
        </Link>
      </div>
    );
  });

  return (
    <div
      className="mobile-side-menu"
      theme="dark"
      selectedKeys={activeKey}
      mode="horizontal">
      {phoneMenuItems}
    </div>
  );
};

export default MobileMenu;
