import React from "react";
import { Icon } from "antd";

const MenuIcon = ({ menuIcon, fuc_handleMenuIcon }) => {
  return (
    <div>
      {menuIcon ? (
        <Icon
          type="menu"
          style={{ color: "white", fontSize: "2.5rem" }}
          onClick={() => fuc_handleMenuIcon(false)}
        />
      ) : (
        <Icon
          type="close"
          style={{ color: "white", fontSize: "2.5rem" }}
          onClick={() => fuc_handleMenuIcon(true)}
        />
      )}
    </div>
  );
};

export default MenuIcon;
