import React, { Component } from "react";
import SetPriority from "./SetPriority";
import { Row, Col } from "antd";
import { default as TouchBackend } from "react-dnd-touch-backend";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";
const update = require("immutability-helper");
const isMobile = "ontouchstart" in window;
class SetPriorityWrapper extends Component {
  state = {
    branches: this.props.cpa
      ? this.props.branches
      : this.props.branchPriority.length
      ? this.props.branchPriority
      : this.props.branches,
  };
  moveCard = (dragIndex, hoverIndex) => {
    const { branches } = this.state;
    const dragCard = branches[dragIndex];

    this.setState(
      update(this.state, {
        branches: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        },
      })
    );
  };

  componentDidUpdate(prevStates, prevProps) {
    if (prevStates?.branches !== this.state.branches) {
      this.props.handleDone(this.state.branches);
    }
    const { branches } = this.props;
    if (this.props.cpa && prevStates.branches !== branches) {
      if (branches) {
        this.setState({ branches: this.props.branches });
      }
    }
  }

  render() {
    return (
      <div className="set-priority-wrapper-main-div">
        <p className="heading-3 set-priority-branch-wrapper-div-para">
          Set your Specialization preferences
        </p>
        <p className="course-branch-wrapper-div-small">
          Select Specialization as per your choice of preference. Use the arrow
          tool to re-order them.
        </p>
        <Row gutter={[20, 20]}>
          {this.state.branches.map((data, index) => (
            <Col key={index} xs={24} md={24}>
              <SetPriority
                key={index}
                branch={data}
                index={index}
                id={index}
                moveCard={this.moveCard}
              />
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

let Export = null;
if (isMobile) {
  Export = DragDropContext(TouchBackend)(SetPriorityWrapper);
} else {
  Export = DragDropContext(HTML5Backend)(SetPriorityWrapper);
}
export default Export;
