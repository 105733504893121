import React from "react";
const TermsAndConditions = () => {
  return (
    <div>
      <h4 className="terms-condition-main-heading">
        Terms & Conditions for Common Application Form
      </h4>
      <p className="terms-condition-license-para">
        The licenses granted under this Agreement are also directed solely to
        students who want to use Our Site related service(s) for
        college/university admissions in connection with their use of Our Site
        .Note that “Site” in this Agreement is defined by Our Terms of Use
        displayed at caf.entrancezone.com. www.entrancezone.com , subfolders and
        subdomains or any pages at EntranceZone.com.
      </p>

      <h4 className="terms-conditions-main-paras">
        "Common Application Form Works" or "Works" means Our college/university
        first year admission application, transfer applications, teacher and/or
        counselor evaluations and recommendations, school and mid-year reports,
        instruction information and other related works of Common Application in
        whatever media, hard copy, electronic, CD-ROM or otherwise, including
        any updates or supplements provided thereto by Us for which You are
        granted a license pursuant to this Agreement.{" "}
      </h4>
      <h4 className="terms-conditions-main-paras">
        “Admissions Data” means any information about potential applicants
        gathered by the Common Application and made available to or obtained by
        a member institution either through direct emails or through access to
        the Common Application site-related services.{" "}
      </h4>
      <h4 className="terms-conditions-main-paras">
        “Communication” includes any communication from or signed by a member
        institution official to any prospective student or parent, or any school
        or school counselor.{" "}
      </h4>
      <p className="terms-condition-license-para" style={{ marginTop: "2rem" }}>
        Taking any step to access or use any EntranceZone service constitutes
        your assent to and acceptance of this agreement. Written or electronic
        approval is not a prerequisite to the validity or enforceability of this
        agreement and no solicitation of any such written or electronic approval
        by or on behalf of you will be construed as an inference to the
        contrary. If you used any common EntranceZone service you are assenting
        to the terms of this agreement. If you do not agree with all terms of
        this agreement, you must cease all use of our service.
      </p>
      <p className="terms-condition-license-para" style={{ marginTop: "2rem" }}>
        EntranceZone is hereby assigned all right, title and interest in said
        alteration, addition, modification, or derivative, including all of the
        associated intellectual property rights. You do not acquire any other
        rights, express or implied, other than those rights expressly granted
        under this Agreement.
      </p>
      <h4 className="terms-conditions-headings">Copyright license :</h4>
      <p className="terms-conditions-para">
        {" "}
        If You are a Student, Institutions or university that is also a member
        of the Common Application form, EntranceZone hereby grants you a
        limited, non-exclusive, non-transferable license (without the right to
        sublicense) to access CAF Student Profile dashboard solely for your own
        use or may be used by guardian or parents. Under no circumstances you
        are granted permission to sublicense the application data for any
        purpose to any third party, affiliate or vendor unless specifically
        authorized in writing by EntranceZone.
      </p>
      <h4 className="terms-conditions-headings">Ownership : </h4>
      <p className="terms-conditions-para">
        EntranceZone will retain all right, title and interest in and to the
        copyright and any other intellectual property rights in the Common
        Application Form, subject only to the limited licenses set forth in this
        Agreement. You do not acquire any other rights, express or implied, in
        the Works other than those rights expressly granted under this
        Agreement. The institution will not be able to see email id and phone
        number of students until students accept the institution provisional
        admission/scholarship offer.
      </p>
      <h4 className="terms-conditions-headings">
        {" "}
        Transmission of Admissions Data :
      </h4>
      <p className="terms-conditions-para">
        {" "}
        You may not transmit or provide Admissions Data to any non-member
        institution, third-party vendor, person, or organization.
      </p>
      <h4 className="terms-conditions-headings">
        {" "}
        Use of Admissions Data for Promotional Purposes :
      </h4>
      <p className="terms-conditions-para">
        {" "}
        EntranceZone prohibits use of Admissions Data to promote, market,
        support, or solicit use of any application data. We never share the data
        to any third party. Student may ask to delete his data from system, such
        request may take 30 days’ time to delete all the data from system. But
        EntranceZone doesn’t guarantee the data deletion from intuition side if
        it is passed during the process.
      </p>
      <h4 className="terms-conditions-headings">
        Disclaimer of Application Data :
      </h4>
      <p className="terms-conditions-para">
        {" "}
        The students must fill the CAF fields at their own and to be best to
        their knowledge. The students must do Self-Attestation of
        document/certificate copies to be uploaded on CAF system.
        University/Institutes partner will verify the eligibility and
        authentication of each student data and documents. If there’s any
        discrepancy/doubt in student data, Institution must ask for
        clarification through CAF and may provide conditional admission.
      </p>
      <h4 className="terms-conditions-headings">
        Provisional Admission through CAF :
      </h4>
      <p className="terms-conditions-para">
        All the admission granted through EntranceZone Common Application Form
        is consider as provisional admission. The final admission confirmation
        should be given after original document verification of students by
        Institution authority. EntranceZone does not guarantee any admission to
        any students. It’s a medium/platform that connects students and
        institutions through education portal. Any seat/branch dispute among or
        between student and Institutions is sole discretion of student and
        intuitions only. The final admission documents containing stream, course
        and branch must be checked by student and institutions at their own.
      </p>
      <h4 className="terms-conditions-headings">Login Credentials :</h4>
      <p className="terms-conditions-para">
        Students can created their profile at CAF with valid email id or phone
        number as this will be used in all further communication. The login
        credentials can be used to login, complete the profile, upload the
        required documents, fill the application form and check the status.
      </p>
      <h4 className="terms-conditions-headings">Time Line :</h4>
      <p className="terms-conditions-para">
        {" "}
        Institutions may take action and view applications on daily basis.
        Preferably Institution will take action and students will be informed
        about acknowledgement of application within 24 hour of application
        submission or as soon as possible. Whereas, Institutions will verify the
        data and take action on application within 48 hour of receiving the
        application. In case of any discrepancy institutions/students can take
        help of EntranceZone counsellors.
      </p>
      <h4 className="terms-conditions-headings">Role of EntranceZone :</h4>
      <p className="terms-conditions-para">
        EntranceZone will be contacting to students (Via various mediums) on
        behalf of Institutions. As partner institutions gives this right while
        signing the CPA agreement. Whereas student must update the documents and
        walk-in to the campus before admission deadlines. Admission will be
        based on first come first basis or as per university/institute
        guidelines for admission. Students are abide to follow these guidelines.
      </p>
      <h4 className="terms-conditions-headings">Status of Admission :</h4>
      <p className="terms-conditions-para">
        It’s a responsibility of all stock holders that they must update the
        final status of student’s admission. So that counsellor can update on
        system and students follow up mails can be paused and checked. Whereas,
        Status will also help both parties in verification of admission with
        institutes.
      </p>
      <h4 className="terms-conditions-headings">Termination :</h4>
      <p className="terms-conditions-para">
        {" "}
        This Agreement is effective until terminated. We may terminate this
        Agreement at any time upon your breach of any of the provisions hereof.
        Upon 30 days’ notice, with or without cause, any member school may
        terminate this Agreement with the EntranceZone, and the EntranceZone may
        terminate this Agreement with any member school. Upon termination of
        this Agreement, You will be restricted to use all the application data
        and our services.
      </p>
      <h4 className="terms-conditions-headings">Entire Agreement :</h4>
      <p className="terms-conditions-para">
        {" "}
        This Agreement constitutes the entire agreement between the parties with
        respect to its subject matter and this Agreement supersedes all prior or
        contemporaneous agreements, representations, or understandings, written
        or oral, explicit or implied, concerning the subject matter of this
        Agreement. This Agreement may not be modified or amended except in a
        writing signed by a duly authorized representative of each party; no
        other act, document, usage or custom will be deemed to amend or modify
        this Agreement. It is expressly agreed that the terms of this Agreement
        will supersede the terms of any of your purchase orders or other
        ordering documents.
      </p>
    </div>
  );
};
export default TermsAndConditions;
