import React, { useState } from "react";
import CAFAuthenticationModal from "../../../Login/CAFAuthenticationModal";
const CoursesOffered = ({ data, handleApply }) => {
  const { id, course, first_year_fee } = data;
  const [showModal, setShowModal] = useState(false);
  return (
    <div
      className="courses-offered-main-div"
      onClick={() => handleApply({ name: course, id })}
    >
      <div className="courses-offered-child-div-1">
        <div className="course-name-div">{course}</div>
        {first_year_fee ? (
          <div className="tuition-fee-div">Tuition Fee: {first_year_fee}</div>
        ) : null}
      </div>
      <div className="courses-offered-child-div-2">
        <button className="primary-btn antd-button-apply-now">Apply Now</button>
      </div>
      <CAFAuthenticationModal
        showAuthModal={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};
export default CoursesOffered;
