import React, { useState, useEffect } from "react";
import { Upload, message, Button, Icon, Divider } from "antd";
import getUrl from "utilities/apiConstant";
import imgurl from "img/college.png";

export const UploadDoc = (props) => {
  const {
    text,
    highlighter,
    form_id,
    education_level_id,
    document_type,
    data = [],
    url,
    is_divider,
    updateDocumentsCallback,
  } = props;
  const getExtraData = () => {
    if (!education_level_id) {
      return { document_type };
    } else {
      return {
        education_level_id,
        document_type,
      };
    }
  };

  const image_url = data?.find(
    (item) => item.education_level_id === education_level_id
  );

  const file_path = image_url?.file_path;
  const [imageUrl, setImageUrl] = useState(undefined);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (file_path) {
      setImageUrl(true);
      setFileList([
        {
          uid: "1",
          name: document_type,
          url: file_path,
          thumbUrl: file_path,
        },
      ]);
    }
  }, [data]);

  let uploadProps = {
    name: "file",
    listType: "picture",
    method: "post",
    action: `${getUrl("root_v2")}/caf/forms/${form_id}/document`,

    withCredentials: true,
    showUploadList: { showRemoveIcon: false },
    data: getExtraData,
    onChange(info) {
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(true);
          setFileList([
            {
              uid: "1",
              name: document_type,
              url: imageUrl,
              thumbUrl: imageUrl,
            },
          ]);
        });
        message.success(`${info.file.name} file uploaded successfully`);
        updateDocumentsCallback(education_level_id);
        // setImageUrl(true);
        // setFileList([
        //   {
        //     uid: "1",
        //     name: document_type,
        //   },
        // ]);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    },
    // onRemove(file) {
    //   message.success(`File removed successfully`);
    //   setImageUrl(undefined);
    // },
  };

  if (file_path) {
    uploadProps["fileList"] = [...fileList];
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  return (
    <div className="upload-docs">
      <div className="block">
        <p className="text">
          {text} <span className="highlighter">{highlighter}</span>
        </p>
        <Upload {...uploadProps} className="custom-upload">
          {!imageUrl ? (
            <Button className="upload-button">
              <Icon type="upload" /> Click to Upload
            </Button>
          ) : null}
        </Upload>
      </div>
      {is_divider ? <Divider /> : null}
    </div>
  );
};
