import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import _get from "lodash/get";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Checkbox } from "antd";
import { Context } from "../../utilities/Context";

import ProfileStepper from "./ProfileStepper";
import getUrl from "../../utilities/apiConstant";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs";
import PhoneModal from "../PhoneModal";
import CAFEmailModal from "../Login/CAFEmailModal";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  notification,
  Tabs,
  Icon,
} from "antd";

const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;
const PersonalInformation = (props) => {
  const history = useHistory();
  const { updateContextData, is_personal_detail_complete } = useContext(
    Context
  );

  const {
    form: { getFieldDecorator },
    profileProps: { data = {} },
  } = props;

  // const { data = {} } = personalData;
  const { meta = {}, detail = [] } = data;
  const personalData = detail.filter(
    (item) => item.type === "personal_information"
  );
  const value = personalData[0];
  const states = meta && meta.states ? meta.states : [];
  const genders = meta && meta.genders ? meta.genders : [];
  const reservation_categories =
    meta && meta.reservation_categories ? meta.reservation_categories : [];
  const { is_phone_verified, is_email_verified = true, email, phone } =
    (value && value.value) || {};

  const [isLoading, setIsLoading] = useState(false);
  const [currentStateName, setCurrentStateName] = useState();
  const [permanentStateName, setPermanentStateName] = useState();
  const [showPhoneModal, setPhoneModal] = useState(false);
  const [showEmailModal, setEmailModal] = useState(false);
  const [isPhoneVerified, setPhoneVerified] = useState(
    is_phone_verified || false
  );

  useEffect(() => {
    handleSetFields();
    setPhoneVerified(is_phone_verified);
  }, [value, is_personal_detail_complete]);

  const handleSaveAndContinue = async (e) => {
    e.preventDefault();
    const values = await props.form.validateFields();
    try {
      setIsLoading(true);
      const {
        first_name,
        last_name,
        phone,
        gender,
        dob,
        category,
        email,
        father_name,
        mother_name,
        current_line_one,
        current_line_two,
        current_district,
        current_location,
        current_pin_code,
        current_state,
        permanent_line_one,
        permanent_line_two,
        permanent_district,
        permanent_location,
        permanent_pin_code,
        permanent_state,
      } = values;
      const response = await axios.post(
        `${getUrl("root")}/caf/user/update`,
        {
          first_name,
          last_name,
          phone,
          gender_id: gender,
          dob,
          category_id: category,
          email,
          father_name,
          mother_name,
          current_address: {
            line_1: current_line_one,
            line_2: current_line_two,
            city: current_location,
            district: current_district,
            pincode: current_pin_code,
            state_id: current_state,
            state: currentStateName,
          },
          permanent_address: {
            line_1: permanent_line_one,
            line_2: permanent_line_two,
            city: permanent_location,
            district: permanent_district,
            pincode: permanent_pin_code,
            state_id: permanent_state,
            state: permanentStateName,
          },
        },
        { withCredentials: true }
      );
      setIsLoading(false);
      updateDataToContext();
      notification.success({
        message: _get(response, "data.message") || "Something went wrong",
      });
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: _get(error.response, "data.message") || "Something went wrong",
      });
    }
  };

  const updateDataToContext = async () => {
    try {
      const response = await axios(getUrl("user"), {
        withCredentials: true,
      });
      const data = response && response.data ? response.data.data : {};
      updateContextData(data);
    } catch (error) {}
  };

  const handleSetFields = () => {
    let dateOfBirth = _get(value, "value.dob") || undefined;
    dateOfBirth = dateOfBirth ? moment(dateOfBirth) : undefined;
    setCurrentStateName(_get(value, "value.current_address.state") || "");
    setPermanentStateName(_get(value, "value.permanent_address.state") || "");
    props.form.setFieldsValue({
      first_name: _get(value, "value.first_name") || undefined,
      father_name: _get(value, "value.father_name") || undefined,
      last_name: _get(value, "value.last_name") || undefined,
      mother_name: _get(value, "value.mother_name") || undefined,
      phone: _get(value, "value.phone") || undefined,
      email: _get(value, "value.email") || undefined,
      dob: dateOfBirth,
      gender: _get(value, "value.gender_id") || undefined,
      category: _get(value, "value.category_id") || undefined,
      current_line_one:
        _get(value, "value.current_address.line_1") || undefined,
      current_line_two:
        _get(value, "value.current_address.line_2") || undefined,
      current_location: _get(value, "value.current_address.city") || undefined,
      current_district:
        _get(value, "value.current_address.district") || undefined,
      current_pin_code:
        _get(value, "value.current_address.pincode") || undefined,
      current_state: _get(value, "value.current_address.state_id") || undefined,
      permanent_line_one:
        _get(value, "value.permanent_address.line_1") || undefined,
      permanent_line_two:
        _get(value, "value.permanent_address.line_2") || undefined,
      permanent_location:
        _get(value, "value.permanent_address.city") || undefined,
      permanent_district:
        _get(value, "value.permanent_address.district") || undefined,
      permanent_pin_code:
        _get(value, "value.permanent_address.pincode") || undefined,
      permanent_state:
        _get(value, "value.permanent_address.state_id") || undefined,
    });
  };

  const handlePermanentAddress = (e) => {
    const target = e.target.checked;
    if (target) {
      const {
        current_line_one,
        current_line_two,
        current_location,
        current_district,
        current_pin_code,
        current_state,
      } = props.form.getFieldsValue();

      setPermanentStateName(currentStateName || "");
      props.form.setFieldsValue({
        permanent_line_one: current_line_one,
        permanent_line_two: current_line_two,
        permanent_location: current_location,
        permanent_district: current_district,
        permanent_pin_code: current_pin_code,
        permanent_state: current_state,
      });
    } else {
      props.form.setFieldsValue({
        permanent_line_one: undefined,
        permanent_line_two: undefined,
        permanent_location: undefined,
        permanent_district: undefined,
        permanent_pin_code: undefined,
        permanent_state: undefined,
      });
    }
  };

  const validateMobileNo = (rule, value, callback) => {
    if (isNaN(value)) {
      callback("!! No. should only be in digits !!");
      return;
    }
    callback();
  };

  const handlePhoneVerify = () => {
    setPhoneModal(true);
  };

  const handleEmailModal = () => {
    setEmailModal(true);
  };

  const handlePhoneNumber = (value) => {
    props.form.setFieldsValue({
      phone: value || undefined,
    });
    setPhoneVerified(true);
  };

  const handleEmailId = (value) => {
    props.form.setFieldsValue({
      email: value || undefined,
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Personal Information - Online College Admission Form | Entrancezone
        </title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      <BreadCrumbs />
      {/* <ProfileStepper /> */}
      <p style={{ fontSize: "1.2rem", marginTop: "1rem" }}>
        * We will not, in any circumstances, share your personal information
        with other individuals or organizations without your permission.
      </p>
      <Tabs
        defaultActiveKey="personal"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <TabPane
          tab={
            <Link
              to="/profile/personal-information"
              style={{ padding: "1.2rem 0" }}>
              Personal Information
            </Link>
          }
          key="personal"
        />
        <TabPane
          tab={
            <Link
              to="/profile/academic-information"
              style={{ padding: "1.2rem 0" }}>
              Academic Information
            </Link>
          }
          key="academic"
        />
      </Tabs>
      {!is_email_verified ? (
        <div className="email-notification">
          <div>
            <Icon type="mail" style={{ color: "#faca45", fontSize: "2rem" }} />
          </div>
          <p className="email-notification__content">
            Please verify your email
          </p>
          <button
            className="email-notification__btn"
            onClick={() => setEmailModal(true)}>
            Take Action
          </button>
        </div>
      ) : null}
      <Form style={{ marginTop: "3rem", display: "grid" }}>
        <h2
          className="heading-2"
          style={{
            marginBottom: "2rem",
            color: "#3c1a5b",
            fontWeight: "500",
          }}>
          Personal Information
        </h2>
        <Row gutter={16}>
          <Col xs={12} md={8}>
            <FormItem label="First Name">
              {getFieldDecorator("first_name", {
                rules: [{ required: true, message: "First name Required" }],
              })(<Input placeholder="First Name" disabled={true} />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Last Name">
              {getFieldDecorator("last_name", {
                rules: [{ required: false, message: "Last name Required" }],
              })(<Input placeholder="Last Name" disabled={true} />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Email-ID">
              {getFieldDecorator("email", {
                rules: [
                  { required: true, type: "email", message: "Email Required" },
                ],
              })(
                <Input
                  placeholder="Enter your email"
                  disabled={is_email_verified}
                  onClick={!is_email_verified ? handleEmailModal : null}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Phone No">
              {getFieldDecorator("phone", {
                rules: [
                  {
                    required: false,
                    message: "Phone is required",
                  },
                  {
                    len: 10,
                    type: "string",
                    message: "Enter 10 digit number",
                  },
                  {
                    validator: validateMobileNo,
                  },
                ],
              })(
                <Input
                  addonBefore="+91 - "
                  placeholder="Phone No..."
                  maxLength={10}
                  disabled={isPhoneVerified}
                  onClick={!isPhoneVerified ? handlePhoneVerify : null}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Dob">
              {getFieldDecorator("dob", {
                rules: [
                  { required: true, message: "Enter your Date of Birth" },
                ],
              })(
                <DatePicker
                  placeholder="Date of Birth"
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Gender">
              {getFieldDecorator("gender", {
                rules: [{ required: true, message: "Select your gender" }],
              })(
                <Select
                  placeholder="Gender"
                  showSearch
                  optionFilterProp="children">
                  {genders.map((item, index) => {
                    return (
                      <Option value={item.id} key={`s${index}`}>
                        {item.value}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Category">
              {getFieldDecorator("category", {
                rules: [{ required: true, message: "Select your category" }],
              })(
                <Select
                  placeholder="Category"
                  showSearch
                  optionFilterProp="children">
                  {reservation_categories.map((item, index) => {
                    return (
                      <Option value={item.id} key={`s${index}`}>
                        {item.value}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Father's Name">
              {getFieldDecorator("father_name", {
                rules: [{ required: true, message: "Father name Required" }],
              })(<Input placeholder="Father Name" />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Mother's Name">
              {getFieldDecorator("mother_name", {
                rules: [{ required: true, message: "Mother name Required" }],
              })(<Input placeholder="Mother Name" />)}
            </FormItem>
          </Col>
        </Row>
        <h2
          className="heading-2"
          style={{
            marginBottom: "2rem",
            color: "#3c1a5b",
            marginTop: "2rem",
            fontWeight: "500",
          }}>
          Address
        </h2>
        <p style={{ color: "#faca45" }}>Current Address:</p>
        <Row gutter={16}>
          <Col xs={12} md={8}>
            <FormItem label="Address Line 1:">
              {getFieldDecorator("current_line_one", {
                rules: [{ required: true, message: "Address Line 1 Required" }],
              })(<Input placeholder="Line 1" />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Address Line 2:">
              {getFieldDecorator("current_line_two", {
                rules: [
                  { required: false, message: "Address Line 2 Required" },
                ],
              })(<Input placeholder="Line 2" />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="City/Town/Village">
              {getFieldDecorator("current_location", {
                rules: [{ required: true, message: "Location Required" }],
              })(<Input placeholder="City/Town/Village" />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="District">
              {getFieldDecorator("current_district", {
                rules: [{ required: true, message: "District Required" }],
              })(<Input placeholder="District" />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Pin Code">
              {getFieldDecorator("current_pin_code", {
                rules: [
                  {
                    required: true,
                    message: "Pin Code is required",
                  },
                  {
                    len: 6,
                    type: "string",
                    message: "Enter 6 digit number",
                  },
                  {
                    validator: validateMobileNo,
                  },
                ],
              })(<Input placeholder="Pin Code" maxLength={6} />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="State">
              {getFieldDecorator("current_state", {
                rules: [{ required: true, message: "State Required" }],
              })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="State">
                  {states.map((item, index) => {
                    return (
                      <Option
                        value={item.id}
                        key={`s${index}`}
                        onClick={() => setCurrentStateName(item.value)}>
                        {item.value}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <p style={{ color: "#faca45", marginBottom: "0/5rem" }}>
          Permanent Address:
        </p>
        <div>
          ( Same as Current Address
          <Checkbox
            onChange={handlePermanentAddress}
            style={{ marginLeft: "1rem" }}
          />{" "}
          )
        </div>
        <Row gutter={16}>
          <Col xs={12} md={8}>
            <FormItem label="Address Line 1:">
              {getFieldDecorator("permanent_line_one", {
                rules: [{ required: true, message: "Address Line 1 Required" }],
              })(<Input placeholder="Line 1" />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Address Line 2:">
              {getFieldDecorator("permanent_line_two", {
                rules: [
                  { required: false, message: "Address Line 2 Required" },
                ],
              })(<Input placeholder="Line 2" />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="City/Town/Village">
              {getFieldDecorator("permanent_location", {
                rules: [{ required: true, message: "Location Required" }],
              })(<Input placeholder="City/Town/Village" />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="District">
              {getFieldDecorator("permanent_district", {
                rules: [{ required: true, message: "District Required" }],
              })(<Input placeholder="District" />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="Pin Code">
              {getFieldDecorator("permanent_pin_code", {
                rules: [
                  {
                    required: true,
                    message: "Pin Code is required",
                  },
                  {
                    len: 6,
                    type: "string",
                    message: "Enter 6 digit number",
                  },
                  {
                    validator: validateMobileNo,
                  },
                ],
              })(<Input placeholder="Pin Code" maxLength={6} />)}
            </FormItem>
          </Col>
          <Col xs={12} md={8}>
            <FormItem label="State">
              {getFieldDecorator("permanent_state", {
                rules: [{ required: true, message: "State Required" }],
              })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="State">
                  {states.map((item, index) => {
                    return (
                      <Option
                        value={item.id}
                        key={`s${index}`}
                        onClick={() => setPermanentStateName(item.value)}>
                        {item.value}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Button
              type="secondary"
              onClick={() => history.push("/profile")}
              style={{
                textAlign: "left",
                padding: "0 4rem",
                fontWeight: "500",
              }}>
              Home
            </Button>
          </Col>
          <Col xs={12} md={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={handleSaveAndContinue}
                loading={isLoading}
                type="primary"
                // className="primary-btn"
                style={{
                  marginBottom: "5rem",
                  padding: "0 4rem",
                  fontWeight: "500",
                }}>
                Save
              </Button>
              {is_personal_detail_complete ? (
                <Button
                  type="secondary"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    history.push("/profile/academic-information");
                  }}
                  style={{
                    marginBottom: "5rem",
                    padding: "0 4rem",
                    fontWeight: "500",
                    marginLeft: "1rem",
                  }}>
                  Next
                </Button>
              ) : null}
            </div>
          </Col>
        </Row>
      </Form>

      <PhoneModal
        showPhoneModal={showPhoneModal}
        onCancel={() => setPhoneModal(false)}
        phone={phone}
        fun_setPhoneNumber={handlePhoneNumber}
      />
      <CAFEmailModal
        visible={showEmailModal}
        onCancel={() => setEmailModal(false)}
        email={email}
        fun_setEmailId={handleEmailId}
      />
    </>
  );
};

export default Form.create()(PersonalInformation);
