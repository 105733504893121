import React, { useState } from "react";
import img from "../../../img/comingsoon.png";
function ComingSoonCard() {
  const [showCard, setShowCard] = useState(true);
  if (!showCard) return <div></div>;
  return (
    <div className="coming-soon-main-div">
      <div className="coming-soon-main-div__child_1">
        <img
          className="coming-soon-main-div__child_1__img"
          alt="coming-soon"
          src={img}
        />
        <div className="coming-soon-main-div__child_1__item_1">
          <h2 className="coming-soon-main-div__child_1__h2">
            More Colleges Coming Soon !
          </h2>
          <p className="coming-soon-main-div__child_1__para">
            We are actively working to get you more colleges on the platform,
            stay updated.
          </p>
          <button
            className="primary-btn coming-soon-main-div__button_mobile"
            onClick={() => setShowCard(!showCard)}
          >
            Close
          </button>
        </div>
      </div>
      <button
        className="primary-btn coming-soon-main-div__button"
        onClick={() => setShowCard(!showCard)}
      >
        Close
      </button>
    </div>
  );
}

export default ComingSoonCard;
