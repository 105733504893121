import React, { useState, useEffect, useContext } from "react";
import { Radio, Input } from "antd";
import { Context } from "../../../utilities/Context";
const radioStyle = {
  display: "block",
  height: "25px",
  fontFamily: "Nunito Sans",
  fontSize: "14px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "0.23px",
  color: "#3c1a5b",
};
const SingleLayout = ({
  fieldName,
  fields = [],
  keyName,
  type,
  addUpdateFilterCallback,
  selectedFilters = [],
}) => {
  const [value, setValue] = useState(undefined);
  const { location } = window;
  //const [searchedData, setSearchedData] = useState(undefined);
  const [totalFields, setTotalFields] = useState([]);
  const [fieldsToShow, setFieldsToShow] = useState(totalFields);
  const { Search } = Input;
  const onChange = (e) => {
    const data = { ...e.target.value };
    setValue(e.target.value);
    addUpdateFilterCallback(type, keyName, [data]);
  };
  useEffect(() => {
    const totalField = fields?.length
      ? fields.map((field, id) => (
          <Radio
            key={`${id}-${Math.random()}`}
            style={radioStyle}
            value={field}
            checked={field.is_selected}
          >
            {`${field.name} (${field.count})`}
          </Radio>
        ))
      : [];
    setTotalFields(totalField);
    setFieldsToShow(totalField);
  }, [fields]);
  useEffect(() => {
    if (location.search) {
      const query = location.search.substr(1).split("&");
      const mappedQuery = query.map((data) => data.split("="));
      const addDataToFilter = fields.filter((data) =>
        mappedQuery.find(
          (item) => item[0] === keyName && parseInt(item[1]) === data.id
        )
      );
      if (addDataToFilter.length) {
        setValue(addDataToFilter);
        addUpdateFilterCallback(type, keyName, addDataToFilter);
      }
    }
  }, []);
  useEffect(() => {
    if (!value) return;
    let newValue = {};
    newValue = selectedFilters.filter((data) => data.key === keyName);
    if (!newValue.length && value.id) setValue(undefined);
  }, [selectedFilters]);
  const handleChange = (e) => {
    const searchedValue = e.target.value.toLowerCase();
    let result = [];
    if (searchedValue) {
      result = totalFields.filter((data) => {
        if (data.props.value.name.toLowerCase().includes(searchedValue)) {
          return data;
        }
      });
      if (!result.length) {
        result = <div style={{ margin: "1rem" }}>No result's Found</div>;
      }
      setFieldsToShow(result);
    } else {
      setFieldsToShow(totalFields);
    }
  };
  const isRadioSelected = selectedFilters.find((data) => data.key === keyName);
  const handleClearAll = () => {
    addUpdateFilterCallback(type, keyName, []);
  };
  return (
    <div className="parent-div">
      <div className="graduation-search-div">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="search-bar-alternative">{fieldName}</div>
          {isRadioSelected ? (
            <div
              className="clearall-div-single-multi-layout"
              onClick={handleClearAll}
            >
              Clear
            </div>
          ) : null}
        </div>
        {fields && fields.length > 10 ? (
          <Search
            placeholder={`Search ${fieldName}`}
            onChange={handleChange}
            style={{ paddingRight: "0.7rem" }}
          />
        ) : null}
      </div>
      <div className="graduation-parent-div">
        <Radio.Group onChange={onChange}>{fieldsToShow}</Radio.Group>
      </div>
    </div>
  );
};
export default SingleLayout;
