// import React, { useState } from "react";
// import { Tabs } from "antd";
// import CourseSelection from "./FormSteps/CourseSelection";
// import PersonalInfo from "./FormSteps/PersonalInfo";
// import Payment from "./FormSteps/Payment";
// import AcademicDetails from "./FormSteps/AcademicDetails";
// import { UploadDocWrapper } from "./FormSteps/UploadDocWrapper";

// const steps = [
//   {
//     key: "course",
//     screenType: CourseSelection,
//     title: "Select Course",
//   },
//   {
//     key: "personal",
//     screenType: PersonalInfo,
//     title: "Personal Information",
//   },
//   {
//     key: "academic",
//     screenType: AcademicDetails,
//     title: "Academic Details",
//   },
//   {
//     key: "documents",
//     screenType: UploadDocWrapper,
//     title: "Upload Documents",
//   },
//   {
//     key: "checkout",
//     screenType: Payment,
//     title: "Payment",
//   },
// ];
// export default function CPAFormWrapper({ data, match }) {
//   const { TabPane } = Tabs;
//   const [form_id, setFormId] = useState(data?.form_id);
//   const [current_screen, setCurrentScreen] = useState(
//     data?.current_section ?? "course"
//   );

//   const clg_logo = data?.college_data?.logo?.display_url;
//   const logo_alt_text = data?.college_data?.logo?.alt_text;
//   const clg_name = data?.college_data?.full_name;

//   const handleFormId = (screen, id) => {
//     if (id) {
//       setFormId(form_id);
//     }
//     setCurrentScreen(screen);
//   };

//   return (
//     <section className="cpa-container">
//       <div className="cpa-container__clg_heading">
//         <img src={clg_logo} alt={logo_alt_text} className="logo" />
//         <p className="clg-name">{clg_name}</p>
//       </div>
//       <Tabs
//         activeKey={current_screen}
//         tabPosition="top"
//         onTabClick={(key) => handleFormId(key)}>
//         {steps.map((item) => (
//           <TabPane tab={item.title} key={item.key}>
//             <item.screenType
//               data={data}
//               match={match}
//               form_id={form_id}
//               setFormIdCallback={handleFormId}
//             />
//           </TabPane>
//         ))}
//       </Tabs>
//     </section>
//   );
// }

import React, { useState, useEffect } from "react";
import { Steps } from "antd";
import axios from "axios";
import getUrl from "utilities/apiConstant";
import { enquireScreen } from "enquire-js";
import { UploadDocWrapper } from "./FormSteps/UploadDocWrapper";
// import { Context } from "utilities/Context";
import CourseSelection from "./FormSteps/CourseSelection";
import PersonalInfo from "./FormSteps/PersonalInfo";
import Payment from "./FormSteps/Payment";
import AcademicDetail from "./FormSteps/AcademicDetail";
import SuccessFullScreen from "./FormSteps/SuccessfulScreen";

const { Step } = Steps;
const steps = [
  {
    key: "course",
    screenType: CourseSelection,
    title: "Select Course",
  },
  {
    key: "personal",
    screenType: PersonalInfo,
    title: "Personal Information",
  },
  {
    key: "academic",
    screenType: AcademicDetail,
    title: "Academic Details",
  },
  {
    key: "documents",
    screenType: UploadDocWrapper,
    title: "Upload Documents",
  },
  {
    key: "checkout",
    screenType: Payment,
    title: "Payment",
  },
];

export default function CPAFormWrapper({ data, match }) {
  // const { current_screen, setCurrentScreen } = useContext(Context);
  const [isMobile, setIsMobile] = useState(false);
  const [form_id, setFormId] = useState(data?.form_id);
  const [current_screen, setCurrentScreen] = useState(
    data?.current_section
      ? steps.findIndex((item) => item.key === data?.current_section)
      : 0
  );
  const [academicPersonalData, setAcademicPersonalData] = useState({});
  const clg_logo = data?.college_data?.logo?.display_url;
  const logo_alt_text = data?.college_data?.logo?.alt_text;
  const clg_name = data?.college_data?.full_name;
  const CurrentSection = steps[current_screen]?.screenType;
  const completedScreenIndex = steps.findIndex(
    (item) => item.key === data?.current_section
  );

  useEffect(() => {
    enquireScreen((status) => setIsMobile(status));
  }, []);

  useEffect(() => {
    if (current_screen !== -1 && current_screen <= 2) getPersonalAcademicData();
  }, [current_screen]);

  const getPersonalAcademicData = async () => {
    try {
      const res = await axios.get(`${getUrl("profile")}`, {
        withCredentials: true,
      });
      setAcademicPersonalData(res?.data?.data?.meta ?? {});
    } catch (err) {}
  };

  const handleFormId = (screen, id) => {
    if (id) {
      setFormId(id);
      return;
    }
    steps.map((item, index) => {
      if (item.key === screen) {
        setCurrentScreen(index);
      }
    });
  };

  const handleStepperChange = (value) => {
    if (value <= completedScreenIndex) {
      setCurrentScreen(value);
    }
  };

  if (data?.current_section === "success")
    return <SuccessFullScreen form_id={form_id} />;

  return (
    <section className="cpa-container">
      <div className="cpa-container__clg_heading">
        <img src={clg_logo} alt={logo_alt_text} className="logo" />
        <p className="clg-name">{clg_name}</p>
      </div>

      <Steps
        className="custom-steps"
        current={current_screen}
        progressDot={true}
        direction={isMobile ? "vertical" : "horizontal"}
        onChange={handleStepperChange}
      >
        {steps.map((item, index) => {
          return (
            <Step
              key={index}
              title={item.key.charAt(0).toUpperCase() + item.key.slice(1)}
              disabled={index > completedScreenIndex}
            />
          );
        })}
      </Steps>

      <div>
        <CurrentSection
          data={data}
          match={match}
          form_id={form_id}
          setFormIdCallback={handleFormId}
          academicPersonalData={academicPersonalData}
        />
      </div>
    </section>
  );
}
