import React, { useContext } from "react";
import { Steps, notification } from "antd";
import { Context } from "../../utilities/Context";
import { useHistory } from "react-router-dom";
const { Step } = Steps;

const StreamScreen = ({ data, educationId, eduLevelName }) => {
  const { setCollegeData, educationLevel, setCurrent } = useContext(Context);
  const history = useHistory();
  const streamData = data.filter(item => item.id === educationId);
  const handleClick = item => {
    try {
      setCollegeData(item, "stream");
      history.push(
        `/colleges?stream_id=${item.id}&education_level_id=${educationLevel.id}`
      );
    } catch (e) {
      notification.error({ message: "Something went wrong !" });
    }
  };
  return (
    <div className="stream-level">
      <p className="heading-2 stream-level__heading">
        2. What Stream would you like to study at{" "}
        <span
          className="stream-level__heading--highlighter"
          onClick={() => setCurrent(2)}>
          {`${eduLevelName} level !`}
        </span>
      </p>
      <div className="stream-level__elements">
        {streamData[0].streams.map((item, index) => {
          return (
            <div
              className="stream-level__single-element"
              key={index}
              onClick={() => {
                handleClick(item);
                setCurrent(1);
              }}>
              <p className="stream-level__single-element--name">{item.name}</p>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginRight: "2rem"
        }}>
        <div className="stepper" style={{ width: "50%" }}>
          <Steps current={1} progressDot={true}>
            <Step
              title="Education Level"
              className="step-2"
              onClick={() => setCurrent(2)}
            />
            <Step title="Stream Level" />
            <Step title="Select Colleges" />
          </Steps>
        </div>
      </div>
    </div>
  );
};

export default StreamScreen;
