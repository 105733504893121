import React from "react";
import { Helmet } from "react-helmet";
// import HomeSection from "./HomeSection/HomeSection";
import { HomeOverview } from "./HomeSection/HomeOverview";

const HomeProfile = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard - Online College Admission Form | Entrancezone</title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      <div className="right-side-profile">
        {/* <HomeSection /> */}
        <HomeOverview />
      </div>
    </>
  );
};

export default HomeProfile;
