import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import _startCase from "lodash/startCase";
import { Form, Input, Select, Button, notification, Icon } from "antd";
import getUrl from "../../utilities/apiConstant.js";
import { Context } from "../../utilities/Context";
import success from "../../img/success.svg";
import HomeCarousel from "components/HomePage/HomeCarousel";

const { Option } = Select;

const CplForm = (props) => {
  const {
    form: { getFieldDecorator, setFieldsValue, validateFields },
    data,
    slug,
  } = props;
  const history = useHistory();
  const { userData } = useContext(Context);

  const [layout, setLayout] = useState(undefined);
  // const [collegeData, setCollegeData] = useState({});
  const [selectedResponse, setSelectedResponse] = useState({});
  const [fieldKey, setFieldKey] = useState(undefined);
  const [city, setCity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const getReqUrl = pathname?.replace("/apply", "");
  const college_admission_id =
    data?.college_data?.college_admission_id ?? undefined;
  const short_name = data?.college_data?.short_name ?? undefined;
  const title = data?.college_data?.title ?? undefined;
  const logo_url = data?.college_data?.logo?.display_url ?? undefined;
  const logo_alt = data?.college_data?.logo?.alt_text ?? undefined;
  const dynamic_fields = data?.college_data?.dynamic_fields ?? {};
  const state_city = data?.state_city ?? [];

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${getUrl("root")}/caf/colleges/admissions${getReqUrl}`
  //       );
  //       setCollegeData(response?.data?.data);
  //     } catch (error) {
  //       history.push("/");
  //     }
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    if (!isEmpty(data)) {
      handleUserInfo();
    }
  }, [data]);

  const handleUserInfo = () => {
    if (!isEmpty(userData)) {
      setFieldsValue({
        name: userData?.user?.name ?? undefined,
        email: userData?.user?.email ?? undefined,
        phone: userData?.user?.phone ?? undefined,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let values = await validateFields();
    try {
      setIsLoading(true);
      values["college_name"] = short_name;
      values["page_source"] = "caf";
      values["college_admission_id"] = college_admission_id;
      const { data = {} } = await axios.post(
        `${getUrl("colleges")}${slug}`,
        values
      );
      setIsLoading(false);
      setLayout({
        message: data?.message,
        colleges: data?.data ?? [],
      });
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: error?.response?.data?.message ?? "Something went wrong",
      });
    }
  };

  const handleSelectedItem = (key, value) => {
    const { depends_on = [] } = dynamic_fields.values[key] || {};

    const handleRemoveItem = (item) => {
      setFieldsValue({ [`${item}`]: undefined });
      delete selectedResponse[item];
      setSelectedResponse(selectedResponse);
      return;
    };

    dynamic_fields.fields.map((item) =>
      item === key || depends_on.includes(item) ? null : handleRemoveItem(item)
    );

    selectedResponse[key] = value;
    setSelectedResponse(selectedResponse);
  };

  const validateMobileNo = (rule, value, callback) => {
    if (isNaN(value)) {
      callback("!! No. should only be in digits !!");
      return;
    }
    callback();
  };

  const handleCities = (e) => {
    const cities = state_city.find((item) => item.state === e) || {};
    setCity(cities.city || []);
    setFieldsValue({ city: undefined });
  };

  const resultantData =
    fieldKey && !isEmpty(dynamic_fields)
      ? dynamic_fields.values[fieldKey].values
          .filter((dataItem) =>
            dynamic_fields.values[fieldKey].depends_on.every(
              (dependentItem) =>
                selectedResponse[dependentItem] === dataItem[dependentItem]
            )
          )
          .map((result) => result.name)
      : [];

  return !layout ? (
    <Form className="apply-form">
      <div className="top-heading">
        <p className="top-heading__reg">Register Now to Apply</p>
        <div className="clg-icon">
          <img src={logo_url} alt={logo_alt} className="logo" />
          <p className="top-heading__clg-name">{title}</p>
        </div>
      </div>
      <div>
        <p className="content-heading">Personal Details</p>
        <div className="border">
          <div className="border__highlighter" />
          <div className="border__border" />
        </div>
      </div>
      <div className="personal-form">
        <Form.Item label="Full Name">
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "Enter your name" }],
          })(<Input placeholder="Full Name" className="custom-input" />)}
        </Form.Item>
        <Form.Item label="Email Id">
          {getFieldDecorator("email", {
            rules: [
              { required: true, message: "Enter your email", type: "email" },
            ],
          })(<Input placeholder="Email Id" className="custom-input" />)}
        </Form.Item>
        <Form.Item label="Mobile Number">
          {getFieldDecorator("phone", {
            rules: [
              {
                required: true,
                message: "Phone is required",
              },
              {
                len: 10,
                type: "string",
                message: "Enter 10 digit number",
              },
              {
                validator: validateMobileNo,
              },
            ],
          })(
            <Input
              addonBefore="+91 - "
              placeholder="Mobile Number"
              maxLength={10}
              className="custom-input"
            />
          )}
        </Form.Item>
        <Form.Item label="State">
          {getFieldDecorator("state", {
            rules: [{ required: true, message: "Select State" }],
          })(
            <Select
              placeholder="Select State"
              className="custom-select"
              notFoundContent={null}
              onSelect={(e) => handleCities(e)}
              showSearch
              optionLabelProp="children"
            >
              {state_city.length
                ? state_city.map((item, index) => {
                    return (
                      <Option value={item.state} key={`state${index}`}>
                        {item.state}
                      </Option>
                    );
                  })
                : null}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="City">
          {getFieldDecorator("city", {
            rules: [{ required: true, message: "Select City" }],
          })(
            <Select
              placeholder="Select City"
              notFoundContent="Select State First"
              showSearch
              className="custom-select"
              optionLabelProp="children"
            >
              {city.length
                ? city.map((item, index) => {
                    return (
                      <Option value={item} key={`city${index}`}>
                        {item}
                      </Option>
                    );
                  })
                : null}
            </Select>
          )}
        </Form.Item>
      </div>
      <div>
        <p className="content-heading">Course Details</p>
        <div className="border">
          <div className="border__highlighter" />
          <div className="border__border" />
        </div>
      </div>
      <div className="personal-form">
        {!isEmpty(dynamic_fields) && dynamic_fields?.fields?.length
          ? dynamic_fields.fields.map((item, index) => {
              return (
                <Form.Item label={`${_startCase(item)}`} key={index}>
                  {getFieldDecorator(`${item}`, {
                    rules: [
                      {
                        required: true,
                        message: `Enter your ${_startCase(item)}`,
                      },
                    ],
                  })(
                    <Select
                      placeholder={`Select ${_startCase(item)}`}
                      className="custom-select"
                      onSelect={(e) => handleSelectedItem(item, e)}
                      onFocus={() => setFieldKey(item)}
                      dropdownClassName="custom-dropdown"
                      showSearch
                      optionLabelProp="children"
                      notFoundContent={null}
                    >
                      {resultantData.length
                        ? resultantData.map((item, index) => {
                            return (
                              <Option value={item} key={index}>
                                {item}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  )}
                </Form.Item>
              );
            })
          : null}
      </div>
      <Button loading={isLoading} type="primary" onClick={handleSubmit}>
        Proceed
      </Button>
    </Form>
  ) : (
    <div className="success-layout__wrapper">
      <div className="success-layout">
        <img src={success} alt="" className="success-layout__img" />
        <p>{layout.message}</p>
      </div>
      <div className="home-carousel-wrapper">
        <section className="application-form-heading-wrapper">
          <h2 className="heading-2 admission-form-heading">Similar Colleges</h2>
          <section
            className="application-form-heading-wrapper"
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/colleges")}
          >
            <p style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}>
              Explore More
            </p>
            <Icon
              type="right-circle"
              theme="filled"
              className="right-circle-icon"
            />
          </section>
        </section>
        <HomeCarousel data={layout?.colleges ?? []} admission_mode="cpl" />
      </div>
    </div>
  );
};

export default Form.create()(CplForm);
