import React, { useContext } from "react";
import { Divider } from "antd";
import counsellorInfo from "img/counsellor-info.svg";
import counsellorPhone from "img/counsellorPhone.svg";
import counsellorEmail from "img/counsellorEmail.svg";
import { Context } from "utilities/Context";

const CounsellorInfo = () => {
  const { userData = {} } = useContext(Context);

  if (!userData?.counsellor) return null;

  const { name, email, phone } = userData?.counsellor ?? {};
  return (
    <div style={{ marginBottom: "4rem" }}>
      <p className="section-heading">Counsellor Info</p>
      <div className="counsellor-info">
        <div className="counsellor_overview">
          <div className="block">
            <img src={counsellorInfo} alt="" className="block-image" />
            <div className="block-content">
              <p className="label">Counsellor Name</p>
              <p className="value">{name ?? " - "}</p>
            </div>
            <Divider type="vertical" />
          </div>
          <div className="block">
            <img src={counsellorPhone} alt="" className="block-image" />
            <div className="block-content">
              <p className="label">Contact Number</p>
              <p className="value">{phone ?? " - "}</p>
            </div>
            <Divider type="vertical" />
          </div>
          <div className="block">
            <img src={counsellorEmail} alt="" className="block-image" />
            <div className="block-content">
              <p className="label">Email Id</p>
              <p className="value">{email ?? " - "}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounsellorInfo;
