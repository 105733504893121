import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import debounce from "lodash.debounce";
import SingleLayout from "./Filter/SingleLayout";
import MultiLayout from "./Filter/MultiLayout";
import getUrl from "utilities/apiConstant";
import isEmpty from "lodash/isEmpty";
import { Spin, notification, Drawer } from "antd";
import axios from "axios";
import filter from "img/filter.png";
import CollegeCard from "./Card/CollegeCard";
import { Context } from "utilities/Context";
import { BreadcrumbsCollegeFilter } from "../BreadCrumbs";
import ComingSoonCard from "./Card/ComingSoonCard";

const FilterMainLayout = ({ location }) => {
  const { setCollegeData } = useContext(Context);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);
  const [collegesData, setCollegesData] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [finalSelectionData, setFinalSelectionData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [total_count, setTotalCount] = useState(0);
  const [isRefreshed, setIsRefreshed] = useState(true);
  const [offset, setOffset] = useState(15);

  let totalFilters = [];
  useEffect(() => {
    if (!isEmpty(window.history.state) || !window.history.state) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        window.location.search;
      window.history.pushState({ path: newurl }, "", newurl);
    }
    setIsRefreshed(false);
  }, []);
  useEffect(() => {
    if (!isRefreshed) {
      const data = selectedFilters.map(
        (item, index) => `${item.key}=${item.id}`
      );
      const query = selectedFilters.length
        ? "?" + data.toString().replace(/,/g, "&")
        : data.toString().replace(/,/g, "&");
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        query;
      window.history.pushState({ path: newurl }, "", newurl);
    }
    handleApiCall();
  }, [selectedFilters]);
  const addUpdateFilter = (type, key, data) => {
    const filterWithoutKey = selectedFilters.filter((item) => item.key !== key);
    const dataMapped = data.map((item) => ({
      type,
      key,
      id: item.id,
      name: item.name,
    }));
    filterWithoutKey.push(...dataMapped);
    totalFilters.push(...filterWithoutKey);
    if (!selectedFilters.length) {
      setSelectedFilters(totalFilters);
      return;
    }
    setSelectedFilters(filterWithoutKey);
  };
  const handleApiCall = async () => {
    try {
      let queries = {};
      if (
        !isEmpty(window.history.state) &&
        window.history.state.path &&
        window.history.state.path.split("?")[1]
      ) {
        const queryGenerator = window.history.state.path
          .split("?")[1]
          .split("&")
          .map((data) => data.split("="))
          .map((data) => ({ selectedValue: data[0], id: data[1] }));
        queryGenerator.map((data) => {
          if (queries[data.selectedValue]) {
            if (typeof queries[data.selectedValue] === "object") {
              queries[data.selectedValue].push(data.id);
            } else {
              const copyData = queries[data.selectedValue];
              queries[data.selectedValue] = [copyData];
              queries[data.selectedValue].push(data.id);
            }
          } else queries[data.selectedValue] = data.id;
        });
      }
      queries.limit = 15;
      queries.offset = 0;
      setCardLoading(true);
      const educationResult = await axios.get(
        `${getUrl("collegeFilters")}/filters`,
        { params: queries },
        {
          withCredentials: true,
        }
      );
      setEducationData(educationResult.data.data);
      const response = await axios.get(
        `${getUrl("collegeFilters")}`,
        { params: queries },
        { withCredentials: true }
      );
      setTotalCount(response.data.totalCount);
      setCollegesData(response.data.data);
      setIsLoading(false);
      setCardLoading(false);
      if (offset > 15) setOffset(15);
    } catch (e) {
      notification.error({ message: "Something went wrong !" });
      setIsLoading(false);
      setCardLoading(false);
    }
  };
  /**
   *
   * @param {*} type -> single, multi
   * @param {*} key -> stream_id, course_id
   * @param {*} data (optional)-> object -> {id: 1, name: "Engineering", is_selected:false}
   */
  const deleteFilter = (data) => {
    const { key, id } = data;
    //Case 1: Delete individual item for an element for ex: delete one city
    //Case 2: Delete all filters item of an element for ex: clear all selected cities
    let filteredResult = [];
    if (!data) {
      //Delete all the rows from selectedFilters that contains the specified key.
      filteredResult = selectedFilters.filter((item) => item.key !== key);
    } else {
      //If a user tries deleting one of the selected key of the element.
      //Data will always hold object to be deleted.
      if (id) {
        filteredResult = selectedFilters.filter(
          (item) => !(item.key === key && item.id === id)
        );
        if (key === "education_level_id") setCollegeData({}, "education");
        if (key === "stream_id") setCollegeData({}, "stream");
      }
    }
    setSelectedFilters(filteredResult);
  };
  const RemoveFilters = (keyName) => {
    if (keyName) {
      const filteredItems = selectedFilters.filter(
        (data) => data.key !== keyName
      );
      setSelectedFilters(filteredItems);
    }
  };
  useEffect(() => {
    const dataToBeSelected = educationData.map((data, id) =>
      data.type === "single" ? (
        <SingleLayout
          fieldName={data.name}
          type={data.type}
          fields={data.data}
          key={id}
          addUpdateFilterCallback={addUpdateFilter}
          keyName={data.key}
          selectedFilters={selectedFilters}
        />
      ) : (
        <MultiLayout
          fieldName={data.name}
          type={data.type}
          fields={data.data}
          key={id}
          addUpdateFilterCallback={addUpdateFilter}
          keyName={data.key}
          selectedFilters={selectedFilters}
          RemoveFiltersCallback={RemoveFilters}
        />
      )
    );
    setFinalSelectionData(dataToBeSelected);
  }, [educationData]);
  // const handleUpgradeNow = () => {
  //   alert("Upgrade Now Clicked");
  // };
  const handleDrawerClose = () => {
    setIsVisible(!isVisible);
  };
  const handlePagination = async () => {
    try {
      let queries = {};
      if (
        !isEmpty(window.history.state) &&
        window.history.state.path &&
        window.history.state.path.split("?")[1]
      ) {
        const queryGenerator = window.history.state.path
          .split("?")[1]
          .split("&")
          .map((data) => data.split("="))
          .map((data) => ({ selectedValue: data[0], id: data[1] }));
        queryGenerator.map((data) => {
          if (queries[data.selectedValue]) {
            if (typeof queries[data.selectedValue] === "object") {
              queries[data.selectedValue].push(data.id);
            } else {
              const copyData = queries[data.selectedValue];
              queries[data.selectedValue] = [copyData];
              queries[data.selectedValue].push(data.id);
            }
          } else queries[data.selectedValue] = data.id;
        });
      }
      queries.limit = 15;
      queries.offset = offset;
      setCardLoading(true);
      const response = await axios.get(
        `${getUrl("collegeFilters")}`,
        { params: queries },
        { withCredentials: true }
      );
      setTotalCount(response?.data?.totalCount ?? 0);
      setOffset((offset) => offset + 15);
      const filteredCollegeData = [
        ...collegesData,
        ...(response?.data?.data ?? []),
      ];
      setCollegesData(filteredCollegeData);
      setIsLoading(false);
      setCardLoading(false);
    } catch (e) {
      notification.error({ message: "Something went wrong !" });
    }
  };
  return (
    <>
      <Helmet>
        <title>Colleges - Online College Admission Form | Entrancezone</title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      {isLoading ? (
        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            alignItems: "center",
            justifySelf: "center",
            height: "100vh",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div className="filter-container">
          <BreadcrumbsCollegeFilter />
          {/* <div className="div-nav">
          
            <p className="div-nav-para">
              You can only select upto 1 college, upgrade now to apply to more
              colleges!
            </p>
            <div className="upgrade-now">
              <p className="upgrade-now-para" onClick={handleUpgradeNow}>
                Upgrade Now
              </p>
            </div>
          </div> */}
          <div className="filter-card-parent-div">
            <div className="filter-pages-div">
              <div className="filter-clearall-div">
                <div className="filter-div">FILTERS</div>
                {selectedFilters && selectedFilters.length ? (
                  <div
                    className="clearall-div"
                    onClick={() => {
                      setSelectedFilters([]);
                      totalFilters = [];
                      setCollegeData({}, "education");
                      setCollegeData({}, "stream");
                    }}
                  >
                    CLEAR ALL
                  </div>
                ) : null}
              </div>
              <div className="desktop-layout">
                <div className="margin-div"></div>
                {finalSelectionData}
              </div>
              <div className="margin-div-mobile">
                {" "}
                <Drawer
                  className="main-layout-drawer"
                  title="Filters"
                  placement="left"
                  closable={true}
                  onClose={handleDrawerClose}
                  visible={isVisible}
                >
                  {finalSelectionData}
                </Drawer>
              </div>
            </div>
            <div className="searchcollege-card-parent-div">
              {/* <SearchCollege collegesData={collegesData} /> */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="select-colleges-para">
                  {`Colleges (${total_count})`}
                </p>
                <div
                  className="filter-btn-div"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <img
                    alt="hamburger"
                    src={filter}
                    className="hamburger-menu"
                  />
                  Filters
                </div>
              </div>
              <CollegeCard
                collegesData={collegesData}
                selectedFilters={selectedFilters}
                deleteFilterCallback={deleteFilter}
                setSelectedFilters={setSelectedFilters}
                totalFilters={totalFilters}
                totalCount={total_count}
                cardLoading={cardLoading}
                handlePagination={handlePagination}
              />

              {collegesData?.length % 15 ? <ComingSoonCard /> : null}
              {/* {total_count > 15 ? (
                <Pagination
                  className="pagination-main-layout"
                  defaultCurrent={currentPage}
                  defaultPageSize={15}
                  total={total_count}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  showQuickJumper
                  onChange={handlePagination}
                />
              ) : null} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default FilterMainLayout;
