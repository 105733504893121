// import React, { useState, useContext } from "react";
// import { useHistory } from "react-router-dom";
// import axios from "axios";
// import CAFAuthenticationModal from "../Login/CAFAuthenticationModal";
// import { Context } from "../../utilities/Context";
// import getUrl from "../../utilities/apiConstant";
// import { Menu, notification,Drawer } from "antd";

// const MobileNav = ({ fuc_handleMenuIcon }) => {
//   const {
//     isLogin,
//     userData = {},
//     updateContextData,
//     selectedCollegesData = [],
//     form_left
//     // profile_image_url
//   } = useContext(Context);
//   const [showModal, setShowModal] = useState(false);
//   // const [loading, setLoading] = useState(false);
//   // const [imageUrl, setImageUrl] = useState(profile_image_url);
//   const history = useHistory();

//   const { user = {} } = userData;

//   const text = selectedCollegesData.length
//     ? "Shortlisted Colleges"
//     : "Explore Colleges";

//   const upgradeText = form_left ? `Credits: ${form_left}` : "Upgrade";

//   // const handleChange = info => {
//   //   if (info.file.status === "uploading") {
//   //     setLoading(true);
//   //     return;
//   //   }
//   //   if (info.file.status === "done") {
//   //     // Get this url from response in real world.
//   //     getBase64(info.file.originFileObj, image => {
//   //       setImageUrl(image);
//   //       setLoading(false);
//   //     });
//   //   }
//   // };

//   // const getBase64 = (img, callback) => {
//   //   const reader = new FileReader();
//   //   reader.addEventListener("load", () => callback(reader.result));
//   //   reader.readAsDataURL(img);
//   // };

//   // const beforeUpload = file => {
//   //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   //   if (!isJpgOrPng) {
//   //     message.error("You can only upload JPG/PNG file!");
//   //   }
//   //   const isLt2M = file.size / 1024 / 1024 < 2;
//   //   if (!isLt2M) {
//   //     message.error("Image must smaller than 2MB!");
//   //   }
//   //   return isJpgOrPng && isLt2M;
//   // };

//   const handleSignInOrUp = () => {
//     if (!isLogin()) return setShowModal(true);
//     history.push("/profile");
//     fuc_handleMenuIcon(true);
//   };

//   const handleLogout = async () => {
//     const pathName = window.location.pathname;
//     try {
//       await axios.get(`${getUrl("root")}/user/logout`, {
//         withCredentials: true
//       });
//       updateContextData({});
//       fuc_handleMenuIcon(true);
//       if (pathName !== "/" || pathName !== "/colleges")
//         return history.push("/");
//     } catch (error) {
//       notification.error({
//         message: "Something went wrong"
//       });
//     }
//   };

//   const handleShortlistAndExplore = () => {
//     if (selectedCollegesData.length) {
//       history.push("/profile/college-selection");
//     } else {
//       history.push("/colleges");
//     }
//   };

//   const handleUpgrade = () => {
//     if (form_left) return history.push("/profile");
//     return history.push("/profile/upgrade");
//   };

//   // const uploadButton = (
//   //   <div>
//   //     {loading ? (
//   //       <Icon style={{color:"#3c1a5b"}} type="loading" style={{ color: "#ffffff", fontSize: "2rem" }} />
//   //     ) : (
//   //       <Avatar size={50}>
//   //         {userData.user && userData.user.name
//   //           ? userData.user.name.charAt(0).toUpperCase()
//   //           : "EZ"}
//   //       </Avatar>
//   //     )}
//   //   </div>
//   // );

//   return (
//     <>
//       {isLogin() ? (
//         <>
//           <Menu className="mobile-nav-menu background-image">
//             <Menu.Item key="1" onClick={handleSignInOrUp}>
//               {user.name}
//             </Menu.Item>
//             <Menu.Item
//               key="2"
//               onClick={() => {
//                 handleUpgrade();
//                 fuc_handleMenuIcon(true);
//               }}>
//               {upgradeText}
//             </Menu.Item>
//             <Menu.Item
//               key="3"
//               onClick={() => {
//                 handleShortlistAndExplore();
//                 fuc_handleMenuIcon(true);
//               }}>
//               {text}
//             </Menu.Item>
//             <Menu.Item key="4" onClick={handleLogout}>
//               Logout
//             </Menu.Item>
//           </Menu>
//         </>
//       ) : (
//         <Menu className="mobile-nav-menu background-image">
//           <Menu.Item key="1" onClick={handleSignInOrUp}>
//             SignIn/SignUp
//           </Menu.Item>
//         </Menu>
//       )}

//       <CAFAuthenticationModal
//         showAuthModal={showModal}
//         onClose={() => setShowModal(false)}
//       />
//     </>
//   );
// };

// export default MobileNav;

// //user upload file comment out

// /* <div className="" style={{ display: "flex", alignItems: "center" }}>
//             <Upload
//               name="file"
//               listType="picture-card"
//               className="avatar-uploader"
//               showUploadList={false}
//               action={`${getUrl("uploads")}/profile`}
//               method="post"
//               withCredentials={true}
//               beforeUpload={beforeUpload}
//               onChange={handleChange}
//               // style={{
//               //   width: "20px",
//               //   height: "20px"
//               // }}
//             >
//               {imageUrl ? (
//                 <img
//                   src={imageUrl}
//                   alt="avatar"
//                   style={{
//                     width: "11rem",
//                     height: "95px",
//                     borderRadius: "50%"
//                   }}
//                 />
//               ) : (
//                 uploadButton
//               )}
//             </Upload>
//             <div
//               style={{
//                 color: "white",
//                 fontSize: "2rem",
//                 fontWeight: "600",
//                 fontFamily: "Montserrat",
//                 marginLeft: "1rem"
//               }}
//             >
//               {userData.user
//                 ? userData.user.name.charAt(0).toUpperCase() +
//                   userData.user.name.slice(1)
//                 : null}
//             </div>
//           </div>
//         */

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import CAFAuthenticationModal from "../Login/CAFAuthenticationModal";
import { Context } from "../../utilities/Context";
import getUrl from "../../utilities/apiConstant";
import {
  Menu,
  notification,
  Drawer,
  Avatar,
  Upload,
  message,
  Icon,
} from "antd";

const { SubMenu } = Menu;

const MobileNav = ({ fuc_handleMenuIcon, showDrawer }) => {
  const {
    isLogin,
    userData = {},
    updateContextData,
    selectedCollegesData = [],
    form_left,
    profile_image_url,
  } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(profile_image_url);
  const history = useHistory();

  useEffect(() => {
    setImageUrl(profile_image_url);
  }, [profile_image_url]);

  const text = selectedCollegesData.length
    ? "Shortlisted Colleges"
    : "Explore Colleges";

  // const upgradeText = form_left ? `Credits: ${form_left}` : "Upgrade";

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (image) => {
        setImageUrl(image);
        setLoading(false);
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleSignInOrUp = () => {
    if (!isLogin()) return setShowModal(true);
    history.push("/profile");
    fuc_handleMenuIcon(true);
  };

  const handleLogout = async () => {
    const pathName = window.location.pathname;
    try {
      await axios.get(`${getUrl("root")}/user/logout`, {
        withCredentials: true,
      });
      updateContextData({});
      fuc_handleMenuIcon(true);
      if (pathName !== "/" || pathName !== "/colleges")
        return history.push("/");
    } catch (error) {
      notification.error({
        message: "Something went wrong",
      });
    }
  };

  const handleShortlistAndExplore = () => {
    if (selectedCollegesData.length) {
      history.push("/profile/college-selection");
    } else {
      history.push("/colleges");
    }
  };

  // const handleUpgrade = () => {
  //   if (form_left) return history.push("/profile");
  //   return history.push("/profile/upgrade");
  // };

  // const uploadButton = (
  //   <div>
  //     {loading ? (
  //       <Icon style={{color:"#3c1a5b"}} type="loading" style={{ color: "#ffffff", fontSize: "2rem" }} />
  //     ) : (
  //       <Avatar size={70}>
  //         {userData.user && userData.user.name
  //           ? userData.user.name.charAt(0).toUpperCase()
  //           : "EZ"}
  //       </Avatar>
  //     )}
  //   </div>
  // );

  const uploadButton = (
    <div>
      <Avatar size={70}>
        <Icon
          style={{ color: "#3c1a5b" }}
          type={loading ? "loading" : "plus"}
        />
        <div className="ant-upload-text">Upload</div>
      </Avatar>
    </div>
  );

  return (
    <Drawer
      className="mobile-drawer"
      visible={!showDrawer}
      onClose={fuc_handleMenuIcon}
      placement="left"
    >
      {isLogin() ? (
        <>
          <div
            className=""
            // style={{ display: "flex", alignItems: "center" }}
          >
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${getUrl("uploads")}/profile`}
              method="post"
              withCredentials={true}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              // style={{
              //   width: "20px",
              //   height: "20px"
              // }}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: "11rem",
                    height: "95px",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            <div className="user-name">
              Welcome,{" "}
              {userData.user
                ? userData.user.name.charAt(0).toUpperCase() +
                  userData.user.name.slice(1)
                : null}
            </div>
          </div>
          <Menu className="mobile-nav-menu" mode="inline">
            <Menu.Item key="1" onClick={handleSignInOrUp}>
              <Icon style={{ color: "#3c1a5b" }} type="profile" />
              Dashboard
            </Menu.Item>
            {/* <Menu.Item
              key="2"
              onClick={() => {
                handleUpgrade();
                fuc_handleMenuIcon(true);
              }}>
              <Icon
                style={{ color: "#3c1a5b" }}
                type={form_left ? "credit-card" : "cloud-upload"}
              />
              {upgradeText}
            </Menu.Item> */}
            <Menu.Item
              key="3"
              onClick={() => {
                history.push("/colleges");
                fuc_handleMenuIcon(true);
              }}
            >
              <Icon style={{ color: "#3c1a5b" }} type="select" />
              Colleges
            </Menu.Item>
            <Menu.Item
              key="4"
              onClick={() => {
                handleShortlistAndExplore();
                fuc_handleMenuIcon(true);
              }}
            >
              <Icon style={{ color: "#3c1a5b" }} type="book" />
              {text}
            </Menu.Item>
            {/* <Menu.Item
              key="5"
              onClick={() => {
                history.push("/pricing");
                fuc_handleMenuIcon(true);
              }}>
              <Icon style={{ color: "#3c1a5b" }} type="dollar" />
              Pricing
            </Menu.Item> */}
            <Menu.Item key="6" onClick={handleLogout}>
              <Icon style={{ color: "#3c1a5b" }} type="logout" />
              Logout
            </Menu.Item>
            <SubMenu
              key="sub1"
              title={
                <>
                  <Icon style={{ color: "#3c1a5b" }} type="info-circle" /> About
                </>
              }
            >
              <Menu.Item
                key="sub-menu-1"
                onClick={() => {
                  history.push("/privacy-policy");
                  fuc_handleMenuIcon(true);
                }}
              >
                <Icon style={{ color: "#3c1a5b" }} type="container" />
                Privacy Policy
              </Menu.Item>
              <Menu.Item
                key="sub-menu-2"
                onClick={() => {
                  history.push("/terms-and-conditions");
                  fuc_handleMenuIcon(true);
                }}
              >
                <Icon style={{ color: "#3c1a5b" }} type="file-text" />
                Terms of Policy
              </Menu.Item>
            </SubMenu>
          </Menu>
        </>
      ) : (
        <Menu className="mobile-nav-menu" mode="inline">
          <Menu.Item
            key="1"
            onClick={handleSignInOrUp}
            // style={{ borderTop: "0.1rem solid", borderBottom: "0.1rem solid" }}
          >
            <Icon style={{ color: "#3c1a5b" }} type="login" />
            Log In or Sign Up
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              history.push("/colleges");
              fuc_handleMenuIcon(true);
            }}
          >
            <Icon style={{ color: "#3c1a5b" }} type="select" />
            Colleges
          </Menu.Item>
          <Menu.Item key="3" onClick={handleSignInOrUp}>
            <Icon style={{ color: "#3c1a5b" }} type="book" />
            Shortlisted Colleges
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={() => {
              history.push("/pricing");
              fuc_handleMenuIcon(true);
            }}
          >
            <Icon style={{ color: "#3c1a5b" }} type="dollar" />
            Pricing
          </Menu.Item>

          <SubMenu
            key="sub1"
            title={
              <>
                <Icon style={{ color: "#3c1a5b" }} type="info-circle" /> About
              </>
            }
          >
            <Menu.Item
              key="sub-menu-1"
              onClick={() => {
                history.push("/privacy-policy");
                fuc_handleMenuIcon(true);
              }}
            >
              <Icon style={{ color: "#3c1a5b" }} type="container" />
              Privacy Policy
            </Menu.Item>
            <Menu.Item
              key="sub-menu-2"
              onClick={() => {
                history.push("/terms-and-conditions");
                fuc_handleMenuIcon(true);
              }}
            >
              <Icon style={{ color: "#3c1a5b" }} type="file-text" />
              Terms of Policy
            </Menu.Item>
          </SubMenu>
        </Menu>
      )}

      <CAFAuthenticationModal
        showAuthModal={showModal}
        onClose={() => setShowModal(false)}
      />
    </Drawer>
  );
};

export default MobileNav;

//user upload file comment out

/* <div className="" style={{ display: "flex", alignItems: "center" }}>
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${getUrl("uploads")}/profile`}
              method="post"
              withCredentials={true}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              // style={{
              //   width: "20px",
              //   height: "20px"
              // }}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: "11rem",
                    height: "95px",
                    borderRadius: "50%"
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            <div
              style={{
                color: "white",
                fontSize: "2rem",
                fontWeight: "600",
                fontFamily: "Montserrat",
                marginLeft: "1rem"
              }}
            >
              {userData.user
                ? userData.user.name.charAt(0).toUpperCase() +
                  userData.user.name.slice(1)
                : null}
            </div>
          </div>
        */
