import React from "react";
import { Input, Icon, Form, Spin, notification } from "antd";
import axios from "axios";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import getUrl from "../../utilities/apiConstant";
import CAFPhoneAuth from "./CAFPhoneAuth";
import { Context } from "../../utilities/Context";

const FormItem = Form.Item;

class CAFLogin extends React.Component {
  state = {
    showForgotPass: false,
    isLoading: false,
    responseMsg: null,
    phoneVerify: false,
  };

  static contextType = Context;

  handleSignIn = async (e) => {
    const pathName = window.location.pathname;
    e.preventDefault();
    const values = await this.props.form.validateFields();
    try {
      this.setState({ isLoading: true });
      const { current_email, password } = values;
      const response = await axios.post(
        `${getUrl("authSignin")}`,
        {
          email: current_email,
          password,
          source: "caf",
          path: pathName,
        },
        { withCredentials: true }
      );
      this.setState({ isLoading: false, response: response.data.message });
      notification.success({
        message: get(response, "data.message") || "Something went wrong",
      });
      this.updateDataToContext();
      if (this.props.onProceed) {
        this.shortlistApi();
      }
      if (this.props.auth_page)
        this.props.history.goBack() ?? this.props.history.push("/");
      else this.props.onCloseModal();
    } catch (error) {
      this.setState({ isLoading: false });
      notification.error({
        message: get(error.response, "data.message") || "Something went wrong",
      });
    }
  };

  handleForgotPassword = async (e) => {
    e.preventDefault();
    const values = await this.props.form.validateFields();
    try {
      this.setState({ isLoading: true });
      const { email_forgot } = values;
      const response = await axios.post(getUrl("authForgotPassword"), {
        email: email_forgot,
        source: "caf",
      });
      this.setState({ isLoading: false });
      notification.success({
        message: get(response, "data.message") || "Something went wrong",
      });
      // !this.props.auth_page ? this.props.onCloseModal() : Router.push("/");
    } catch (error) {
      this.setState({ isLoading: false });
      notification.error({
        message: get(error.response, "data.message") || "Something went wrong",
      });
    }
  };

  updateDataToContext = async () => {
    try {
      const response = await axios(getUrl("user"), {
        withCredentials: true,
      });
      const data = response && response.data ? response.data.data : {};
      this.context.updateContextData(data);
    } catch (error) {
      // notification.error({
      //   message: "Something went wrong"
      // });
    }
  };

  shortlistApi = async () => {
    const selectedData = this.context.selectedCollegesData;
    try {
      await axios.post(
        `${getUrl("root")}/caf/user/shortlist/proceed`,
        selectedData,
        {
          withCredentials: true,
        }
      );
      this.props.history.push("/profile/college-selection");
      // this.updateDataToContext();
    } catch (e) {
      notification.error({ message: "Something went wrong !" });
    }
  };

  // handleFacebookVerify = async () => {
  //   window.open(getUrl("authFacebook"), "sharer", "width=548,height=400");
  //   window.loginCallBack = this.loginCallBack;
  // };

  handleGoogleVerify = async () => {
    window.open(getUrl("authGoogle"), "sharer", "width=548,height=400");
    window.loginCallBack = this.loginCallBack;
  };

  loginCallBack = (status) => {
    if (status === "success" && !this.props.auth_page) {
      this.props.onCloseModal();
      // this.updateDataToContext();
      notification.success({
        message: "Signed In Successfully",
      });
    } else if (status === "success" && this.props.auth_page) {
      // Router.push("/");
      // this.updateDataToContext();
      notification.success({
        message: "Signed In Successfully",
      });
    }
  };

  handlePhoneAuth = (value, closeModal) => {
    this.setState({ phoneVerify: value });
    // for closing auth modal & update userData
    if (closeModal) {
      // this.props.auth_page ? Router.push("/") : this.props.onCloseModal();
      this.props.onCloseModal();
      this.updateDataToContext();
    }
  };

  handleLoadingLayout = () => {
    return this.state.isLoading ? (
      <div className="login-comp__loading">
        <Spin size="large" />
      </div>
    ) : null;
  };

  handlePhoneVerifyLayout = () => {
    return this.state.phoneVerify ? (
      <div className="register-content__phone-verify">
        <CAFPhoneAuth type="signin" handlePhoneAuth={this.handlePhoneAuth} />
      </div>
    ) : null;
  };

  onSignUpClick = () => {
    if (this.props.auth_page) {
      this.props.history.push("/signup");
      return;
    }
    this.props.showRegisterScreen();
  };

  forgotPasswordLayout = (getFieldDecorator) => (
    <>
      <h2 className="login-content__heading">Enter your Recovery Mail</h2>
      <div className="login-details">
        <Form onSubmit={this.handleForgotPassword} style={{ display: "grid" }}>
          <FormItem>
            {getFieldDecorator("email_forgot", {
              rules: [
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid Email!",
                },
              ],
            })(
              <Input
                className="login-details__input"
                placeholder="Email"
                type="email_forgot"
                prefix={
                  <Icon className="login-details__prefix-icon" type="mail" />
                }
              />
            )}
          </FormItem>
          <p
            onClick={() => {
              this.setState({ showForgotPass: false });
            }}
            style={{ cursor: "pointer", color: "#1890ff", textAlign: "end" }}
          >
            Sign In
          </p>
          <button
            className="login-submit__button"
            onClick={this.handleForgotPassword}
          >
            Ask for recovery link
          </button>
        </Form>
      </div>
    </>
  );

  signInLayout = (getFieldDecorator) => (
    <>
      <h2 className="login-content__heading">Sign in to EntranceZone</h2>
      <div className="login-content__social-icon">
        {/* <div
                  style={{ visibility: 'hidden' }}
                  className="login-content__social-icon--border"
                  onClick={this.handleFacebookVerify}>
                  <span
                    className="icon-facebook-app-symbol"
                    style={{ fontWeight: 'bolder', fontSize: '1.7rem' }}
                  />
                </div> */}
        {/* <div
          className="login-content__social-icon--border"
          onClick={this.handleGoogleVerify}
        >
          <Icon
            type="google"
            style={{ fontWeight: "bolder", fontSize: "1.7rem" }}
          />
        </div> */}
        <div
          className="login-content__social-icon--border"
          onClick={() => this.handlePhoneAuth(true)}
        >
          <Icon
            type="phone"
            theme="filled"
            style={{ fontWeight: "bolder", fontSize: "1.7rem" }}
          />
        </div>
      </div>
      <p style={{ color: "#A9A9A9" }}>or use email account:</p>
      <div className="login-details">
        <Form onSubmit={this.handleSignIn} style={{ display: "grid" }}>
          <FormItem>
            {getFieldDecorator("current_email", {
              rules: [
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid Email!",
                },
              ],
            })(
              <Input
                className="login-details__input"
                placeholder="Email"
                prefix={
                  <Icon className="login-details__prefix-icon" type="mail" />
                }
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  min: 8,
                  message: "At least 8 characters!",
                },
              ],
            })(
              <Input
                className="login-details__input"
                placeholder="Password"
                type="password"
                autoComplete="current_password"
                prefix={
                  <Icon className="login-details__prefix-icon" type="lock" />
                }
              />
            )}
          </FormItem>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p onClick={this.onSignUpClick} style={{ cursor: "pointer" }}>
              or <span style={{ color: "#1890ff" }}>Sign Up</span>
            </p>
            <p
              onClick={() => this.setState({ showForgotPass: true })}
              style={{ color: "#1890ff", cursor: "pointer" }}
            >
              Forgot your password?
            </p>
          </div>
          <button className="login-submit__button" type="submit">
            SIGN IN
          </button>
        </Form>
      </div>
    </>
  );

  render() {
    const { showForgotPass } = this.state;
    const {
      auth_page,
      form: { getFieldDecorator },
    } = this.props;
    return (
      <div className={`login-comp ${auth_page}`}>
        {/* <CAFAuthFeature
          auth_page={auth_page}
          page="signin"
          authFeatureCallback={this.props.showRegisterScreen}
        /> */}
        <div className="login-content">
          {showForgotPass
            ? this.forgotPasswordLayout(getFieldDecorator)
            : this.signInLayout(getFieldDecorator)}
        </div>
        {this.handleLoadingLayout()}
        {this.handlePhoneVerifyLayout()}
      </div>
    );
  }
}

export default withRouter(Form.create()(CAFLogin));
