import * as yup from "yup";
import moment from "moment";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const getAddressSchema = () =>
  yup.object().shape({
    state: yup.string().required("State is Required"),
    district: yup.string().required("District is Required"),
    city: yup.string().required("City is Required"),
    pincode: yup
      .string()
      .matches("^[0-9]{1,6}$", "Pincode is not valid")
      .min(6, "Please enter 6 digit Pincode")
      .max(6, "Please enter 6 digit Pincode")
      .required("Pincode is Required"),
    line_1: yup
      .string()
      .max(255, "Line 1 should not exceed 255 characters")
      .required("Line 1 is Required"),
    line_2: yup.string().max(255, "Line 2 should not exceed 255 characters")
  });

const getGuardianDetailsSchema = () =>
  yup.object().shape({
    salutation: yup.string().required("Parent's Salutation is Required"),
    name: yup.string().required("Parent's Name is required"),
    email: yup.string().email("Incorrect email"),
    // .required("Email is required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone Number must be exactly of 10 digits")
      .max(10, "Phone Number must be exactly of 10 digits"),
    education: yup.string().max(255, "Too long"),
    occupation: yup.string().max(255, "Too long")
  });

export const getPersonalSchema = () =>
  yup.object({
    salutation: yup.string().required("Applicant's Salutation is Required"),
    first_name: yup.string().required("Applicant's First Name is Required"),
    middle_name: yup.string(),
    last_name: yup.string(),
    nationality: yup.string().required("Applicant's Nationality Required"),
    email: yup
      .string()
      .email("Incorrect email")
      .required("Applicant's Email is Required"),
    dob: yup
      .date("Enter correct date")
      .required("Applicant's Date of Birth is required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Applicant's Phone number is not valid")
      .min(10, "Phone Number must be exactly of 10 digits")
      .max(10, "Phone Number must be exactly of 10 digits")
      .required("Applicant's Phone Number is Required"),
    alternate_phone: yup
      .string()
      .matches(phoneRegExp, "Applicant's Alternate Phone number is not valid")
      .min(10, "Phone Number must be exactly of 10 digits")
      .max(10, "Phone Number must be exactly of 10 digits"),
    gender: yup.string().required("Applicant's Gender is Required"),
    blood_group: yup.string().max(50),
    category_id: yup
      .number()
      .required("Applicant's Reservation Category is Required"),
    permanent_address: getAddressSchema(),
    current_address: getAddressSchema(),
    both_address_same: yup.boolean(),
    parent_detail: yup.object().shape({
      father: getGuardianDetailsSchema(),
      mother: getGuardianDetailsSchema()
      // guardian: getGuardianDetailsSchema(),
    })
  });

const getAddressValues = data => ({
  state: data?.state ?? undefined,
  district: data?.district ?? undefined,
  city: data?.city ?? undefined,
  pincode: data?.pincode ?? undefined,
  line_1: data?.line_1 ?? undefined,
  line_2: data?.line_2 ?? undefined
});

const getGuardianValues = (data, salutation) => ({
  salutation,
  name: data?.name ?? undefined,
  email: data?.email ?? undefined,
  phone: data?.phone ?? undefined,
  education: data?.education ?? undefined,
  occupation: data?.occupation ?? undefined
});

export const getInitialValues = data => ({
  salutation: data?.salutation ?? undefined,
  first_name: data?.first_name ?? undefined,
  middle_name: data?.middle_name ?? undefined,
  last_name: data?.last_name ?? undefined,
  email: data?.email ?? undefined,
  phone: data?.phone ?? undefined,
  alternate_phone: data?.alternate_phone ?? undefined,
  blood_group: data?.blood_group ?? undefined,
  category_id: data?.category_id ?? undefined,
  gender: data?.gender ?? undefined,
  dob: data?.dob ? moment(data?.dob) : undefined,
  permanent_address: getAddressValues(data?.permanent_address),
  current_address: getAddressValues(data?.current_address),
  both_address_same: data?.both_address_same ?? false,
  parent_detail: {
    father: getGuardianValues(data?.parent_detail?.father, "Mr."),
    mother: getGuardianValues(data?.parent_detail?.mother, "Mrs.")
    // guardian: getGuardianValues(data?.parent_detail?.guardian),
  },
  nationality: data?.nationality ?? "Indian"
});
