import React, { useState, useContext } from "react";
import CAFAuthenticationModal from "../Login/CAFAuthenticationModal";
import { Icon, message } from "antd";
import { Context } from "../../utilities/Context";
import { useHistory } from "react-router-dom";
// import moment from "moment";
const PricingCard = ({ Data }) => {
  const history = useHistory();
  const { isLogin, setSelectedPackContext, setRedirectUrl } = useContext(
    Context
  );
  const [showModal, setCloseModal] = useState(false);
  const {
    full_name = "N/A",
    discounted_price = "N/A",
    benefits = [],
    actual_price,
    max_form_count = "N/A",
    is_active,
    is_available,
    package_expiry = "N/A",
  } = Data;
  const handleBuyButton = () => {
    if (!isLogin()) return setCloseModal(true);
    if (is_active) return message.info("This pack is already activated");
    if (!is_available) return message.info("This pack is not available");
    setSelectedPackContext(Data);
    setRedirectUrl(window.location.pathname);
    history.push({
      pathname: "/profile/upgrade",
      state: { currentValue: 1 },
    });
  };
  const college = max_form_count > 1 ? "Colleges" : "College";
  return (
    <div>
      <div className="pricing-card-main-div" onClick={handleBuyButton}>
        <h2 className="pricing-card-type">{full_name}</h2>
        {actual_price === discounted_price ? null : (
          <h2 className="pricing-card-actual-cost">{actual_price}</h2>
        )}
        <h1 className="pricing-card-cost">{discounted_price}</h1>
        <h2 className="pricing-card-college">{`Upto ${max_form_count} ${college}`}</h2>
        <div className="pricing-card-assitance-div">
          {benefits.summary.map((data, index) => (
            <div className="pricing-card-benefits-summary" key={index}>
              <Icon type="check" style={{ color: "#ffffff" }} />
              <p className="pricing-card-assistance-para">{data}</p>
            </div>
          ))}
        </div>
        <h2 className="pricing-card-validity">Validity : {package_expiry}</h2>
        {is_active ? (
          <button className="primary-btn buy-now-btn">
            Active
            <Icon
              type="check-circle"
              className="pricing-card-antd-check-circle-icon"
            />
          </button>
        ) : is_available ? (
          <button className="primary-btn buy-now-btn">Buy Now</button>
        ) : (
          <button className="primary-btn buy-now-btn">
            Not Available
            <Icon
              type="close-circle"
              className="pricing-card-antd-check-circle-icon"
            />
          </button>
        )}
      </div>
      <CAFAuthenticationModal
        showAuthModal={showModal}
        onClose={() => setCloseModal(false)}
      />
      <div className="pricing-card-assitance-details-div">
        {benefits.detail.map((data, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: ".8rem 0",
            }}>
            <Icon type="check" style={{ color: "#faca45" }} />
            <p className="pricing-card-assistance-para">{data}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default PricingCard;
