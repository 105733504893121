import React from "react";
import { Spin } from "antd";

export const SpinnerComp = () => {
  return (
    <div
      style={{
        display: "grid",
        gridColumn: "1/-1",
        height: "100vh",
        placeContent: "center",
      }}>
      <Spin />
    </div>
  );
};
