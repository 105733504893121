import React, { useState } from "react";
import Facility from "./Facility";
import { Row, Col, Button } from "antd";
const Facilities = ({ facilities }) => {
  const [more, setMore] = useState(false);
  const handleShowData = e => {
    e.preventDefault();
    setMore(!more);
  };
  const facilityLayout = facilities.map((data, index) => (
    <Col xs={12} md={3} key={index}>
      <Facility key={index} facility={data} />
    </Col>
  ));
  let minimumFacilities = [];
  for (let i = 0; i <= 15; i++) {
    minimumFacilities.push(facilityLayout[i]);
  }
  const minimumFacilitiesLength =
    minimumFacilities.length < facilityLayout.length;
  return (
    <div style={{ marginTop: "3rem" }}>
      <div>
        <Row gutter={[16, 16]}>
          <div className="facility-layout-div">
            {more ? facilityLayout : minimumFacilities}
          </div>
        </Row>
      </div>
      {minimumFacilitiesLength ? (
        more ? (
          <div className="show-more-button-facility">
            <Button
              className="show-hide-button-facility"
              onClick={handleShowData}>
              Hide
            </Button>
          </div>
        ) : (
          <div className="show-more-button-facility">
            <Button
              className="show-hide-button-facility"
              onClick={handleShowData}>
              View All
            </Button>
          </div>
        )
      ) : null}
    </div>
  );
};
export default Facilities;
