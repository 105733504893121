import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Icon, Select, Row, Col, Button } from "antd";
import { Context } from "../../utilities/Context";
import UG from "./UG";
import PG from "./PG";
const { Option } = Select;
const AddAnotherEducation = ({ data, meta, documentsData }) => {
  const history = useHistory();
  const { is_academic_detail_complete } = useContext(Context);
  const [showSelector, setShowSelector] = useState(false);
  const [showUG, setShowUG] = useState(false);
  const [showPG, setShowPG] = useState(false);
  const [showUGFirst, setShowUGFirst] = useState(true);
  const ugData = data && data.length ? data.find(item => item.id === 1) : {};
  const pgData = data && data.length ? data.find(item => item.id === 2) : {};

  useEffect(() => {
    handleEducationLevel();
  }, [data, is_academic_detail_complete]);

  const handleEducationLevel = () => {
    const educationLevelData = data && data.length ? data : [];
    if (educationLevelData && educationLevelData.length) {
      educationLevelData.map(item => {
        switch (item.id) {
          case 1:
            setShowUG(true);
            break;
          case 2:
            setShowPG(true);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleSelect = value => {
    switch (value) {
      case "under_graduation":
        setShowUGFirst(true);
        setShowUG(true);
        setShowSelector(false);
        break;
      case "post_graduation":
        setShowUGFirst(false);
        setShowPG(true);
        setShowSelector(false);
        break;
      default:
        break;
    }
  };
  const showUnderGraduation = showUG ? (
    <UG key={`ug`} data={ugData} meta={meta} />
  ) : null;
  const showPostGraduation = showPG ? (
    <PG key={`pg`} data={pgData} meta={meta} />
  ) : null;
  return (
    <div style={{ position: "relative" }}>
      {showSelector ? (
        <div>
          <Select
            placeholder="Select Education"
            style={{ width: "30%", marginBottom: "2rem" }}
            onChange={handleSelect}>
            <Option value="under_graduation">Under Graduation</Option>
            <Option value="post_graduation">Post Graduation</Option>
          </Select>
        </div>
      ) : null}
      <button
        className="add"
        style={{
          padding: "1rem 2rem",
          marginBottom: "5rem",
          backgroundColor: "#FACA45",
          borderColor: "white",
          border: "none",
          color: "white"
        }}
        onClick={() => setShowSelector(true)}>
        <Icon type="plus" /> Add another education
      </button>
      {!showUGFirst
        ? [showPostGraduation, showUnderGraduation]
        : [showUnderGraduation, showPostGraduation]}
      <Row style={{ marginBottom: "5rem" }}>
        <Col
          xs={24}
          md={12}
          //  style={{ textAlign: "left" }}
        >
          <Button
            className="secondary-btn academic-buttons"
            style={{
              padding: "0.5rem 1rem"
            }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push("/profile/personal-information");
            }}>
            Back To Personal Details
          </Button>
        </Col>
        {is_academic_detail_complete ? (
          <Col xs={24} md={12} style={{ textAlign: "right" }}>
            <Button
              className="secondary-btn academic-buttons"
              style={{
                padding: "0.5rem 1rem"
              }}
              onClick={() => history.push("/profile/college-selection")}>
              Proceed to College Selection
            </Button>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default AddAnotherEducation;
