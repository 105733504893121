import React, { useState } from "react";
import { Button, Icon } from "antd";
import { PdfMake } from "./PdfMake";
import getUrl from "utilities/apiConstant";
import LoadingOverlay from "components/LoadingOverlay";

const Checkout = (props) => {
  const [loading, setLoading] = useState(false);
  const form_id = props?.form_id ?? undefined;
  const first_name = props?.data?.first_name ?? undefined;
  const last_name = props?.data?.last_name ?? undefined;
  const email = props?.data?.email ?? undefined;
  const phone = props?.data?.phone ? `+91-${props?.data?.phone}` : undefined;
  const state = props?.data?.address?.state ?? undefined;
  const city = props?.data?.address?.city ?? undefined;
  const course_name = props?.data?.course_name ?? undefined;
  const requested_branches = props?.data?.requested_branches ?? [];
  const branches = requested_branches?.length
    ? requested_branches.map((item) => item.name)
    : null;
  const featured_image = props?.data?.featured_image?.display_url ?? undefined;
  const featured_alt_text = props?.data?.featured_image?.alt_text ?? undefined;
  const logo_image = props?.data?.logo?.display_url ?? undefined;
  const logo_alt_text = props?.data?.logo?.alt_text ?? undefined;
  const full_name = props?.data?.full_name ?? undefined;
  const actual_fees = props?.data?.actual_fees ?? undefined;
  const discounted_fees = props?.data?.discounted_fees ?? undefined;
  const fees = discounted_fees ? discounted_fees : actual_fees;

  const handlePay = () => {
    setLoading(true);
    window.open(`${getUrl("root_v2")}/caf/forms/${form_id}/payment`, "_self");
  };

  const personalData = [
    { heading: "Name", value: `${first_name} ${last_name}` },
    { heading: "Email", value: email },
    { heading: "Phone", value: phone },
    // { heading: "State", value: state },
    // { heading: "City", value: city },
    { is_divider: true },
    { heading: "Course", value: course_name },
  ];

  return (
    <LoadingOverlay active={loading}>
      <div className="checkout">
        <div>
          <p className="content-heading">Checkout</p>

          <div className="border">
            <div className="border__highlighter" />
            <div className="border__border" />
          </div>
        </div>
        <div className="content">
          <div>
            <div className="detail-part">
              <p className="detail">Detail</p>
              {/* <PdfMake form_id={form_id} /> */}
            </div>
            <div className="block">
              {personalData.map((item, index) => {
                if (item.is_divider) {
                  return <div key={index} className="block-divider" />;
                }
                return (
                  <div key={index} className="info-block">
                    <p className="heading">{item.heading}</p>
                    <p className="value">{item.value}</p>
                  </div>
                );
              })}

              <div className="info-block">
                <p className="heading">Branches</p>
                <p className="value">
                  {branches?.length ? branches.join(", ") : null}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="detail-part">
              <p className="detail">Order Summary</p>
            </div>
            <div style={{ maxWidth: "40rem" }}>
              <div
                style={{
                  boxShadow: "0 10px 25px 0 rgba(0, 0, 0, 0.1)",
                  marginTop: "3rem",
                  borderRadius: "0.5rem",
                }}
              >
                <img
                  src={featured_image}
                  alt={featured_alt_text}
                  className=""
                  style={{ width: "100%", height: "14rem" }}
                />
                <div
                  style={{
                    transform: "translateY(-50%)",
                    padding: "0 2rem",
                    height: "7rem",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={logo_image}
                    alt={logo_alt_text}
                    className=""
                    style={{
                      width: "8rem",
                      height: "8rem",
                      borderRadius: "50%",
                      backgroundColor: "white",
                      border: "0.1rem solid #f08143",
                    }}
                  />
                  <p>
                    <Icon
                      type="environment"
                      theme="filled"
                      style={{ marginRight: "1rem" }}
                    />
                    {`${state}, ${city}`}
                  </p>
                </div>
                <h3
                  style={{
                    fontSize: "1.8rem",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.22",
                    padding: "0 2rem",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#44337a",
                  }}
                >
                  {full_name}
                </h3>
                <div className="fee-amount">
                  <p className="college-application-amount">
                    APPLICATION AMOUNT
                  </p>
                  <strong className="college-apply-amount ">
                    ₹ {Math.ceil(fees)}
                  </strong>
                </div>
              </div>
              <Button
                onClick={handlePay}
                type="primary"
                // icon="arrow-right"
                className="pay-btn"
              >
                {Math.ceil(fees)
                  ? `Pay Rs ${Math?.ceil(fees) ?? "---"}`
                  : "Submit"}
                <Icon type="arrow-right" className="arw-rgt-icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Checkout;
