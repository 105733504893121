import React, { useState, useEffect } from "react";
import ShortlistedCollege from "./ShortlistedCollegeWrapper";
import { Helmet } from "react-helmet";
import RecommendedCollege from "./RecommendedWrapper";
import ProfileStepper from "../profile/ProfileStepper";
import AppliedCollege from "./AppliedCollegeWrapper";
import axios from "axios";
import { Spin, notification } from "antd";
import getUrl from "../../utilities/apiConstant";
import Breadcrumbs from "../BreadCrumbs";
const CollegeSelectionIndex = ({ location }) => {
  const [allColleges, setAllColleges] = useState([]);
  // const [shortlistColleges, setShortlistColleges] = useState(false);
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.get("type") && params.get("msg")) {
        if (params.get("type") === "success")
          notification.success({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "fail")
          notification.error({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "pending")
          notification.warning({ message: decodeURI(params.get("msg")) });
      } else return;
    }
    const fetchCollegeSelection = async () => {
      try {
        const allCollegeResponse = await axios.get(
          `${getUrl("root_v2")}/caf/user/colleges`,
          {
            withCredentials: true,
          }
        );
        setAllColleges(allCollegeResponse.data.data);
      } catch (error) {
        notification.error({ message: "Something went wrong !" });
      }
    };
    fetchCollegeSelection();
  }, []);
  const shortlistedData = allColleges.filter((item) => item.is_shortlisted);
  const appliedColleges = allColleges.filter((item) => item.is_applied);
  // if (shortlistedData.length && !shortlistColleges) {
  //   setShortlistColleges(true);
  // }
  if (!allColleges.length) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>
        <Spin />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>
          College Selection - Online College Admission Form | Entrancezone
        </title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      <div>
        <Breadcrumbs />
        {/* <ProfileStepper /> */}
        {/* {shortlistColleges ? ( */}
        <ShortlistedCollege shortlistedData={shortlistedData} />
        {/* ) : null} */}
        <AppliedCollege appliedData={appliedColleges} />
        <RecommendedCollege allColleges={allColleges} />
      </div>
    </>
  );
};

export default CollegeSelectionIndex;
