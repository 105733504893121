import React, { useState } from "react";
import { Row, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import ApplyCard from "./AppliedFormCard";
import CAFAuthenticationModal from "components/Login/CAFAuthenticationModal";
import Tags from "./Tags";
// import ComingSoonCard from "./ComingSoonCard";

const CollegeCard = ({
  collegesData,
  selectedFilters,
  deleteFilterCallback,
  setSelectedFilters,
  totalFilters,
  cardLoading,
  handlePagination,
  totalCount,
}) => {
  const [showModal, setShowModal] = useState(false);
  const searching =
    !Boolean(collegesData?.length % 15) && collegesData?.length !== totalCount;
  // const ComingSoon = <ComingSoonCard />;
  return (
    <>
      <Row className="college-card" gutter={[45, 45]}>
        <Tags
          selectedFilters={selectedFilters}
          deleteFilterCallback={deleteFilterCallback}
          setSelectedFilters={setSelectedFilters}
          totalFilters={totalFilters}
        />
        {collegesData?.length ? (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            threshold={1000}
            isReverse={false}
            loadMore={searching ? handlePagination : () => {}}
            hasMore={searching}
            loader={
              cardLoading ? (
                <section
                  key={Math.floor(Math.random())}
                  style={{
                    gridColumn: "1/-1",
                    display: "flex",
                    alignItems: "center",
                    justifySelf: "center",
                    justifyContent: "center",
                    height: "15vh",
                  }}
                >
                  <Spin size="large" />
                </section>
              ) : null
            }
          >
            <section className="apply-card-wrapper">
              {collegesData.map((item, index) => (
                <ApplyCard
                  key={index}
                  data={item}
                  collegesData={collegesData}
                />
              ))}
            </section>
          </InfiniteScroll>
        ) : (
          <h2 style={{ textAlign: "center", margin: "3rem 0" }}>
            No Result's Found
          </h2>
        )}
        <CAFAuthenticationModal
          showAuthModal={showModal}
          onClose={() => setShowModal(false)}
          onProceed={true}
        />
      </Row>
    </>
  );
};

export default CollegeCard;
