import React from "react";
import logo from "../../../img/successImage.png";
import { useHistory } from "react-router-dom";
const Apply = ({ college_name }) => {
  const history = useHistory();
  const handleApply = () => {
    history.push("/profile/applications");
  };
  return (
    <div>
      <h2 className="success-summary-heading">Summary</h2>
      <div className="success-child-div-1">
        <img alt="success-logo" src={logo} style={{ width: "12rem" }} />
        <h1 className="success-heading">Success!</h1>
        <p className="success-message">
          You have successfully applied to {college_name}
        </p>
        <button
          className="primary-btn success-message-button"
          onClick={handleApply}
        >
          View Application
        </button>
      </div>
    </div>
  );
};
export default Apply;
