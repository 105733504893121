import React from "react";
import { Row, Col } from "antd";
import CourseBranch from "./CourseBranch";
const CourseBranchWrapper = ({
  courses,
  handleSelectedBranch,
  isSpaceAvailable,
  branchPriority
}) => {
  const { branches = [] } = courses;
  // const [more, setMore] = useState(false);
  // const handleClick = () => {
  //   setMore(!more);
  // };
  const total_streams =
    branches && branches.length
      ? branches.map((data, index) => (
          <Col key={index} xs={24} md={12}>
            <CourseBranch
              data={data}
              handleSelectedBranch={handleSelectedBranch}
              isSpaceAvailable={isSpaceAvailable}
              branchPriority={branchPriority}
            />
          </Col>
        ))
      : [];
  let minimumCourses = [];
  if (branches && branches.length) {
    for (let i = 0; i <= 3; i++) {
      minimumCourses.push(total_streams[i]);
    }
  }

  // const total_stream_length = total_streams.length > 4;
  return (
    <div style={{ marginTop: "3rem" }}>
      <h3 className="heading-3 course-branch-wrapper-div">Selected Course</h3>
      <div className="course-branch-wrapper-course-name-div">
        <div className="course-wrapper-name-div">{courses.course}</div>
        <div className="tuition-wrapper-fee-div">
          Tuition Fee : {courses.first_year_fee}
        </div>
      </div>
      <div className="heading-3 course-branch-wrapper-div">Select Branch</div>
      <div className="course-branch-wrapper-div-small">
        You can select upto 5 branches !{" "}
      </div>
      <Row gutter={[20, 20]} style={{ margin: "unset" }}>
        {total_streams}
      </Row>
      {/* {more ? (
        <Row gutter={[20, 20]}>{total_streams}</Row>
      ) : (
        <Row gutter={[20, 20]}>{minimumCourses}</Row>
      )} */}
      {/* <div style={{ width: "100%", textAlign: "center" }}>
        {total_stream_length ? (
          more ? (
            <Button className="show-hide-button" onClick={handleClick}>
              View Less
            </Button>
          ) : (
            <Button className="show-hide-button" onClick={handleClick}>
              View More
            </Button>
          )
        ) : null}
      </div> */}
    </div>
  );
};
export default CourseBranchWrapper;
