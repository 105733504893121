import React, { useState } from "react";
import _get from "lodash/get";
import { Form, Select, Input } from "antd";
import CommonSearch from "./CommonSearch";

const { Option } = Select;
const FormItem = Form.Item;

const Graduation = (props) => {
  const {
    meta,
    graduationType = "ug",
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = props;
  const [marksType, setMarksType] = useState(
    values?.[graduationType]?.marks_type === "percentage" ? "%" : "CGPA"
  );
  // const [appearing, setAppearing] = useState(
  //   values?.[graduationType]?.is_appearing ?? false
  // );
  const states = meta && meta.states ? meta.states : [];
  const currentYear = new Date().getFullYear();

  const propsToPass = {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
    graduationType,
  };
  const yearSelector = () => {
    let optionArray = [];
    for (let i = currentYear + 5; i >= 1990; i--) {
      let optionData = (
        <Option value={i} key={`k${i}`}>
          {i}
        </Option>
      );
      optionArray.push(optionData);
    }
    return optionArray;
  };

  return (
    <section className="cpa-form__wrapper">
      <p
        className="heading-3"
        style={{
          gridColumn: "1/-1",
          color: "#3c1a5b",
        }}
      >
        {graduationType?.replace(/_/g, " ")?.toUpperCase() ?? "---"}
      </p>
      <FormItem
        label="APPEARING STATUS"
        validateStatus={
          touched?.[graduationType]?.is_appearing &&
          errors?.[graduationType]?.is_appearing
            ? "error"
            : values?.[graduationType]?.is_appearing
            ? "success"
            : null
        }
        help={
          touched?.[graduationType]?.is_appearing &&
          errors?.[graduationType]?.is_appearing
        }
        required
        // hasFeedback={Boolean(
        //   touched?.[graduationType]?.is_appearing &&
        //     errors?.[graduationType]?.is_appearing
        // )}
      >
        <Select
          size="large"
          value={
            values?.[graduationType]?.is_appearing === undefined
              ? undefined
              : values?.[graduationType]?.is_appearing
              ? 1
              : 0
          }
          placeholder="Graduation Status"
          onChange={(newValue) => {
            const value = newValue ? true : false;
            setFieldValue(`${graduationType}.is_appearing`, value, true);
            setFieldTouched(`${graduationType}.is_appearing`, false);
            if (value) {
              setFieldValue(`${graduationType}.marks_type`, undefined, true);
              setFieldValue(`${graduationType}.marks`, undefined, true);
              setFieldValue(`${graduationType}.maximum_marks`, undefined, true);
              // setAppearing(value);
            }
          }}
        >
          <Option value={1}>Appearing</Option>
          <Option value={0}>Passed</Option>
        </Select>
      </FormItem>
      <FormItem
        label="APPEARING/PASSING YEAR"
        validateStatus={
          touched?.[graduationType]?.year && errors?.[graduationType]?.year
            ? "error"
            : values?.[graduationType]?.year
            ? "success"
            : null
        }
        required
        help={touched?.[graduationType]?.year && errors?.[graduationType]?.year}
        // hasFeedback={Boolean(
        //   touched?.[graduationType]?.year && errors?.[graduationType]?.year
        // )}
      >
        <Select
          placeholder="Year of Appearing/Passing"
          showSearch
          value={values?.[graduationType]?.year}
          size="large"
          onChange={(value) => {
            setFieldValue(`${graduationType}.year`, value, true);
            setFieldTouched(`${graduationType}.year`, false);
          }}
          // onBlur={handleBlur}
        >
          {yearSelector()}
        </Select>
      </FormItem>
      {/* <CommonSearch {...propsToPass} type="course" optionType="course_name" /> */}
      {/* <CommonSearch {...propsToPass} type="branch" optionType="stream" /> */}
      {values[graduationType]?.is_appearing ||
      values[graduationType]?.is_appearing === undefined ? null : (
        <React.Fragment>
          <FormItem
            label="MARKING SCHEME"
            validateStatus={
              touched?.[graduationType]?.marks_type &&
              errors?.[graduationType]?.marks_type
                ? "error"
                : values?.[graduationType]?.marks_type
                ? "success"
                : null
            }
            help={
              touched?.[graduationType]?.marks_type &&
              errors?.[graduationType]?.marks_type
            }
            required
            // hasFeedback={Boolean(
            //   touched?.[graduationType]?.marks_type &&
            //     errors?.[graduationType]?.marks_type
            // )}
          >
            <Select
              size="large"
              value={values?.[graduationType]?.marks_type}
              placeholder="Marks Type (CGPA/Percentage)"
              onChange={(value) => {
                const data = value === "Percentage" ? "%" : "CGPA";
                const totalMarks = value === "Percentage" ? 100 : 10;
                setMarksType(data);
                setFieldValue(`${graduationType}.marks_type`, value, true);
                setFieldValue(
                  `${graduationType}.maximum_marks`,
                  totalMarks,
                  true
                );
                setFieldValue(`${graduationType}.marks`, undefined, true);
                setFieldTouched(`${graduationType}.mark_type`, false);
                setFieldTouched(`${graduationType}.maximum_marks`, false);
                setFieldTouched(`${graduationType}.marks`, false);
              }}
              // onBlur={handleBlur}
            >
              <Option value="Percentage">Percentage</Option>
              <Option value="CGPA">CGPA</Option>
            </Select>
          </FormItem>
          <FormItem
            label="MARKS"
            validateStatus={
              touched?.[graduationType]?.marks &&
              errors?.[graduationType]?.marks
                ? "error"
                : values?.[graduationType]?.marks
                ? "success"
                : null
            }
            help={
              touched?.[graduationType]?.marks &&
              errors?.[graduationType]?.marks
            }
            required
            // hasFeedback={Boolean(
            //   touched?.[graduationType]?.marks &&
            //     errors?.[graduationType]?.marks
            // )}
          >
            <Input
              name={`${graduationType}.marks`}
              size="large"
              addonAfter={marksType}
              placeholder="Marks"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.[graduationType]?.marks}
            />
          </FormItem>
          {/* <FormItem
            label="TOTAL MARKS"
            validateStatus={
              touched?.[graduationType]?.maximum_marks &&
              errors?.[graduationType]?.maximum_marks
                ? "error"
                : values?.[graduationType]?.maximum_marks
                ? "success"
                : null
            }
            help={
              touched?.[graduationType]?.maximum_marks &&
              errors?.[graduationType]?.maximum_marks
            }
            // hasFeedback={Boolean(
            //   touched?.[graduationType]?.maximum_marks &&
            //     errors?.[graduationType]?.maximum_marks
            // )}
          >
            <Input
              name={`${graduationType}.maximum_marks`}
              value={values?.[graduationType]?.maximum_marks}
              size="large"
              addonAfter={marksType}
              placeholder="Total Marks"
              // onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormItem> */}
        </React.Fragment>
      )}
      <CommonSearch {...propsToPass} type="college" optionType="college_name" />
      <FormItem
        label="COURSE & SPECIALIZATION"
        validateStatus={
          touched?.[graduationType]?.course_name &&
          errors?.[graduationType]?.course_name
            ? "error"
            : values?.[graduationType]?.course_name
            ? "success"
            : null
        }
        help={
          touched?.[graduationType]?.course_name &&
          errors?.[graduationType]?.course_name
        }
        required
        // hasFeedback={Boolean(
        //   touched?.[graduationType]?.course_name &&
        //     errors?.[graduationType]?.course_name
        // )}
      >
        <Input
          name={`${graduationType}.course_name`}
          size="large"
          placeholder="Course & Specialization "
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.[graduationType]?.course_name}
        />
      </FormItem>
      <FormItem
        label="STATE"
        validateStatus={
          touched?.[graduationType]?.state_id &&
          errors?.[graduationType]?.state_id
            ? "error"
            : values?.[graduationType]?.state_id
            ? "success"
            : null
        }
        help={
          touched?.[graduationType]?.state_id &&
          errors?.[graduationType]?.state_id
        }
        required
        // hasFeedback={Boolean(
        //   touched?.[graduationType]?.state_id && errors?.[graduationType]?.state_id
        // )}
      >
        <Select
          placeholder="State"
          showSearch
          optionFilterProp="children"
          size="large"
          value={values?.[graduationType]?.state_id}
          onChange={(value) => {
            setFieldValue(`${graduationType}.state_id`, value, true);
            setFieldTouched(`${graduationType}.state_id`, false);
          }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
          // onBlur={handleBlur}
        >
          {states.map((item, index) => {
            return (
              <Option value={item.id} key={item.value}>
                {item.value}
              </Option>
            );
          })}
        </Select>
      </FormItem>
    </section>
  );
};

export default Graduation;
