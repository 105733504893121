import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../utilities/Context";
import { Dropdown, Menu, notification } from "antd";
import axios from "axios";
import getUrl from "../../utilities/apiConstant";
import CAFAuthenticationModal from "./CAFAuthenticationModal";

const AuthButton = () => {
  const history = useHistory();
  const { userData = {}, updateContextData } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const { user = {} } = userData;

  const handleCAFAuthenticationModal = () => {
    userData.user ? history.push("/profile") : setShowModal(true);
  };

  const handleLogout = async () => {
    const pathName = window.location.pathname;
    try {
      await axios.get(`${getUrl("root")}/user/logout`, {
        withCredentials: true,
      });
      updateContextData({});
      if (pathName !== "/" || pathName !== "/colleges")
        return history.push("/");
    } catch (error) {
      notification.error({
        message: "Something went wrong",
      });
    }
  };

  const authMenu = userData.user ? (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push("/profile");
          window.scrollTo(0, 0);
        }}>
        Dashboard
      </Menu.Item>
      <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
    </Menu>
  ) : (
    <Menu style={{ display: "none" }}></Menu>
  );
  return (
    <div
    //  style={{ gridColumn: "5/6" }}
    >
      <CAFAuthenticationModal
        showAuthModal={showModal}
        onClose={() => setShowModal(false)}
      />
      <Dropdown overlay={authMenu}>
        <button className="auth-button" onClick={handleCAFAuthenticationModal}>
          {user.name ? user.name : "Login"}
        </button>
      </Dropdown>

      {/* <Icon
        type="user"
        style={{
          color: "#ffffff",
          fontSize: "3.4rem"
        }}
        onClick={handleCAFAuthenticationModal}
      /> */}
    </div>
  );
};

export default AuthButton;
