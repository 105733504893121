import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Spin, notification } from "antd";
import getUrl from "../../utilities/apiConstant.js";
import CplForm from "./CplForm";
import { SpinnerComp } from "../SpinnerComp";
import CPAForm from "../../components/CPAForm";

const ApplyFormWrapper = (props) => {
  const history = useHistory();
  const {
    location: { pathname },
    match,
  } = props;
  const [responseData, setResponseData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${getUrl("colleges")}/${pathname}`, {
          withCredentials: true,
        });
        setIsLoading(false);
        setResponseData(response?.data?.data);
      } catch (error) {
        setIsLoading(false);
        if (error?.response?.status === 401) {
          notification.error({
            message: error?.response?.data?.error ?? "Unauthorized !",
            description: error?.response?.data?.message ?? "Sign in first !",
          });
          history.push("/signin");
        }
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <SpinnerComp />;
  }

  return (
    <>
      {responseData?.admission_mode === "cpa" ||
      responseData?.admission_mode === "cps" ? (
        <CPAForm data={responseData} match={match} />
      ) : responseData?.admission_mode === "cpl" ? (
        <CplForm data={responseData} slug={pathname} />
      ) : null}

      {/* {responseData?.admission_mode === "cpl" ? (
        <CplForm data={responseData} slug={pathname} />
      ) : null}
      {responseData?.admission_mode === "cpa" ? (
        <CPAForm data={responseData} />
      ) : null} */}
    </>
  );
};

export default ApplyFormWrapper;

{
  /* <CplForm data={responseData} pathname={pathname} /> */
}
