import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Icon, Row, Col, notification } from "antd";
import { useHistory } from "react-router-dom";
import _get from "lodash/get";
import getUrl from "../../utilities/apiConstant";
import { Context } from "../../utilities/Context";
import CourseDetails from "./Course-Details";
import CAFAuthenticationModal from "../../components/Login/CAFAuthenticationModal";
import axios from "axios";
import { BreadcrumbsCollege } from "../BreadCrumbs";
const img = require("../../img/Hurray.svg");
const CollegeProfile = ({ data, location = {} }) => {
  const history = useHistory();
  const {
    college_id,
    full_name,
    description,
    logo = {},
    address = {},
    featured_image = {},
    facilities = [],
    courses = [],
    accreditation_and_regulations,
    slug,
  } = data;

  const {
    isLogin,
    selectedCollegesData,
    setCollegeData,
    educationLevel,
    selectedStream,
    selectedCourse,
  } = useContext(Context);
  const isShortlisted = selectedCollegesData.find(
    (data) => data.college_id === college_id
  );
  const [shortlist, setShortlist] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const { city, state } = address;
  const {
    alt_text: logo_alt_text,
    display_url: logo_display_url,
    title: logo_title,
  } = logo;
  // const {
  //   alt_text: featured_alt_text,
  //   display_url: featured_display_url,
  //   title: featured_title,
  // } = featured_image;

  useEffect(() => {
    setShortlist(isShortlisted ? true : false);
    const getUrlParameter = (name) => {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      var results = regex.exec(location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };
    if (location.search) {
      setCollegeData(
        getUrlParameter("education_level_id" || undefined),
        "education"
      );
      setCollegeData(getUrlParameter("stream_id") || undefined, "stream");
      setCollegeData(getUrlParameter("course_id") || undefined, "query-course");
    }
  }, [isShortlisted, location.search, selectedCollegesData, setCollegeData]);

  // const handleShortlist = async () => {
  //   if (isLogin()) {
  //     try {
  //       setIsLoading(true);
  //       await axios.post(
  //         `${getUrl("root")}/caf/user/shortlist`,
  //         {
  //           college_id: college_id,
  //           education_level_id:
  //             educationLevel && educationLevel.id
  //               ? educationLevel.id
  //               : undefined,
  //           course_id: selectedCourse,
  //           stream_id:
  //             selectedStream && selectedStream.id
  //               ? selectedStream.id
  //               : undefined,
  //         },
  //         { withCredentials: true }
  //       );
  //       setIsLoading(false);
  //       setShortlist(!shortlist);
  //       setCollegeData(data, "college");
  //       return;
  //     } catch (error) {
  //       setIsLoading(false);
  //       notification.error({
  //         message:
  //           _get(error.response, "data.message") || "Something went wrong",
  //       });
  //       return;
  //     }
  //   }
  //   setShortlist(!shortlist);
  //   setCollegeData(data, "college");
  // };

  // const handleCancelShortlist = async () => {
  //   if (isLogin()) {
  //     try {
  //       setIsLoading(true);
  //       await axios({
  //         method: "DELETE",
  //         url: `${getUrl("root")}/caf/user/shortlist`,
  //         data: { college_id: college_id },
  //         withCredentials: true,
  //       });
  //       setIsLoading(false);
  //       setShortlist(!shortlist);
  //       setCollegeData(data, "college");
  //       return;
  //     } catch (error) {
  //       setIsLoading(false);
  //       notification.error({
  //         message:
  //           _get(error.response, "data.message") || "Something went wrong",
  //       });
  //       return;
  //     }
  //   }
  //   setShortlist(!shortlist);
  //   setCollegeData(data, "college");
  // };

  // const onProceed = async () => {
  //   if (!isLogin()) return setShowModal(true);
  //   history.push("/profile/college-selection");
  // };

  // const text = selectedCollegesData.length > 1 ? "colleges" : "college";
  return (
    <>
      <Helmet>
        <title>
          {`${full_name} Detail Page - Online College Admission Form | Entrancezone`}
        </title>
        <meta
          name="description"
          content="The Common Application Form (CAF) is a unique interface through which a student can apply to numerous colleges using one window. Through the college admission application platform, a student can apply to multiple colleges using a single application form."
        />
      </Helmet>
      <div className="college-profile-main-div">
        {/* <div className="college-detail__image-div">
          <img
            alt={featured_alt_text}
            src={featured_display_url}
            title={featured_title}
            className="college-detail__image"
          />
        </div> */}
        <div className="college-profile-header">
          <BreadcrumbsCollege />
          <div className="college-profile-child-div-1-1">
            <img
              alt={logo_alt_text}
              src={logo_display_url}
              title={logo_title}
              className="college-profile-child-div-1-1__image"
            />
            <div className="logo-icon-div">
              <h1 className="college-name-para">{full_name}</h1>
              <div className="address-grade-div">
                <div style={{ display: "flex" }}>
                  <div
                    className="icon-detail-div-2"
                    style={{ color: "#3c1a5b" }}
                  >
                    <Icon
                      type="environment"
                      theme="filled"
                      style={{ fontSize: "1.6rem" }}
                    />
                    <p
                      style={{
                        paddingLeft: "0.5rem",
                        fontWeight: "500",
                      }}
                    >{`${city}, ${state}`}</p>
                  </div>
                  <div className="icon-detail-div" style={{ color: "#3c1a5b" }}>
                    <Icon
                      type="check-circle"
                      theme="filled"
                      style={{ fontSize: "1.6rem" }}
                    />
                    <p
                      style={{
                        paddingLeft: "0.5rem",
                        fontWeight: "500",
                      }}
                    >
                      {accreditation_and_regulations}
                    </p>
                  </div>
                </div>
                {/* {shortlist ? (
                <button
                  className="secondary-btn college-profile-shortlist-button"
                  // className="antd-button-shortlist"
                  onClick={handleCancelShortlist}
                  style={{ margin: 0 }}>
                  {isLoading ? (
                    <>
                      <Icon
                        type="check-circle"
                        theme="filled"
                        style={{ marginRight: "1rem", fontSize: "1.8rem" }}
                      />
                      Shortlisted
                      <Icon type="loading" style={{ marginLeft: "0.5rem" }} />
                    </>
                  ) : (
                    <>
                      <Icon
                        type="check-circle"
                        theme="filled"
                        style={{ marginRight: "1rem", fontSize: "1.8rem" }}
                      />
                      Shortlisted
                    </>
                  )}
                </button>
              ) : (
                <button
                  className="primary-btn shortlist-btn  college-profile-shortlist-button"
                  onClick={handleShortlist}>
                  {isLoading ? (
                    <>
                      {" "}
                      <Icon type="loading" style={{ marginRight: "0.5rem" }} />
                      Shortlist{" "}
                    </>
                  ) : (
                    <>Shortlist</>
                  )}
                </button>
              )} */}
                <button
                  className="apply-now-btn"
                  onClick={() => history.push(`${slug}/apply`)}
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="college-profile-proceed-bar">
          {selectedCollegesData.length ? (
            <Col xs={24} md={24}>
              <Row className="proceed" type="flex" align="middle">
                <Col xs={0} md={2}>
                  <img
                    src={img}
                    alt=""
                    className=""
                    style={{
                      marginLeft: "1rem",
                      width: "5rem",
                      height: "5rem",
                    }}
                  />
                </Col>
                <Col xs={18} md={18}>
                  <p className="proceed__content">
                    <span className="proceed__content--highlighter">
                      Success!{" "}
                    </span>
                    You have selected{" "}
                    <span className="proceed__content--highlighter">
                      {selectedCollegesData.length}
                    </span>{" "}
                    {text}. You may proceed to applying or select more.
                  </p>
                </Col>
                <Col xs={6} md={2}>
                  <button className="proceed__btn" onClick={onProceed}>
                    Proceed
                  </button>
                </Col>
              </Row>
            </Col>
          ) : null}
        </div>
         */}
        <div className="course-details-wrapper-div">
          <CourseDetails
            college_id={college_id}
            college_complete_data={data}
            courses={courses}
            facilities={facilities}
            description={description}
          />
        </div>
        {/* <div className="content-div">{description}</div> */}
        <CAFAuthenticationModal
          showAuthModal={showModal}
          onClose={() => setShowModal(false)}
          onProceed={true}
        />
      </div>
    </>
  );
};
export default CollegeProfile;
