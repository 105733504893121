import React, { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Select, notification, Divider, Form, Button, Alert } from "antd";
import { Context } from "utilities/Context";
import getUrl from "utilities/apiConstant";
import { SpinnerComp } from "components/SpinnerComp";
import SetPriorityWrapper from "../../profile/ShortlistedColleges/SetPriorityWrapper";
import LoadingOverlay from "components/LoadingOverlay";
import ThrowError from "./ThrowError";

const { Option } = Select;
const FormItem = Form.Item;
function Courseselection({ data: apiData, match, form_id, setFormIdCallback }) {
  const { userData: { user = {} } = {} } = useContext(Context);
  const { is_email_verified, email } = user;
  const [showError, setShowError] = useState(false);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(
    apiData?.form_id ? undefined : {}
  );
  const { params: { college } = {} } = match || {};
  const getSchema = () =>
    Yup.object().shape({
      course_id: Yup.number().positive().required("Select a Course !"),
      branch_id: Yup.array()
        .min(1, "Select atleast one branch")
        .max(5, "You can select only 5 branches")
        .required("Select atleast One Branch !")
    });

  const getAppliedFormData = async () => {
    try {
      const { data } = await axios.get(
        `${getUrl("cpa_form")}/${form_id}/course`,
        { withCredentials: true }
      );
      setSelectedData(data?.data ?? {});
    } catch (err) {
      setSelectedData({});
    }
  };

  useEffect(() => {
    handleSearch();
    if (apiData?.form_id) {
      getAppliedFormData();
    }
  }, []);

  const handleSearch = async e => {
    try {
      const { data = {} } = await axios.get(
        `${getUrl("colleges")}/${college}/courses`
      );
      setData(data?.data?.courses ?? []);
    } catch (err) {}
  };

  const handleBranchChange = (
    type,
    newValue,
    setFieldValue,
    setFieldTouched,
    course_id
  ) => {
    if (type === "course_id") {
      setFieldValue(type, newValue);
      // setFieldTouched(type, false);
      setFieldValue("branch_id", []);
      // setFieldTouched("branch_id", false);
      return;
    }
    const selectedBranches = data
      .find(item => item.id === course_id)
      ?.branches?.filter(item => newValue.includes(item.id));
    if (selectedBranches?.length > 5)
      return notification.error({
        message: "You can select only 5 branches !"
      });
    setFieldValue(type, selectedBranches);
    // setFieldTouched(type, true);
  };

  const handleBranchPriority = setFieldValue => branches => {
    setFieldValue("branch_id", branches, true);
  };

  const showBranch = id => {
    const selectedCourse = data.find(item => item.id === id);
    return (
      selectedCourse?.branches?.map(item => (
        <Option key={item?.full_name} value={item?.id}>
          {item?.full_name}
        </Option>
      )) ?? []
    );
  };

  const handleVerifyEmail = async key => {
    try {
      const res = await axios.post(
        getUrl("email_verify"),
        {
          email,
          source: "caf"
        },
        { withCredentials: true }
      );
      notification.close(key);
      notification.success({
        message: res?.data?.message ?? "Verify link sent on you mail"
      });
    } catch (err) {
      notification.close(key);
      notification.error({ message: "Something went wrong !" });
    }
  };

  if (!selectedData) return <SpinnerComp />;
  return (
    <React.Fragment>
      {/* <section className="course-instruction">
        <h1>Instructions</h1>
        <Divider />
        <ul>
          Read the application form carefully before you fill it. -
          <li>
            Please use tick marks (√) in appropriate boxes wherever required,
            except for question on academic concentration / major where you need
            to list in numbers. Please re-check the form to ensure you have not
            left any data un-filled.
          </li>
          <li>
            All information you provide will only be used by our Admission
            Department and will remain confidential.{" "}
          </li>
          <li>
            {" "}
            The information contained in this prospectus is of general nature
            for the students seeking admissions in various programmes offered by
            this institution. It is neither an exhaustive nor a legal document.
            Every effort has been made to ensure that the information in this
            Prospectus is correct at the time of compilation.
          </li>
        </ul>
      </section> */}
      <Formik
        initialValues={{
          course_id: selectedData?.course_id ?? undefined,
          branch_id: selectedData?.branches ?? []
        }}
        validationSchema={getSchema()}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, setStatus, setErrors }) => {
          const branches =
            values?.branch_id?.map((item, index) => ({
              ...item,
              priority: index + 1
            })) ?? [];
          try {
            if (!is_email_verified)
              throw {
                message: "Email not verified !",
                email_error: "Click on below button to send verification link"
              };
            setStatus({ success: true });
            setSubmitting(true);
            const { data = {} } = await axios.post(
              form_id
                ? `${getUrl("cpa_form")}/${form_id}/course`
                : getUrl("cpa_form"),
              {
                branches,
                college_admission_id: apiData?.college_admission_id ?? "",
                course_id: values?.course_id,
                college_id: apiData?.college_data?.college_id
              },
              { withCredentials: true }
            );
            setSubmitting(false);
            window.scrollTo(0, 0);
            if (data?.form_id) {
              if (!form_id) setFormIdCallback("", parseInt(data.form_id));
              // notification.success({
              //   message: "Course Details added successfully !",
              // });
              setFormIdCallback("personal");
            } else
              throw {
                message: "Something went Wrong while submitting form data !"
              };
          } catch (e) {
            if (e?.email_error) {
              const key = `open${Date.now()}`;
              const btn = (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleVerifyEmail(key)}
                >
                  Send Link
                </Button>
              );
              notification.error({
                message: e?.message,
                description: e?.email_error,
                btn,
                key
              });
            }
            notification.error({
              message: e?.response?.data?.message ?? "Something went wrong !"
            });
            setErrors({ submit: e?.message });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          handleBlur,
          setFieldTouched
          /* and other goodies */
        }) => (
          <LoadingOverlay
            active={isSubmitting && !Object.keys(errors ?? {}).length}
          >
            {showError && Object.keys(errors ?? {})?.length ? (
              <Alert
                message="Error"
                description={<ThrowError error={errors} />}
                type="error"
                showIcon
                className="alert-error"
                closable
                onClose={() => setShowError(false)}
              />
            ) : null}
            <form
              onSubmit={handleSubmit}
              className="cpa-form cpa-form__wrapper cpa-form__selection-form"
            >
              {/* <section>
                <p>Campus Selected : </p>
                <h1 style={{ textTransform: "capitalize" }}>
                  {apiData?.college_data?.full_name ?? "---"}
                </h1>
              </section> */}
              <FormItem
                label="COURSE"
                validateStatus={
                  touched?.course_id && errors?.course_id
                    ? "error"
                    : values?.course_id
                    ? "success"
                    : null
                }
                help={touched?.course_id && errors?.course_id}
                // hasFeedback={Boolean(touched?.course_id && errors?.course_id)}
              >
                <Select
                  name="course_id"
                  showSearch
                  size="large"
                  value={values.course_id}
                  placeholder="Select Course"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                    0
                  }
                  onChange={newValue =>
                    handleBranchChange(
                      "course_id",
                      newValue,
                      setFieldValue,
                      setFieldTouched
                    )
                  }
                >
                  {data?.length
                    ? data.map(ele => (
                        <Option key={ele.course} value={ele.id}>
                          {ele?.course}
                        </Option>
                      ))
                    : []}
                </Select>
              </FormItem>
              <FormItem
                label="BRANCHES"
                validateStatus={
                  touched?.branch_id && errors?.branch_id
                    ? "error"
                    : values?.branch_id
                    ? "success"
                    : null
                }
                help={touched?.branch_id && errors?.branch_id}
                // hasFeedback={Boolean(touched?.branch_id && errors?.branch_id)}
              >
                <Select
                  name="branch_id"
                  mode="multiple"
                  size="large"
                  value={values.branch_id?.map(element => element.id) ?? []}
                  // value={values?.branch_id ?? []}
                  placeholder="Select Branches"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                    0
                  }
                  onSearch={handleSearch}
                  onChange={newValue =>
                    handleBranchChange(
                      "branch_id",
                      newValue,
                      setFieldValue,
                      setFieldTouched,
                      values?.course_id
                    )
                  }
                  onBlur={newValue =>
                    handleBranchChange(
                      "branch_id",
                      newValue,
                      setFieldValue,
                      setFieldTouched,
                      values?.course_id
                    )
                  }
                  // onBlur={handleBlur}
                >
                  {data?.length && values?.course_id
                    ? showBranch(values.course_id)
                    : []}
                </Select>
              </FormItem>
              <section className="priority-wrapper">
                <SetPriorityWrapper
                  cpa
                  handleDone={handleBranchPriority(setFieldValue)}
                  branches={values?.branch_id ?? []}
                />
              </section>
              {/* <Button
                onClick={handleSubmit}
                className="secondary-btn personal-save-btn"
                disabled={isSubmitting}
              >
                Save & Exit
              </Button> */}
              <section className="grid-3_1fr">
                <Button
                  className="yellow-btn proceed-btn"
                  htmlType="submit"
                  onClick={() => {
                    setShowError(true);
                    if (Object.keys(errors ?? {}).length) {
                      window.scroll({ top: 400, behavior: "smooth" });
                    }
                  }}
                  loading={isSubmitting}
                >
                  Proceed
                </Button>
              </section>
            </form>
          </LoadingOverlay>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default Courseselection;
