import React from "react";
import { useHistory } from "react-router-dom";
// import { Context } from "../../utilities/Context";
// import { notification, Button } from "antd";
// import UpgradeModal from "../PaymentGateway/UpgradeModal/UpgradeModal";

const errorObject = {
  personalDetailIncomplete: {
    title: "Profile Incomplete",
    description:
      "Your Profile is incomplete. Kindly complete your profile before proceeding.",
    button: {
      title: "Complete",
      redirectUrl: "/profile/personal-information"
    }
  },
  academicDetailIncomplete: {
    title: "Academic Information Incomplete",
    description:
      "Your Academic Information is incomplete. Kindly complete your Academic Information before proceeding. Make Sure you upload the required documents.",
    button: {
      title: "Complete",
      redirectUrl: "/profile/academic-information"
    }
  },
  noActivePackage: {
    title: "No Package Selected.",
    description: "Kindly Select your package.",
    button: {
      title: "Select Package",
      redirectUrl: "/profile/upgrade"
    }
  },
  noCreditsLeft: {
    title: "Insufficient Credits.",
    description:
      "You do not seem to have enough credits to apply for this college. Upgrade your credits now.",
    button: {
      title: "Upgrade",
      redirectUrl: "/profile/upgrade"
    }
  }
};

const CollegeSelection = ({ data }) => {
  // const [upgradeModal, setShowUpgradeModal] = useState(false);
  const history = useHistory();
  // const {
  //   is_academic_detail_complete,
  //   is_personal_detail_complete,
  //   forms_left,
  //   active_package_id,
  //   setRedirectUrl
  // } = useContext(Context);
  const { is_applied, logo, apply_url, state, city } = data;

  // const getErrorData = () => {
  //   //handling error cases.
  //   if (!is_personal_detail_complete)
  //     return { ...errorObject.personalDetailIncomplete };

  //   if (!is_academic_detail_complete)
  //     return { ...errorObject.academicDetailIncomplete };

  //   if (!active_package_id) return { ...errorObject.noActivePackage };
  //   if (!forms_left) return { ...errorObject.noCreditsLeft };
  //   return undefined;
  // };

  const handleApplyNow = () => {
    // setRedirectUrl(window.location.pathname);
    history.push(`/${apply_url}`);
  };

  const trackForm = () => {
    // setShowUpgradeModal(!upgradeModal);
    history.push("/profile/applications");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // const redirectToCollege = () => {
  //   setShowUpgradeModal(!upgradeModal);
  //   // history.push(form_url);
  // };
  // const openNotification = errorData => {
  //   const key = `open${Date.now()}`;
  //   const {
  //     title,
  //     description,
  //     button: { title: buttonTitle, redirectUrl }
  //   } = errorData;
  //   const btn = (
  //     <Button
  //       type="primary"
  //       size="default"
  //       onClick={() => {
  //         notification.close(key);
  //         window.scrollTo({ top: 0, behavior: "smooth" });
  //         return history.push(redirectUrl);
  //       }}
  //     >
  //       {buttonTitle}
  //     </Button>
  //   );
  //   notification.error({
  //     message: title,
  //     description,
  //     btn,
  //     key
  //   });
  // };
  return (
    <div className="college-selection-card-parent">
      <div className="college-selection-card">
        <div className="college-selection-card__image">
          <img
            src={logo.display_url}
            alt={logo.alt_text}
            title={logo.title}
            className="college-selection-card__image--logo"
          />
        </div>
        <div className="college-selection-card__content">
          <div className="college-selection-card__content-text">
            <h3 className="college-selection-card__content-text--heading">
              {data.full_name}
            </h3>
            <p className="college-selection-card__content-text--location">
              {`${city}, ${state}`}
            </p>
          </div>

          <button
            className="primary-btn college-selection-card__apply-btn"
            onClick={is_applied ? trackForm : handleApplyNow}
          >
            {is_applied ? "Track Form" : "Apply Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollegeSelection;
