import React, { useState, useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { Form, Input, Select, Row, Col, Button, notification } from "antd";
import axios from "axios";
import _get from "lodash/get";
import UploadDocument from "./UploadDocument";
import ListOfDocuments from "./ListOfDocuments";
import getUrl from "../../utilities/apiConstant";
import { Context } from "../../utilities/Context";

const FormItem = Form.Item;
const { Option } = Select;

const TenthClass = (props) => {
  const { updateContextData } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [edu_id, setEdu_id] = useState(4);
  const [marksType, setMarksType] = useState("%");

  const {
    form: { getFieldDecorator },
    meta,
    data = {},
  } = props;

  const states = meta && meta.states ? meta.states : [];
  const boards = meta && meta.boards ? meta.boards : [];
  const { data: fieldsData = {}, documents = [] } = data;
  const currentYear = new Date().getFullYear();

  const submitTenthClass = async (e) => {
    e.preventDefault();
    const values = await props.form.validateFields();
    try {
      setIsLoading(true);
      const {
        year,
        board,
        state,
        sch_name,
        marks_type,
        marks,
        max_marks,
      } = values;
      const response = await axios.post(
        `${getUrl("schools")}`,
        {
          is_appearing: false,
          year,
          class: 10,
          education_level_id: edu_id,
          school_board_id: board,
          state_id: state,
          school: sch_name,
          marks_type,
          marks,
          maximum_marks: max_marks,
        },
        { withCredentials: true }
      );
      setIsLoading(false);
      updateDataToContext();
      notification.success({
        message: _get(response, "data.message") || "Something went wrong",
      });
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message:
          _get(error.response, "data.response") || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(fieldsData)) {
      handleSetFields();
    }
  }, [fieldsData]);

  const updateDataToContext = async () => {
    try {
      const response = await axios(getUrl("user"), {
        withCredentials: true,
      });
      const data = response && response.data ? response.data.data : {};
      updateContextData(data);
    } catch (error) {
      // notification.error({
      //   message: "Something went wrong"
      // });
    }
  };

  const handleSetFields = () => {
    if (fieldsData.marks_type)
      setMarksType(
        fieldsData.marks_type === "Percentage" ? "%" : fieldsData.marks_type
      );
    props.form.setFieldsValue({
      year: _get(fieldsData, "year") || undefined,
      board: _get(fieldsData, "school_board_id") || undefined,
      state: _get(fieldsData, "state_id") || undefined,
      sch_name: _get(fieldsData, "school") || undefined,
      marks_type: _get(fieldsData, "marks_type") || undefined,
      marks: _get(fieldsData, "marks") || undefined,
      max_marks: _get(fieldsData, "maximum_marks") || undefined,
    });
  };

  const yearSelector = () => {
    let optionArray = [];
    for (let i = currentYear; i >= 1990; i--) {
      let optionData = (
        <Option value={i} key={`k${i}`}>
          {i}
        </Option>
      );
      optionArray.push(optionData);
    }
    return optionArray;
  };

  const handleObtainedMarks = (rule, value, callback) => {
    if (isNaN(value)) {
      callback("!! No. should only be in digits !!");
      return;
    }
    const max_marks = props.form.getFieldValue("max_marks");
    if (parseInt(max_marks) < parseInt(value)) {
      callback(
        `Obtained Marks should be less than Total Marks i.e ${max_marks} !`
      );
      return;
    }
    callback();
  };

  const handleMarksType = (value) => {
    setMarksType(value === "Percentage" ? "%" : value);
    switch (value) {
      case "Percentage":
        props.form.setFieldsValue({
          max_marks: "100",
        });
        break;
      case "CGPA":
        props.form.setFieldsValue({
          max_marks: "10",
        });
        break;
      default:
        break;
    }
  };

  const documentSections =
    documents && documents.length ? (
      <div className="document-section">
        <h3 className="heading-3 document-section__heading">
          Uploaded Documents
        </h3>{" "}
        {documents.map((item, index) => {
          return <ListOfDocuments key={index} edu_id={edu_id} data={item} />;
        })}
      </div>
    ) : null;

  return (
    <Form style={{ overflow: "hidden" }}>
      <Row gutter={16}>
        <p
          style={{
            margin: "0 0 1rem 0.8rem",
            fontSize: "1.6rem",
            color: "#faca45",
            fontWeight: "500",
          }}>
          Class 10:
        </p>

        <Col xs={24} md={8}>
          <FormItem label="Passing Year">
            {getFieldDecorator("year", {
              rules: [{ required: true, message: "Year Required" }],
            })(
              <Select placeholder="Year" showSearch>
                {yearSelector()}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem label="Select Board">
            {getFieldDecorator("board", {
              rules: [{ required: true, message: "Board Required" }],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Board (CBSE/ICSE)">
                {boards.map((item, index) => {
                  return (
                    <Option value={item.id} key={`s${index}`}>
                      {item.value}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col xs={12} md={8}>
          <FormItem label="Select State">
            {getFieldDecorator("state", {
              rules: [{ required: true, message: "State Required" }],
            })(
              <Select
                placeholder="State"
                showSearch
                optionFilterProp="children">
                {states.map((item, index) => {
                  return (
                    <Option value={item.id} key={`s${index}`}>
                      {item.value}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col xs={12} md={8}>
          <FormItem label="Marks Type">
            {getFieldDecorator("marks_type", {
              initialValue: "Percentage",
              rules: [{ required: true, message: "CGPA/Percentage Required" }],
            })(
              <Select
                placeholder="Marks Type (CGPA/Percentage)"
                onChange={handleMarksType}>
                <Option value="CGPA">CGPA</Option>
                <Option value="Percentage">Percentage</Option>
              </Select>
            )}
          </FormItem>
        </Col>
        <Col xs={12} md={8}>
          <FormItem label="Obtained Marks">
            {getFieldDecorator("marks", {
              rules: [
                { required: true, message: "Marks Required" },
                {
                  validator: handleObtainedMarks,
                },
              ],
            })(<Input addonAfter={marksType} placeholder="Marks" />)}
          </FormItem>
        </Col>
        <Col xs={12} md={8}>
          <FormItem label="Total Marks">
            {getFieldDecorator("max_marks", {
              initialValue: "100",
              rules: [{ required: true, message: "Total Marks Required" }],
            })(<Input addonAfter={marksType} placeholder="Total Marks" />)}
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem label="School Name">
            {getFieldDecorator("sch_name", {
              rules: [{ required: true, message: "School Required" }],
            })(<Input placeholder="School Name" />)}
          </FormItem>
        </Col>
      </Row>
      {documents && documents.length ? (
        documentSections
      ) : (
        <UploadDocument edu_id={edu_id} marksheet="Class 10th Marksheet" />
      )}

      <div style={{ textAlign: "right" }}>
        <Button
          onClick={submitTenthClass}
          loading={isLoading}
          type="primary"
          className="primary-btn"
          style={{
            marginBottom: "5rem",
            padding: "0 4rem",
            fontWeight: "500",
          }}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default Form.create()(TenthClass);
