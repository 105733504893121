import React, { useContext } from "react";
import { Steps } from "antd";
import { Context } from "../../utilities/Context";

const { Step } = Steps;

const EducationLevelScreen = ({ data, setEducationId }) => {
  const { setCollegeData, setCurrent } = useContext(Context);
  const educationLevel = data && data.length ? data : [];
  return (
    <>
      <div className="education-level">
        <p className="heading-2 education-level__heading">
          1. Select your{" "}
          <span className="education-level__heading--highlighter">
            Desired Education Level !
          </span>
        </p>
        <div className="education-level__elements">
          {educationLevel.map((item, index) => {
            return (
              <div
                className="education-level__single-element"
                key={index}
                onClick={() => {
                  setCurrent(3);
                  setCollegeData(item, "education");
                  setEducationId(item.id, item.education_level_full_name);
                }}>
                <p className="education-level__single-element--name">
                  {item.education_level_full_name}
                </p>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: "2rem"
          }}>
          <div className="stepper" style={{ width: "50%" }}>
            <Steps current={0} progressDot={true}>
              <Step title="Education Level" className="stepper-selected" />
              <Step title="Stream Level" className="stepper-unselected" />
              <Step title="Select Colleges" className="stepper-unselected" />
            </Steps>
          </div>
        </div>
      </div>
    </>
  );
};

export default EducationLevelScreen;
