import React, { useContext } from "react";
import { Context } from "../../utilities/Context";
const planeImage = require("../../img/Plane.svg");

const HomeScreen = ({ data }) => {
  const { setCurrent } = useContext(Context);
  const handleGetStarted = async () => {
    if (data && data.length) {
      setCurrent(2);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return (
    <div className="home-screen">
      <p className="heading-2 home-screen__description-1">
        Multiple Colleges, Hundreds of Career Options
      </p>
      <p className="heading-2 home-screen__description-2">
        One Online Form - Common for Admission to Various Programs
      </p>
      <p style={{ color: "#ffffff" }}>Save Time & Money</p>
      <img src={planeImage} alt="" className="home-screen__plane" />
      <button
        className="home-screen__btn-get-started"
        onClick={handleGetStarted}>
        Get Started
      </button>
    </div>
  );
};

export default HomeScreen;
