import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import { Spin } from "antd";
import getUrl from "../../utilities/apiConstant";
import CollegeProfile from "./CollegeProfile";
// import MicroModal from "../../components/MicroModal/MicroModal"
const CollegeDetails = (props) => {
  const history = useHistory();
  const {
    location: { pathname },
  } = props;
  const [collegeDetailData, setCollegeDetailData] = useState({});

  useEffect(() => {
    const fetchCollegeDetail = async () => {
      try {
        const response = await axios.get(
          `${getUrl("root_v2")}/caf/colleges${pathname}`,
          { withCredentials: true }
        );
        setCollegeDetailData(response.data.data);
      } catch (error) {
        if (error.response.data.statusCode === 400) {
          history.push("/error");
        }
      }
    };
    fetchCollegeDetail();
  }, [pathname]);

  if (isEmpty(collegeDetailData)) {
    return (
      <div
        style={{
          gridColumn: "1/-1",
          display: "flex",
          alignItems: "center",
          justifySelf: "center",
          height: "100vh",
        }}>
        <Spin />
      </div>
    );
  }

  return <CollegeProfile data={collegeDetailData} location={props.location} />;
};
export default CollegeDetails;
