import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin, Button, notification } from "antd";
import Checkout from "components/Checkout";
import getUrl from "utilities/apiConstant";

const Payment = (props) => {
  const { form_id, setFormIdCallback, match } = props;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    showPaymentResponse();
    fetchData();
  }, []);

  const showPaymentResponse = () => {
    if (window?.location?.href) {
      const params = new URLSearchParams(window.location.search);
      if (params.get("type") && params.get("msg")) {
        if (params.get("type") === "success")
          notification.success({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "fail")
          notification.error({ message: decodeURI(params.get("msg")) });
        else if (params.get("type") === "pending")
          notification.warning({ message: decodeURI(params.get("msg")) });
      } else return;
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${getUrl("root_v2")}/caf/forms/${form_id}/checkout`,
        { withCredentials: true }
      );
      setIsLoading(false);
      setData(response?.data?.data);
    } catch (error) {
      setData({});
      setIsLoading(false);
      notification.error({
        message: error?.message ?? "Something went Wrong !",
      });
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "grid",
          gridColumn: "1/-1",
          height: "100vh",
          placeContent: "center",
        }}
      >
        <Spin />
      </div>
    );
  }
  return (
    <>
      <Checkout data={data} form_id={form_id} />
      <Button
        onClick={() => setFormIdCallback("documents")}
        className="secondary-btn personal-back-btn"
      >
        Back
      </Button>
    </>
  );
};

export default Payment;
