import React from "react";
import { Breadcrumb, Icon } from "antd";
import { withRouter, Link } from "react-router-dom";
const Breadcrumbs = withRouter(props => {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter(data => data);
  const breadcrumbNameMap = {
    "/": "Home",
    "/colleges": "Colleges",
    "/profile": "Profile",
    "/profile/college-selection": "College Selection",
    "/profile/personal-information": "Personal Information",
    "/profile/academic-information": "Academic Information",
    "/profile/applications": "Application Tracking",
    "/profile/upgrade": "Package Upgrade"
  };
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (pathSnippets.length - 1 === index) {
      return (
        <Breadcrumb.Item key={url}>{breadcrumbNameMap[url]}</Breadcrumb.Item>
      );
    } else {
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        </Breadcrumb.Item>
      );
    }
  });
  const breadcrumbItems = [].concat(extraBreadcrumbItems);
  return (
    <div className="profile-layout-breadcrumbs">
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </div>
  );
});
const BreadcrumbsCollegeFilter = withRouter(props => {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter(data => data);
  const breadcrumbNameMap = {
    "/": "Home",
    "/colleges": "Colleges",
    "/profile": "Profile",
    "/profile/college-selection": "College Selection",
    "/profile/personal-information": "Personal Information",
    "/profile/academic-information": "Academic Information",
    "/profile/applications": "Application Tracking"
  };
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (pathSnippets.length - 1 < index) {
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        </Breadcrumb.Item>
      );
    } else {
      return (
        <Breadcrumb.Item key={url}>{breadcrumbNameMap[url]}</Breadcrumb.Item>
      );
    }
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <Icon type="home" style={{ marginRight: ".5rem" }} />
        Home
      </Link>
    </Breadcrumb.Item>
  ].concat(extraBreadcrumbItems);
  return (
    <div className="main-layout-breadcrumbs">
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </div>
  );
});

const BreadcrumbsCollege = withRouter(props => {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter(data => data);
  const breadcrumbNameMap = {};
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    breadcrumbNameMap[url] = url
      .slice(1)
      .split("-")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1) + " ");
    if (pathSnippets.length - 1 < index) {
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        </Breadcrumb.Item>
      );
    } else {
      return (
        <Breadcrumb.Item key={url}>{breadcrumbNameMap[url]}</Breadcrumb.Item>
      );
    }
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <Icon type="home" style={{ marginRight: ".5rem" }} />
        Home
      </Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="college">
      <Link to="/colleges">Colleges</Link>
    </Breadcrumb.Item>
  ].concat(extraBreadcrumbItems);
  return (
    <div className="college-profile-breadcrumb">
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </div>
  );
});
export default Breadcrumbs;
export { BreadcrumbsCollegeFilter, BreadcrumbsCollege };
