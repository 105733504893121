import React, { useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { Radio, Button, notification, Alert } from "antd";
import axios from "axios";
import getUrl from "utilities/apiConstant";
// import debounce from "lodash/debounce";
import cleanData from "./cleanData";
import { getPersonalSchema, getInitialValues } from "./Personal/personalSchema";
import PersonalDetails from "./Personal/PersonalDetails";
import Parent from "./Personal/Parent";
import Address from "./Personal/Address";
import { SpinnerComp } from "components/SpinnerComp";
import LoadingOverlay from "components/LoadingOverlay";
import ThrowError from "./ThrowError";

function Personalinfo({
  data,
  form_id,
  setFormIdCallback,
  match,
  academicPersonalData,
}) {
  const [showError, setShowError] = useState(false);
  const [personalData, setPersonalData] = useState(undefined);
  useEffect(() => {
    getPersonalData();
  }, []);

  const getPersonalData = async () => {
    try {
      const { data = {} } = await axios.get(
        `${getUrl("cpa_form")}/${form_id}/personal`,
        {
          withCredentials: true,
        }
      );
      setPersonalData(data?.data ?? {});
    } catch (err) {
      notification.error({ message: err?.message ?? "Something went Wrong !" });
      setPersonalData({});
    }
  };

  const handleAddress = (e, setFieldValue, values) => {
    setFieldValue("both_address_same", e.target.value, true);
    if (e.target.value)
      setFieldValue("permanent_address", values.current_address, true);
    else
      setFieldValue(
        "permanent_address",
        getInitialValues().permanent_address,
        false
      );
  };
  if (!personalData) return <SpinnerComp />;
  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(personalData)}
      validationSchema={getPersonalSchema()}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          const value = cleanData(values);
          if (Object.keys(values?.dob ?? {})?.length) value.dob = values.dob;
          await axios.post(`${getUrl("cpa_form")}/${form_id}/personal`, value, {
            withCredentials: true,
          });
          // notification.success({
          //   message:
          //     res?.data?.message ?? "Personal Details added successfully !",
          // });
          window.scrollTo(0, 0);
          setFormIdCallback("academic");
          setSubmitting(false);
        } catch (err) {
          notification.error({
            message: err?.response?.data?.message ?? "Something went wrong !",
          });
          setSubmitting(false);
        }
      }}
    >
      {(formik_props) => (
        <LoadingOverlay
          active={
            formik_props.isSubmitting &&
            !Object.keys(formik_props?.errors ?? {}).length
          }
        >
          {showError && Object.keys(formik_props?.errors ?? {})?.length ? (
            <Alert
              message="Error"
              description={<ThrowError error={formik_props?.errors} />}
              type="error"
              showIcon
              className="alert-error"
              closable
              onClose={() => setShowError(false)}
            />
          ) : null}
          <form
            onSubmit={formik_props?.handleSubmit}
            className="cpa-form cpa-form__wrapper"
          >
            <PersonalDetails
              type="student"
              {...formik_props}
              meta={academicPersonalData}
            />
            <h1
              className="heading-3"
              style={{ gridColumn: "1/-1", color: "#3c1a5b" }}
            >
              Address Details
            </h1>
            <Address
              type="current_address"
              {...formik_props}
              meta={academicPersonalData}
            />
            <p style={{ gridColumn: "1/-1", color: "#3c1a5b" }}>
              Is Permanent Address same as Current Address ?
            </p>
            <Radio.Group
              name="both_address_same"
              value={formik_props?.values?.both_address_same ?? false}
              onChange={(e) =>
                handleAddress(
                  e,
                  formik_props?.setFieldValue,
                  formik_props?.values
                )
              }
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
            {formik_props?.values?.both_address_same ? null : (
              <Address
                type="permanent_address"
                {...formik_props}
                meta={academicPersonalData}
              />
            )}
            <Parent type="father" {...formik_props} />
            <Parent type="mother" {...formik_props} />
            {/* <Parent type="guardian" {...formik_props} /> */}
            <section className="back-next-btn-holder">
              <Button
                className="secondary-btn personal-back-btn"
                disabled={formik_props?.isSubmitting}
                onClick={() => setFormIdCallback("course")}
              >
                Back
              </Button>
              <Button
                className="yellow-btn academic-button-wrapper__2"
                loading={formik_props?.isSubmitting}
                htmlType="submit"
                onClick={() => {
                  setShowError(true);
                  if (Object.keys(formik_props?.errors ?? {}).length) {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }
                }}
              >
                Proceed
              </Button>

              {/* <Button
                className="secondary-btn personal-save-btn"
                onClick={formik_props?.handleSubmit}
                disabled={formik_props?.isSubmitting}
              >
                Save & Exit
              </Button> */}
            </section>
          </form>
        </LoadingOverlay>
      )}
    </Formik>
  );
}

export default Personalinfo;
