import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { enquireScreen } from "enquire-js";
import { Icon } from "antd";
import NavigationMenu from "./NavigationMenu/NavigationMenu";
import MenuIcon from "./NavigationMenu/MenuIcon";
import MobileNav from "./NavigationMenu/MobileNav";
import CAFAuthenticationModal from "./Login/CAFAuthenticationModal";
import { Context } from "../utilities/Context";
const logo = require("../img/Logo.svg");

const Navigation = () => {
  const { isLogin, setCurrent } = useContext(Context);
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleMenuIcon = value => {
    setShowMenuIcon(value);
  };

  useEffect(() => {
    enquireScreen(status => setIsMobile(status));
  }, []);

  return (
    <div className="navigationWrapper background-image">
      <div className="navigation">
        {!isMobile ? (
          <img
            onClick={() => {
              history.push("/");
              setCurrent(1);
            }}
            src={logo}
            alt="entrancezone-logo"
            className="navigation__logo-image"
            style={{ cursor: "pointer" }}
          />
        ) : null}

        {!isMobile ? (
          <NavigationMenu />
        ) : (
          <MenuIcon
            menuIcon={showMenuIcon}
            fuc_handleMenuIcon={handleMenuIcon}
          />
        )}
        {isMobile ? (
          <>
            <img
              onClick={() => {
                history.push("/");
                setCurrent(1);
              }}
              src={logo}
              alt="entrancezone-logo"
              className="navigation__logo-image"
              style={{ cursor: "pointer" }}
            />
            {isLogin() ? (
              <Icon
                type="user"
                onClick={() => history.push("/profile")}
                style={{ fontSize: "2.5rem", color: "white" }}
              />
            ) : (
              <button
                className="auth-button"
                onClick={() => setShowModal(true)}>
                Login
              </button>
            )}
          </>
        ) : null}
      </div>
      <MobileNav
        fuc_handleMenuIcon={handleMenuIcon}
        showDrawer={showMenuIcon}
      />
      <CAFAuthenticationModal
        showAuthModal={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default Navigation;
