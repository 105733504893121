import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import CAFLogin from "./CAFLogin";
import CAFSignUp from "./CAFSignUp";

export default function AuthenticationModal({
  showAuthModal,
  onClose,
  onProceed,
}) {
  const screenTypeEnum = Object.freeze({
    login: "login",
    register: "register",
  });

  const [screenType, setScreenType] = useState(screenTypeEnum.login);
  const [showModal, setShowModal] = useState(showAuthModal || false);

  useEffect(() => {
    setShowModal(showAuthModal);
  }, [showAuthModal]);

  return (
    <React.Fragment>
      <Modal
        className="authmodal"
        centered
        width={600}
        bodyStyle={{ padding: "0" }}
        visible={showModal}
        onCancel={() => {
          onClose();
          setScreenType(screenTypeEnum.login);
        }}
        footer={null}
        maskClosable={false}>
        {screenType === screenTypeEnum.login ? (
          <CAFLogin
            showRegisterScreen={() => setScreenType(screenTypeEnum.register)}
            onCloseModal={onClose}
            onProceed={onProceed}
          />
        ) : (
          <CAFSignUp
            onCloseModal={onClose}
            showLoginScreen={() => setScreenType(screenTypeEnum.login)}
            onProceed={onProceed}
          />
        )}
      </Modal>
    </React.Fragment>
  );
}
