import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Divider, notification, Button, Radio, Form, Alert } from "antd";
import TenthTwelfthClass from "./Academic/TenthTwelfth";
import Graduation from "./Academic/Graduation";
import getUrl from "utilities/apiConstant";
import { SpinnerComp } from "components/SpinnerComp";
import LoadingOverlay from "components/LoadingOverlay";
import ThrowError from "./ThrowError";

const educationLevelId = {
  tenth: 4,
  twelfth: 5,
  diploma: 6,
  ug: 1,
  pg: 2,
  phd: 3,
};

function AcademicDetail({ form_id, setFormIdCallback, academicPersonalData }) {
  const [data, setData] = useState(undefined);
  const [showError, setShowError] = useState(false);
  const [intialFetchError, setInitialFetchError] = useState(undefined);

  useEffect(() => {
    getAcademicData();
  }, []);

  const education_level_mapping = {
    "10th": "tenth",
    "12th": "twelfth",
    DIPLOMA: "diploma",
    UG: "ug",
    PG: "pg",
    "Ph.D": "phd",
  };

  // =================================================================================================
  // API CALLS  ======================================================================================
  // =================================================================================================
  const getAcademicData = async () => {
    try {
      const response = await axios.get(
        `${getUrl("cpa_form")}/${form_id}/academic`,
        {
          withCredentials: true,
        }
      );
      setData(response?.data?.data ?? {});
    } catch (err) {
      setInitialFetchError(err);
      notification.error({ message: "Something went Wrong" });
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    //education_level and class to be handled
    const dataToSend = [];
    for (let key in values) {
      // if (values?.[key]) dataToSend.push(values?.[key]);
      switch (key) {
        case "tenth":
        case "twelfth": {
          const data = values[key];
          data.class = key === "tenth" ? 10 : 12;
          data.education_level_id = educationLevelId[key];
          dataToSend.push(data);
          break;
        }
        case "diploma":
        case "ug":
        case "pg":
        case "phd": {
          const data = values[key];
          data.education_level_id = educationLevelId[key];
          dataToSend.push(data);
          break;
        }
        default:
          break;
      }
    }
    try {
      setSubmitting(true);
      await axios.post(
        `${getUrl("cpa_form")}/${form_id}/academic`,
        dataToSend,
        {
          withCredentials: true,
        }
      );
      // notification.success({
      //   message: res?.data?.message ?? "Academic Details added successfully !"
      // });
      window.scrollTo(0, 0);
      setFormIdCallback("documents");
      setSubmitting(false);
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message ?? "Something went wrong !",
      });
      setSubmitting(false);
    }
  };

  // =================================================================================================
  // INITIAL VALUES AND SCHEMAS  =====================================================================
  // =================================================================================================

  const getFieldInitialValues = (data, education_level) => {
    switch (education_level) {
      case "diploma":
      case "ug":
      case "pg":
      case "phd":
        return {
          marks:
            data?.is_appearing === false && data?.marks
              ? data?.marks
              : undefined,
          maximum_marks:
            data?.is_appearing === false && data?.maximum_marks
              ? data?.maximum_marks
              : undefined,
          state_id: data?.state_id,
          year: data?.year,
          marks_type:
            data?.is_appearing === false && data?.marks_type
              ? data?.marks_type
              : undefined,
          college_name: data?.college_name,
          // stream: data?.stream,
          is_appearing: data?.is_appearing,
          course_name: data?.course_name,
        };
      case "tenth":
      case "twelfth":
        return {
          is_appearing:
            education_level === "tenth" ? false : data?.is_appearing,
          marks:
            data?.is_appearing === false && data?.marks
              ? data?.marks
              : undefined,
          maximum_marks:
            data?.is_appearing === false && data?.maximum_marks
              ? data?.maximum_marks
              : undefined,
          state_id: data?.state_id ?? undefined,
          year: data?.year,
          marks_type:
            data?.is_appearing === false && data?.marks_type
              ? data?.marks_type
              : undefined,
          school_board: data?.school_board,
          school: data?.school,
          stream: data?.stream ?? undefined,
        };
      default:
        return {};
    }
  };

  const getInitialValues = () => {
    //get form education level id.
    const {
      form_education_level_id = 1, //1 is the education level id for UG
      academic_data,
      education_level_stage,
    } = data;
    //Get what all stages are required.
    const stage = education_level_stage?.find(
      (item) => form_education_level_id === item.education_level_id
    )?.stage;

    //Use the stage value to get all the levels required before the stage values.
    //UG => stage = 3 so required stages would be 1 and 2 i.e education level will be secondary and senior secondary/diploma.
    const initialData = {};

    const allLevelsBeforeStage = education_level_stage?.filter(
      (item) => item.stage < stage
    );

    const _ = allLevelsBeforeStage?.forEach((education_level) => {
      const data = academic_data?.find(
        (item) => item.education_level_id === education_level.education_level_id
      );
      const education_level_key =
        education_level_mapping[education_level.short_name];
      const initialFieldValue = getFieldInitialValues(
        data,
        education_level_key
      );
      initialData[education_level_key] = initialFieldValue;
    });

    //after 10th qualification
    if (
      initialData.diploma &&
      Object.keys(initialData.diploma).length &&
      initialData.twelfth &&
      Object.keys(initialData.twelfth).length
    ) {
      initialData.qualification_after_tenth = "both";
    } else if (initialData.diploma && Object.keys(initialData.diploma).length) {
      initialData.qualification_after_tenth = "diploma";
    } else if (initialData.twelfth && Object.keys(initialData.twelfth).length) {
      initialData.qualification_after_tenth = "twelfth";
    } else {
      initialData.qualification_after_tenth = undefined;
    }
    return initialData;
  };

  const getFieldSchema = (education_level) => {
    switch (education_level) {
      case "tenth": {
        return Yup.object().shape({
          is_appearing: Yup.boolean().required("Appearing Status is Required"),
          marks_type: Yup.string().when("is_appearing", {
            is: false,
            then: Yup.string()
              .oneOf(["CGPA", "Percentage"])
              .required("Marking Scheme is Required"),
          }),
          marks: Yup.number().when("is_appearing", {
            is: false,
            then: Yup.number()
              .positive()
              .required("Marks is Required")
              .when("marks_type", {
                is: "CGPA",
                then: Yup.number()
                  .max(3.3, "Minimum Required value is 3.3")
                  .max(10, "Maximum Required value is 10"),
              })
              .when("marks_type", {
                is: "Percentage",
                then: Yup.number()
                  .max(33, "Minimum Required value is 33")
                  .max(100, "Maximum Required value is 100"),
              }),
          }),
          state_id: Yup.string().max(255).required("State is Required"),
          year: Yup.number().required("Passing Year is Required"),
          school_board: Yup.string()
            .max(255)
            .required("Board is Required")
            .nullable(),
          school: Yup.string().max(255).required("School is Required"),
        });
      }
      case "twelfth": {
        return Yup.object().shape({
          is_appearing: Yup.boolean().required("Appearing Status is Required"),
          marks_type: Yup.string().when("is_appearing", {
            is: false,
            then: Yup.string()
              .oneOf(["CGPA", "Percentage"])
              .required("Marking Scheme is Required"),
          }),
          marks: Yup.number().when("is_appearing", {
            is: false,
            then: Yup.number()
              .positive()
              .required("Marks is Required")
              .when("marks_type", {
                is: "CGPA",
                then: Yup.number()
                  .max(3.3, "Minimum Required value is 3.3")
                  .max(10, "Maximum Required value is 10"),
              })
              .when("marks_type", {
                is: "Percentage",
                then: Yup.number()
                  .max(33, "Minimum Required value is 33")
                  .max(100, "Maximum Required value is 100"),
              }),
          }),
          state_id: Yup.string().max(255).required("State is Required"),
          year: Yup.number().required("Appearing/Passing Year is Required"),
          stream: Yup.string().required("Stream is required"),
          school_board: Yup.string()
            .max(255)
            .required("Board is Required")
            .nullable(),
          school: Yup.string().max(255).required("School is Required"),
        });
      }
      case "diploma":
      case "ug":
      case "pg":
      case "phd": {
        return Yup.object().shape({
          is_appearing: Yup.boolean().required("Appearing Status is Required"),
          state_id: Yup.string().max(255).required("State is Required"),
          year: Yup.number()
            .positive()
            .required("Appearing/Passing Year is Required"),
          marks_type: Yup.string().when("is_appearing", {
            is: false,
            then: Yup.string()
              .oneOf(["CGPA", "Percentage"])
              .required("Marking Scheme is Required"),
          }),
          marks: Yup.number().when("is_appearing", {
            is: false,
            then: Yup.number()
              .positive()
              .required("Marks is Required")
              .when("marks_type", {
                is: "CGPA",
                then: Yup.number()
                  .max(3.3, "Minimum Required value is 3.3")
                  .max(10, "Maximum Required value is 10"),
              })
              .when("marks_type", {
                is: "Percentage",
                then: Yup.number()
                  .max(33, "Minimum Required value is 33")
                  .max(100, "Maximum Required value is 100"),
              }),
          }),
          college_name: Yup.string().max(255).required("College is Required"),
          course_name: Yup.string().required("Course is Required"),
        });
      }
      default:
        return;
    }
  };

  const getSchemaObject = () => {
    const {
      form_education_level_id = 1, //1 is the education level id for UG
      education_level_stage,
    } = data;
    const stage = education_level_stage?.find(
      (item) => form_education_level_id === item.education_level_id
    )?.stage;

    //Use the stage value to get all the levels required before the stage values.
    //UG => stage = 3 so required stages would be 1 and 2 i.e education level will be secondary and senior secondary/diploma.
    const initialData = {
      qualification_after_tenth: Yup.string()
        .oneOf(["twelfth", "diploma", "both"])
        .required("Please Select Qualification after 10th"),
    };

    const allLevelsBeforeStage = education_level_stage?.filter(
      (item) => item.stage < stage
    );

    allLevelsBeforeStage.forEach((item) => {
      const education_level_key = education_level_mapping[item.short_name];
      switch (education_level_key) {
        case "tenth": {
          initialData.tenth = getFieldSchema("tenth");
          return;
        }
        case "twelfth": {
          initialData.twelfth = Yup.object().when(
            "qualification_after_tenth",
            (value) => {
              if (["twelfth", "both"].includes(value)) {
                return getFieldSchema("twelfth");
              } else {
                return Yup.object().notRequired();
              }
            }
          );
          return;
        }
        case "diploma": {
          initialData.diploma = Yup.object().when(
            "qualification_after_tenth",
            (value) => {
              if (["diploma", "both"].includes(value)) {
                return getFieldSchema("diploma");
              } else {
                return Yup.object().notRequired();
              }
            }
          );
          return;
        }
        case "ug":
        case "pg":
        case "phd": {
          initialData[education_level_key] = getFieldSchema(
            education_level_key
          );
          return;
        }
        default:
          return;
      }
    });
    return Yup.object().shape({ ...initialData });
  };

  // const getSchema = () => {
  //   return Yup.object().shape({
  //     qualification_after_tenth: Yup.string()
  //       .oneOf(["twelfth", "diploma", "both"])
  //       .required("Please Select Qualification after 10th"),
  //     tenth: getFieldSchema("tenth"),
  //     twelfth: Yup.object().when("qualification_after_tenth", value => {
  //       if (["twelfth", "both"].includes(value)) {
  //         return getFieldSchema("twelfth");
  //       } else {
  //         return Yup.object().notRequired();
  //       }
  //     }),
  //     diploma: Yup.object().when("qualification_after_tenth", value => {
  //       if (["diploma", "both"].includes(value)) {
  //         return getFieldSchema("diploma");
  //       } else {
  //         return Yup.object().notRequired();
  //       }
  //     })
  //   });
  // };

  const setSeniorSecondaryLevel = (e, formik) => {
    //get initial value based on the education level.
    formik.setFieldValue("qualification_after_tenth", e.target.value);
    switch (e.target.value) {
      case "twelfth": {
        const data = getFieldInitialValues(formik?.values?.twelfth, "twelfth");
        formik.setFieldValue("twelfth", data, false);
        formik.setFieldValue("diploma", undefined, false);
        return;
      }
      case "diploma": {
        const data = getFieldInitialValues(formik?.values?.diploma, "diploma");
        formik.setFieldValue("diploma", data, false);
        formik.setFieldValue("twelfth", undefined, false);
        return;
      }
      case "both": {
        const twelfthData = getFieldInitialValues(
          formik?.values?.twelfth,
          "twelfth"
        );
        const diplomaData = getFieldInitialValues(
          formik?.values?.diploma,
          "diploma"
        );

        formik.setFieldValue("twelfth", twelfthData, false);
        formik.setFieldValue("diploma", diplomaData, false);
        return;
      }
      default:
        return;
    }
  };

  const handleSeniorSecondaryLevel = (formik, qualification_after_tenth) => {
    switch (qualification_after_tenth) {
      case "twelfth":
        return (
          <TenthTwelfthClass
            type="twelfth"
            meta={academicPersonalData}
            {...formik}
          />
        );
      case "diploma":
        return (
          <Graduation
            graduationType="diploma"
            {...formik}
            meta={academicPersonalData}
          />
        );
      case "both":
        return (
          <>
            <TenthTwelfthClass
              type="twelfth"
              meta={academicPersonalData}
              {...formik}
            />
            <Divider key="divider" />,
            <Graduation
              graduationType="diploma"
              {...formik}
              meta={academicPersonalData}
            />
          </>
        );
      default:
        return null;
    }
  };

  const handleGraduationLevel = (formik) => (
    //Check for Availability Of UG.
    <>
      {formik.values.ug ? (
        <Graduation
          key="ug"
          graduationType="ug"
          {...formik}
          meta={academicPersonalData}
        />
      ) : null}

      {formik.values.pg ? (
        <>
          <Divider key="divider" />,
          <Graduation
            key="pg"
            graduationType="pg"
            {...formik}
            meta={academicPersonalData}
          />
        </>
      ) : null}
      {formik.values.phd ? (
        <>
          <Divider key="divider" />,
          <Graduation
            key="phd"
            graduationType="phd"
            {...formik}
            meta={academicPersonalData}
          />
        </>
      ) : null}
    </>
  );

  // =================================================================================================
  // LAYOUT RENDERING  ===============================================================================
  // =================================================================================================
  if (!data) return <SpinnerComp />;

  if (intialFetchError)
    return <p>Could Not Load Data. Please try again later.</p>;

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={getSchemaObject()}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <LoadingOverlay
          active={
            formik.isSubmitting && !Object.keys(formik?.errors ?? {}).length
          }
        >
          {" "}
          {showError && Object.keys(formik?.errors ?? {})?.length ? (
            <Alert
              message="Error"
              description={<ThrowError error={formik?.errors} />}
              type="error"
              showIcon
              className="alert-error"
              closable
              onClose={() => setShowError(false)}
            />
          ) : null}
          <Form onSubmit={formik?.handleSubmit} className="cpa-form">
            <TenthTwelfthClass
              type="tenth"
              meta={academicPersonalData}
              {...formik}
            />
            <Divider />

            <div style={{ marginBottom: "5rem" }}>
              <Form.Item
                name="qualification_after_tenth"
                label="Qualification After 10th"
                validateStatus={
                  formik.touched.qualification_after_tenth &&
                  formik.errors.qualification_after_tenth
                    ? "error"
                    : null
                }
                help={
                  formik.touched.qualification_after_tenth &&
                  formik.errors.qualification_after_tenth
                }
                required
              >
                <Radio.Group
                  onChange={(e) => setSeniorSecondaryLevel(e, formik)}
                  value={formik.values.qualification_after_tenth}
                >
                  <Radio value="twelfth">Twelfth</Radio>
                  <Radio value="diploma">Diploma</Radio>
                  <Radio value="both">Both</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            {handleSeniorSecondaryLevel(
              formik,
              formik.values.qualification_after_tenth
            )}
            <Divider />
            {handleGraduationLevel(formik)}
            <div
              className="academic-button-wrapper"
              style={{ margin: "3rem 0" }}
            >
              <div className="academic-button-wrapper__1">
                <Button
                  disabled={formik?.isSubmitting}
                  onClick={() => setFormIdCallback("personal")}
                  className="secondary-btn personal-back-btn"
                >
                  Back
                </Button>
              </div>
              <Button
                className="yellow-btn academic-button-wrapper__2"
                htmlType="submit"
                onClick={() => {
                  setShowError(true);
                  if (Object.keys(formik?.errors ?? {}).length) {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }
                }}
                loading={formik.isSubmitting}
              >
                Proceed
              </Button>
            </div>
          </Form>
        </LoadingOverlay>
      )}
    </Formik>
  );
}

export default AcademicDetail;
