import React from "react";
const Facility = ({ facility }) => {
  return (
    <div className="facility-main-div">
      <span
        className={`icon-${facility.icon_path}`}
        style={{ fontSize: "4rem" }}>
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="path4" />
        <span className="path5" />
        <span className="path6" />
        <span className="path7" />
        <span className="path8" />
        <span className="path9" />
        <span className="path10" />
        <span className="path11" />
      </span>
      <div className="facility-name-div" style={{ textAlign: "center" }}>
        {facility.name}
      </div>
    </div>
  );
};
export default Facility;
