import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import ShortlistItems from "./ShortlistItems";
import UpgradeButton from "./UpgradeButton";
import AuthButton from "../Login/AuthButton";
import { Context } from "../../utilities/Context";

const commonStyle = {
  cursor: "pointer",
  fontFamily: "Montserrat",
  fontSize: "1.6rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.33",
  letterSpacing: "0.4px",
  textAlign: "left",
  color: "#ffffff",
};

const NavigationMenu = () => {
  const history = useHistory();
  const { isLogin } = useContext(Context);
  return (
    <div className="navigation-menu">
      <>
        <div
          className="navigation-menu__features"
          onClick={() => history.push("/colleges")}>
          Colleges
        </div>
        {/* <div
          className="navigation-menu__features"
          onClick={() => history.push("/pricing")}
        >
          Pricing
        </div> */}
        {/* {isLogin() ? <ShortlistItems /> : null} */}
        {isLogin() ? (
          <div
            className="navigation-menu__features"
            onClick={() => history.push("/profile")}>
            Dashboard{" "}
          </div>
        ) : null}
        {/* <UpgradeButton /> */}
      </>

      {/* <button className="navbar-upgrade-button">Upgrade</button> */}
      <AuthButton />
    </div>
  );
};

export default NavigationMenu;
