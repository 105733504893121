import React from "react";
import moment from "moment";
import { Icon } from "antd";

const CounselorSection = ({ data = {}, handleCounselorSection }) => {
  const {
    id: application_number,
    college_name,
    logo = {},
    requested_branches: branches = [],
    course,
    applied_date,
    college_fee = { total_fees: "-" },
    message = "No Message Found....!",
    action_required = "None",
    college_address = {},
    counsellor = { name: "No Counsellor Assigned", email: "" }
  } = data;
  const { city, state } = college_address;
  const { name, email } = counsellor;
  const { total_fees } = college_fee;
  const allbranches = branches
    ? branches.map((item, index) => (
        <p key={index} className="branch-name-para">
          {` ${index + 1}. ${item.full_name}`}
        </p>
      ))
    : "";
  return (
    <div>
      <div>
        <div className="admission-overview-div">
          <h2 className="application-preview-h2">Admission Overview</h2>
          <div className="application-number-date-div">
            <div style={{ display: "flex" }}>
              <p className="application-preview-date-para">Date :</p>
              <p className="application-preview-date-para-result">
                {moment(applied_date).format("DD-MM-YYYY")}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p className="application-preview-date-para">
                Application Number :
              </p>
              <p className="application-preview-date-para-result">
                {application_number}
              </p>
            </div>
          </div>
        </div>
        <div className="application-preview-grid-container">
          <div className="grid-item-1">
            <img
              alt={logo.alt_text}
              src={logo.display_url}
              style={{ height: "8rem", borderRadius: "50%" }}
            />
            <h3 className="college-name-h3-application-preview">
              {college_name}
            </h3>
            <div className="address-div-application-preview">
              <Icon
                type="environment"
                theme="filled"
                style={{ margin: "0 1rem", fontSize: "1.5rem" }}
              />
              <p className="address-para">{`${city},${state}`}</p>
            </div>
          </div>
          <div className="grid-item-2">
            <div style={{ display: "flex" }}>
              <Icon
                type="file-text"
                className="application-review-icons"
                style={{
                  marginRight: "1rem",
                  fontSize: "2rem",
                  fill: "#3c1a5b"
                }}
              />
              <p className="branches-applied-para">Branches Applied</p>
            </div>
            <div style={{ marginLeft: "3rem", marginBottom: "1.8rem" }}>
              {allbranches}
            </div>
          </div>
          <div className="grid-item-3">
            {" "}
            <div style={{ display: "flex" }}>
              <Icon
                className="application-review-icons"
                type="book"
                style={{ marginRight: "1rem", fontSize: "2rem" }}
              />
              <p className="branches-applied-para">Course Applied</p>
            </div>
            <div style={{ marginLeft: "3rem", marginBottom: "1.8rem" }}>
              <p className="branch-name-para">{course}</p>
            </div>{" "}
            <div style={{ display: "flex" }}>
              <Icon
                className="application-review-icons"
                type="dollar"
                style={{ marginRight: "1rem", fontSize: "2rem" }}
              />
              <p className="branches-applied-para">Fee</p>
            </div>
            <div style={{ marginLeft: "3rem", marginBottom: "1.8rem" }}>
              <p className="branch-name-para">{total_fees}</p>
            </div>
          </div>
          {/* <div className="grid-item-4">
            {" "}
            <div style={{ display: "flex" }}>
              <Icon
                className="application-review-icons"
                type="user"
                style={{ marginRight: "1rem", fontSize: "2rem" }}
              />
              <p className="branches-applied-para">Counsellor Name</p>
            </div>
            <div style={{ marginLeft: "3rem", marginBottom: "1.8rem" }}>
              <p className="branch-name-para">{name}</p>
              <p className="branch-name-para">{email}</p>
            </div>
          </div> */}
          {/* <div className="grid-item-5">
            <div style={{ display: "flex" }}>
              <Icon
                className="application-review-icons"
                type="message"
                style={{ marginRight: "1rem", fontSize: "2rem" }}
              />
              <p className="branches-applied-para">Messages</p>
            </div>
            <div style={{ marginLeft: "3rem", marginBottom: "1.8rem" }}>
              <p className="branch-name-para">{message}</p>
            </div>
        </div>*/}
          {/* <div className="grid-item-6">
            <div style={{ display: "flex" }}>
              <Icon
                className="application-review-icons"
                type="laptop"
                style={{ marginRight: "1rem", fontSize: "2rem" }}
              />
              <p className="branches-applied-para">Action Required</p>
            </div>
            <div style={{ marginLeft: "3rem", marginBottom: "1.8rem" }}>
              <p className="branch-name-para">{action_required}</p>
            </div>
      </div> */}
        </div>
      </div>
      <button
        className="back-button back-button-application-preview"
        onClick={() => handleCounselorSection(false, {})}
      >
        <Icon
          type="left"
          style={{
            fontSize: "1.5rem",
            marginRight: "1rem"
          }}
        />
        Back
      </button>
    </div>
  );
};

export default CounselorSection;
