import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import moment from "moment";

const ApplicationCard = ({ data, handleCounselorSection }) => {
  const history = useHistory();
  const { requested_branches = [], editable_form_url, is_editable } = data;

  const handleEdit = () => {
    history.push(editable_form_url);
  };
  return (
    <>
      <div className="application-cards">
        <div className="application-card-header">
          <div className="application-card-header__content">
            <p className="application-card-header__content--heading">
              Date Applied
            </p>
            <p className="application-card-header__content--text">
              {moment(data.applied_date).format("DD-MM-YYYY")}
            </p>
          </div>
          <div className="application-card-header__content">
            <p className="application-card-header__content--heading">
              Last Activity
            </p>
            <p className="application-card-header__content--text">
              {moment(data.applied_date).format("DD-MM-YYYY")}
            </p>
          </div>
          <div className="application-card-header__content">
            <p className="application-card-header__content--heading">Status</p>
            <p className="application-card-header__content--text">
              {data.status}
            </p>
          </div>
          <div className="application-card-header__content">
            <p className="application-card-header__content--heading">
              Application ID
            </p>
            <p className="application-card-header__content--text">{`#${data.id}`}</p>
          </div>
        </div>
        <div className="application-card-content">
          <img
            src={data.logo.display_url}
            alt={data.logo.alt_text}
            title={data.logo.title}
            className="application-card-content__logo"
          />
          <div>
            <h3 className="application-card-content__heading">
              {data.college_name}
            </h3>
            <Row
              gutter={16}
              className="application-card-content__blocks"
              type="flex"
              align="middle">
              <Col xs={24} md={12} className="application-card-content__block">
                <p className="application-card-content__block--heading">
                  Branches Selected:
                </p>
                <p className="application-card-content__block--text">
                  {requested_branches && requested_branches.length
                    ? requested_branches.map((data, index) => {
                        if (index === requested_branches.length - 1) {
                          return `${data.full_name}`;
                        } else {
                          return `${data.full_name}, `;
                        }
                      })
                    : null}
                </p>
              </Col>
              <Col xs={24} md={6} className="application-card-content__block">
                <p className="application-card-content__block--heading">
                  Course Applied:
                </p>
                <p className="application-card-content__block--text">
                  {data.course}
                </p>
              </Col>
              <Col xs={24} md={3}>
                <button
                  className="application-card-content__btn"
                  onClick={() => handleCounselorSection(true, data)}>
                  View
                </button>
              </Col>
              <Col xs={24} md={3} className="edit-button-div-application-card">
                {is_editable ? (
                  <button
                    className="application-card-content__btn"
                    onClick={handleEdit}>
                    Edit
                  </button>
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationCard;
