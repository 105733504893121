const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const baseApi = `${baseUrl}/api`;
const storageUrl = process.env.REACT_APP_STORAGE_URL;

//All the endpoints goes here.
const apiConstants = Object.freeze({
  colleges: "/v2/caf/colleges",
  collegeFilters: "/v2/caf/landing/colleges",
  pageFilter: "/v1/caf/landing/page-filters",
  shortlist: "/v1/caf/user/shortlist",
  applicationForm: "/v1/caf/user/application-form",
  schools: "/v1/caf/user/update/school",
  collegeInfo: "/v1/caf/user/update/college",
  root: "/v1",
  root_v2: "/v2",
  user: "/v1/caf/user",
  profile: "/v1/caf/user/profile",
  authSignin: "/v1/auth/email/signin",
  authSignup: "/v1/auth/email/signup",
  authForgotPassword: "/v1/auth/email/forgot-password",
  authFacebook: "/v1/auth/facebook",
  authGoogle: "/v1/auth/google",
  authPhone: "/v1/auth/phone",
  uploads: "/v1/caf/user/uploads",
  payment: "/v1/caf/user/payment",
  packages: "/v1/caf/packages",
  image: "/v1/caf/user/image",
  admissions: "/v1/caf/colleges/admissions",
  landing: "/v2/caf/landing",
  cpa_form: "/v2/caf/forms",
  email_verify: "/v1/user/profile/email",
});

function getUrl(name) {
  const path = apiConstants[name];
  if (path) {
    return `${baseApi}${apiConstants[name]}`;
  } else if (name === "base") {
    return baseUrl;
  } else if (name === "storage") {
    return storageUrl;
  }
  return null;
}

export default getUrl;
