import React, { useState } from "react";
import CoursesOffered from "./CoursesOffered";
import { Select } from "antd";
import { Row, Col, Button } from "antd";
const { Option } = Select;
const CourseCardHOF = ({ college_id, college_data, data }) => {
  const [optionValue, setOptionValue] = useState(false);
  const [more, setMore] = useState(false);
  const [selectedMore, setSelectedMore] = useState(false);
  const handleShowData = (e) => {
    e.preventDefault();
    setMore(!more);
    window.scrollTo(0, 600);
  };
  const handleSelectedMore = (e) => {
    e.preventDefault();
    setSelectedMore(!selectedMore);
    window.scrollTo(0, 600);
  };
  let options = [];
  data.map((ele) => {
    if (options.indexOf(ele.stream) === -1) {
      options.push(ele.stream);
    }
  });
  const handleSelectChange = (value) => {
    if (value === "All") {
      setOptionValue(false);
      return;
    }
    setOptionValue(value);
  };
  const selectItems = options.map((ele, id) => (
    <Option value={ele} key={id}>
      {ele}
    </Option>
  ));
  const totalCourseCards = data.map((data, index) => {
    if (data.course) {
      return (
        <Col xs={24} md={12} key={index}>
          <CoursesOffered
            data={data}
            college_data={college_data}
            college_id={college_id}
          />
        </Col>
      );
    }
  });
  const selectedStream = [];
  data.map((data, index) => {
    if (data.stream === optionValue) {
      if (data.course) {
        selectedStream.push(
          <Col xs={24} md={12} key={index}>
            <CoursesOffered
              data={data}
              college_data={college_data}
              college_id={college_id}
            />
          </Col>
        );
        return false;
      }
    }
  });
  let minimumCards = [];
  for (let i = 0; i <= 5; i++) {
    minimumCards.push(totalCourseCards[i]);
  }
  let minimumSelectedCards = [];
  for (let i = 0; i <= 5; i++) {
    minimumSelectedCards.push(selectedStream[i]);
  }
  const CourseLength = totalCourseCards.length > 5;
  const selectedStreamLength = selectedStream.length > 5;
  return (
    <>
      <div className="course-card-hof-main-div" style={{ margin: "0.5rem" }}>
        <div className="course-search-div">
          <Select
            defaultValue="Select a Stream"
            className="course-search-bar"
            onChange={handleSelectChange}
          >
            <Option value="Select a Stream" disabled hidden>
              Select a Stream
            </Option>
            {optionValue ? <Option value="All">All</Option> : null}
            {selectItems}
          </Select>
        </div>
        <Row gutter={[32, 20]}>
          {more
            ? optionValue
              ? selectedStream
              : totalCourseCards
            : optionValue
            ? minimumSelectedCards
            : minimumCards}
        </Row>
      </div>
      {CourseLength ? (
        optionValue ? (
          selectedStreamLength ? (
            selectedMore ? (
              <div className="show-hide-button-card">
                <Button
                  className="primary-btn show-hide-button"
                  onClick={handleSelectedMore}
                >
                  Hide
                </Button>
              </div>
            ) : (
              <div className="show-hide-button-card">
                <Button
                  className="primary-btn show-hide-button"
                  onClick={handleSelectedMore}
                >
                  View All
                </Button>
              </div>
            )
          ) : null
        ) : more ? (
          <div className="show-hide-button-card">
            <Button
              className="primary-btn show-hide-button"
              onClick={handleShowData}
            >
              Hide
            </Button>
          </div>
        ) : (
          <div className="show-hide-button-card">
            <Button
              className="primary-btn show-hide-button"
              onClick={handleShowData}
            >
              View All
            </Button>
          </div>
        )
      ) : null}
    </>
  );
};
export default CourseCardHOF;
