import React from "react";
import { Checkbox } from "antd";
import isEmpty from "lodash/isEmpty";
import UG from "../profile/UG";
import PG from "../profile/PG";

const ShowAcademic = ({ data = {}, onClose, func_handleApply }) => {
  const checklistData =
    !isEmpty(data) && data.checklist && data.checklist.length
      ? data.checklist
      : [];
  const meta = !isEmpty(data) && data.meta ? data.meta : {};

  const checklistComp = item => {
    return (
      <Checkbox
        checked={item.information_completed}
        style={{ marginBottom: "2rem" }}
        key={item.id}>
        {item.name}
      </Checkbox>
    );
  };

  return (
    <div>
      {checklistData.map((item, index) => {
        switch (item.id) {
          case 4:
          case 5:
          case 6:
            return checklistComp(item);
          case 1:
          case 2:
            return (
              <React.Fragment key={index}>
                {item.id === 1 ? (
                  <UG
                    data={item}
                    meta={meta}
                    func_handleApply={func_handleApply}
                    onClose={onClose}
                  />
                ) : (
                  <PG
                    data={item}
                    meta={meta}
                    func_handleApply={func_handleApply}
                    onClose={onClose}
                  />
                )}
              </React.Fragment>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default ShowAcademic;
