import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CoursesOffered from "./Application-CoursesOffered";
import { Select } from "antd";
import { Row, Col, Button } from "antd";
const img = require("../../../../img/Hurray.svg");
const CourseCardHOF = ({ data, handleApply }) => {
  const history = useHistory();
  const [selectedColleges, setSelectedColleges] = useState(0);
  const handleProceed = () => {
    history.push("/profile");
  };
  const handleSelectedColleges = () => {
    setSelectedColleges(() => selectedColleges + 1);
  };
  const handleRejectCollege = () => {
    setSelectedColleges(() => selectedColleges - 1);
  };
  const { Option } = Select;
  const [optionValue, setOptionValue] = useState(false);
  const [more, setMore] = useState(false);
  const [selectedMore, setSelectedMore] = useState(false);
  const handleShowData = e => {
    e.preventDefault();
    setMore(!more);
  };
  const handleSelectedMore = e => {
    e.preventDefault();
    setSelectedMore(!selectedMore);
  };
  let options = [];
  data.map(ele => {
    if (options.indexOf(ele.stream) === -1) {
      options.push(ele.stream);
    }
  });
  const handleSelectChange = value => {
    if (value === "All") {
      setOptionValue(false);
      return;
    }
    setOptionValue(value);
  };
  const selectItems = options.map((ele, id) => (
    <Option value={ele} key={id}>
      {ele}
    </Option>
  ));
  const totalCourseCards = data.map((data, index) => {
    if (data.course) {
      return (
        <Col xs={24} md={12} key={index}>
          <CoursesOffered
            data={data}
            addCollege={handleSelectedColleges}
            rejectCollege={handleRejectCollege}
            handleApply={handleApply}
          />
        </Col>
      );
    }
  });
  const selectedStream = [];
  data.map((data, index) => {
    if (data.stream === optionValue) {
      if (data.course) {
        selectedStream.push(
          <Col xs={24} md={12} key={index}>
            <CoursesOffered
              data={data}
              addCollege={handleSelectedColleges}
              rejectCollege={handleRejectCollege}
              handleApply={handleApply}
            />
          </Col>
        );
        return false;
      }
    }
  });
  let minimumCards = [];
  for (let i = 0; i <= 5; i++) {
    minimumCards.push(totalCourseCards[i]);
  }
  let minimumSelectedCards = [];
  for (let i = 0; i <= 5; i++) {
    minimumSelectedCards.push(selectedStream[i]);
  }
  const CourseLength = totalCourseCards.length > 6;
  const selectedStreamLength = selectedStream.length > 6;
  return (
    <>
      <div className="course-card-hof-main-div" style={{ margin: "0.5rem" }}>
        {selectedColleges ? (
          <Col xs={24} md={24}>
            <Row
              style={{ backgroundColor: "#3c1a5b", padding: "1rem" }}
              type="flex"
              align="middle">
              <Col xs={0} md={2}>
                <img
                  src={img}
                  alt=""
                  className=""
                  style={{ marginLeft: "1rem" }}
                />
              </Col>
              <Col xs={12} md={18}>
                <p
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    fontSize: "1.6rem",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.25",
                    letterSpacing: "0.35px"
                  }}>
                  <span style={{ color: "#faca45" }}>Success! </span>
                  You have selected{" "}
                  <span style={{ color: "#faca45" }}>
                    {selectedColleges}
                  </span>{" "}
                  courses. You may proceed to applying or select more.
                </p>
              </Col>
              <Col xs={12} md={2}>
                <button
                  onClick={handleProceed}
                  style={{
                    backgroundColor: "#faca45",
                    padding: "1rem 2rem",
                    border: "none",
                    color: "#3c1a5b",
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1.6rem",
                    cursor: "pointer"
                  }}>
                  Proceed
                </button>
              </Col>
            </Row>
          </Col>
        ) : null}
        <div className="course-search-div">
          <Select
            defaultValue="Select a Stream"
            className="course-search-bar"
            onChange={handleSelectChange}>
            <Option value="Select a Stream" disabled hidden>
              Select a Stream
            </Option>
            {optionValue ? <Option value="All">All</Option> : null}
            {selectItems}
          </Select>
        </div>
        <Row gutter={[32, 20]}>
          {more
            ? optionValue
              ? selectedStream
              : totalCourseCards
            : optionValue
            ? minimumSelectedCards
            : minimumCards}
        </Row>
      </div>
      {CourseLength ? (
        optionValue ? (
          selectedStreamLength ? (
            selectedMore ? (
              <div className="show-hide-button-card">
                <Button
                  className="show-hide-button"
                  onClick={handleSelectedMore}>
                  Hide
                </Button>
              </div>
            ) : (
              <div className="show-hide-button-card">
                <Button
                  className="show-hide-button"
                  onClick={handleSelectedMore}>
                  View All
                </Button>
              </div>
            )
          ) : null
        ) : more ? (
          <div className="show-hide-button-card">
            <Button className="show-hide-button" onClick={handleShowData}>
              Hide
            </Button>
          </div>
        ) : (
          <div className="show-hide-button-card">
            <Button className="show-hide-button" onClick={handleShowData}>
              View All
            </Button>
          </div>
        )
      ) : null}
    </>
  );
};
export default CourseCardHOF;
