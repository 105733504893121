import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { Spin } from "antd";
export default function MyLoader({ active, children }) {
  return (
    <LoadingOverlay
      active={active}
      spinner={<Spin />}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "#ffffff",
          opacity: ".7",
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
}
