import React from "react";
import { Collapse } from "antd";
const FAQ = ({ faqs }) => {
  const { Panel } = Collapse;
  const handleChange = () => {};
  const questions = faqs.map((data, index) => (
    <Panel key={index} header={data.que}>
      <div>{data.ans}</div>
    </Panel>
  ));
  return (
    <div className="faq-main-div">
      <h2 className="faq-heading">Frequently Asked Questions !</h2>
      <Collapse
        // defaultActiveKey={["0"]}
        onChange={handleChange}
        expandIconPosition="right">
        {questions}
      </Collapse>
    </div>
  );
};
export default FAQ;
