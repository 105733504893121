import React, { useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { enquireScreen } from "enquire-js";
import { Layout } from "antd";
import SideMenuProfile from "./profile/SideMenuProfile";
import MobileMenu from "./profile/MobileMenu";
const { Content, Footer, Sider } = Layout;

const NewProfile = ({ routes, profileData, pathName }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    enquireScreen(status => setIsMobile(status));
  }, []);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  return (
    <Layout style={{ minHeight: "91vh" }}>
      {!isMobile ? (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <SideMenuProfile pathName={pathName} />
        </Sider>
      ) : null}

      <Layout>
        <Content style={{ padding: "0 2rem", backgroundColor: "white" }}>
          {renderRoutes(routes, { profileProps: profileData })}
        </Content>
        {isMobile ? (
          <MobileMenu pathName={pathName} />
        ) : (
          <Footer
            style={{ textAlign: "center", color: "#3c1a58", padding: "1rem" }}>
            Common Application Form @{currentYear} Powered by EntranceZone
          </Footer>
        )}
      </Layout>
    </Layout>
  );
};

export default NewProfile;
