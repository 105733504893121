import React, { useState, useEffect } from "react";
import axios from "axios";
import getUrl from "../utilities/apiConstant";
import NewProfile from "../components/NewProfile";
import { notification } from "antd";

const Profile = (props) => {
  const {
    route: { routes },
    location: { pathname },
  } = props;

  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          pathname === "/profile/personal-information" ||
          pathname === "/profile/academic-information"
        ) {
          const profileResponse = await axios.get(`${getUrl("profile")}`, {
            withCredentials: true,
          });
          setProfileData(profileResponse.data || {});
        }
      } catch (error) {
        notification.error({ message: "Something went wrong !" });
      }
    };
    fetchData();
  }, [pathname]);

  return (
    <>
      <div className="my-profile">
        <NewProfile
          routes={routes}
          profileData={profileData}
          pathName={pathname}
        />
      </div>
    </>
  );
};

export default Profile;
