import React from "react";
import { Icon } from "antd";
const Footer = () => {
  const handleFooterLinks = () => {
    window.open("https://www.entrancezone.com");
  };
  return (
    <>
      <div className="footer-main-div">
        <div className="footer-main-div__item_1">
          <p className="footer-heading-para">Contact us</p>
          {/* <div className="footer-main-div__item_1__child">
            <Icon type="phone" />
            <p className="footer-main-div__item_1__child__para">(+91) N/A</p>
          </div> */}
          <div className="footer-main-div__item_1__child">
            <Icon type="mail" />
            <a
              href="mailto:caf@entrancezone.com"
              className="footer-main-div__item_1__child__para"
            >
              caf@entrancezone.com
            </a>
          </div>
          <div className="footer-main-div__item_1__child">
            <Icon type="environment" />
            <a
              href="https://www.google.com/maps?q=28.470528, 77.079474"
              rel="nofollow noopener noreferrer"
              target="_blank"
              className="footer-main-div__item_1__child__para"
            >
              3807 Coworking, Sushant Lok - 1, Gurugram
            </a>
          </div>
        </div>
        <div className="footer-main-div__item_2">
          <p className="footer-heading-para" onClick={handleFooterLinks}>
            Policies & Info
          </p>
          <a
            href="terms-and-conditions"
            rel="noopener noreferrer"
            target="_blank"
            className="item__child__para"
          >
            Terms & Conditions
          </a>
          <a
            href="privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
            className="item__child__para"
          >
            Privacy Policy
          </a>
          <a
            href="return-refund-cancellation"
            rel="noopener noreferrer"
            target="_blank"
            className="item__child__para"
          >
            Refund Policy
          </a>
          {/* <a href="pricing" target="_blank" className="item__child__para">
            Pricing
          </a> */}
        </div>
        <div className="footer-main-div__item_3">
          <p className="footer-heading-para">Quick Links</p>
          <a
            href="https://www.entrancezone.com/about-us"
            target="_blank"
            rel="noopener noreferrer"
            className="item__child__para"
          >
            About Us
          </a>
          <a
            href="https://www.entrancezone.com/contact-us"
            rel="noopener noreferrer"
            target="_blank"
            className="item__child__para"
          >
            Contact Us
          </a>
          <a href="colleges" target="_blank" className="item__child__para">
            CAF Colleges
          </a>
          <a href="profile" target="_blank" className="item__child__para">
            My Dashboard
          </a>
        </div>
      </div>
      <div className="footer-main-div__item_4">
        <div className="footer-social-icons">
          <a
            href="https://facebook.com/entrancezone"
            rel="me noopener noreferrer"
            target="_blank"
          >
            <Icon type="facebook" theme="filled" />
          </a>
          <a
            href="https://linkedin.com/company/entrancezone.com"
            rel="me noopener noreferrer"
            target="_blank"
          >
            <Icon type="linkedin" theme="filled" style={{ margin: "0 1rem" }} />
          </a>
          <a
            href="https://youtube.com/entrancezone"
            rel="me noopener noreferrer"
            target="_blank"
          >
            <Icon type="youtube" theme="filled" style={{ margin: "0 1rem" }} />
          </a>
          <a
            href="https://www.instagram.com/entrance_zone/"
            rel="me noopener noreferrer"
            target="_blank"
          >
            <Icon
              type="instagram"
              theme="filled"
              style={{ margin: "0 1rem" }}
            />
          </a>
          <a
            href="https://twitter.com/entrancezone"
            rel="me noopener noreferrer"
            target="_blank"
          >
            <Icon
              type="twitter-circle"
              theme="filled"
              style={{ margin: "0 1rem" }}
            />
          </a>
        </div>
        <div className="footer-copyright-div">
          Copyright © 2020 EntranceZone
        </div>
      </div>
    </>
  );
};
export default Footer;
