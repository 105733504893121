import React, { useState, useEffect } from "react";
import Review from "./Review";
import { Checkbox, Row, Col, Modal } from "antd";
import TermsAndConditions from "../../TermsAndConditions";
const ReviewDisclaimer = ({
  course,
  branches,
  first_year_fee,
  setCheckBox
}) => {
  const [showTermsandCondition, setShowTermsandCondition] = useState(false);
  const CheckboxGroup = Checkbox.Group;
  const handleOnChange = values => {
    setCheckBox(values);
  };
  useEffect(() => {
    setCheckBox(undefined);
  }, [showTermsandCondition]);
  return (
    <>
      <div style={{ position: "relative" }}>
        <h3 className="disclaimer-h3">Application Review</h3>
        <h4 className="review-disclaimer-h4">
          Review your application before proceeding to payment and submission
        </h4>
        <div className="college-selection-review-disclaimer">
          <Review
            course={course}
            first_year_fee={first_year_fee}
            branches={branches}
          />
          <div className="disclaimer-main-div">
            <CheckboxGroup onChange={handleOnChange}>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem"
                  }}
                >
                  <Checkbox value={1}></Checkbox>
                  <p
                    className="disclaimer-checkbox-text"
                    style={{ marginLeft: ".8rem" }}
                  >
                    I agree to all{" "}
                    <span
                      onClick={() => setShowTermsandCondition(true)}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline"
                      }}
                    >
                      Terms and Conditions
                    </span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Checkbox value={2} className="disclaimer-checkbox-text">
                    I hereby declare that all data entered by me is correct
                  </Checkbox>
                </Col>
              </Row>
            </CheckboxGroup>
          </div>
          <Modal
            className="terms-conditions-modal"
            title="Terms and Conditions"
            width={800}
            centered
            footer={null}
            visible={showTermsandCondition}
            onCancel={() => setShowTermsandCondition(false)}
          >
            <TermsAndConditions />
          </Modal>
        </div>
      </div>
    </>
  );
};
export default ReviewDisclaimer;
