import React, { useState, useEffect, createContext } from "react";

export const Context = createContext();

export const UserProvider = ({ data = {}, children }) => {
  const [userData, setUserData] = useState(data || {});
  const [current, setCurrent] = useState(1);
  const { shortlisted_colleges, user = {}, notifications } = userData;
  const {
    is_academic_detail_complete,
    is_personal_detail_complete,
    forms_applied,
    is_email_verified,
    profile_image_exist,
    forms_left,
    active_package_id,
  } = user;
  const [redirect_url, setRedirectUrl] = useState(undefined);
  const [selectedCollegesData, setSelectedColleges] = useState([]);
  const [educationLevel, setEducationLevel] = useState(undefined);
  const [selectedCourse, setSelectedCourse] = useState(undefined);
  const [selectedStream, setSelectedStream] = useState(undefined);
  const [selectedFiltersContext, setSelectedFilterContext] = useState(
    undefined
  );
  const [selectedPackContext, setSelectedPackContext] = useState(undefined);

  const updateContextData = (data) => {
    setUserData(data);
    if (Object.entries(data).length === 0 && data.constructor === Object) {
      setSelectedColleges([]);
      setSelectedPackContext(undefined);
    }
  };

  useEffect(() => {
    if (selectedCollegesData.length) {
      if (shortlisted_colleges && shortlisted_colleges.length) {
        const newSelectedData = selectedCollegesData.concat(
          shortlisted_colleges
        );
        const getUnique = (arr, comp) => {
          const unique = arr
            .map((e) => e[comp])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter((e) => arr[e])
            .map((e) => arr[e]);

          return unique;
        };
        setSelectedColleges(getUnique(newSelectedData, "college_id"));
      }
    } else {
      if (shortlisted_colleges && shortlisted_colleges.length) {
        setSelectedColleges(shortlisted_colleges);
        return;
      }
      setSelectedColleges([]);
      return;
    }
  }, [shortlisted_colleges, userData]);

  const isLogin = () => {
    if (user && user.name) {
      return true;
    } else {
      return false;
    }
  };

  const setCollegeData = (data, value) => {
    switch (value) {
      case "education": {
        setEducationLevel(data);
        break;
      }
      case "stream": {
        setSelectedStream(data);
        break;
      }
      case "query-course": {
        setSelectedCourse(parseInt(data));
        break;
      }
      case "course": {
        const checkData = selectedCollegesData.find(
          (item) => item.college_id === data.college_id
        );
        checkData.course_id = data.course_id;
        const selectedCollegesDataCopy = selectedCollegesData.map((ele) => {
          if (ele.college_id === data.college_id) return checkData;
          else return ele;
        });
        setSelectedColleges(selectedCollegesDataCopy);
        break;
      }
      case "college": {
        const selectedCollegesDataCopy = [...selectedCollegesData];
        const checkData = selectedCollegesDataCopy.find(
          (item) => item.college_id === data.college_id
        );
        if (!checkData) {
          const apiData = {
            ...data,
            education_level_id: educationLevel ? educationLevel.id : undefined,
            // course_id: selectedCourse,
            stream_id: educationLevel ? selectedStream.id : undefined,
          };
          selectedCollegesDataCopy.push(apiData);
        } else {
          const index = selectedCollegesDataCopy.indexOf(checkData);
          selectedCollegesDataCopy.splice(index, 1);
        }
        setSelectedColleges(selectedCollegesDataCopy);
        break;
      }
      case "selectedFilters": {
        setSelectedFilterContext(data);
        break;
      }
      default: {
        break;
      }
    }
  };
  return (
    <Context.Provider
      value={{
        userData,
        profile_image_exist,
        forms_left,
        active_package_id,
        isLogin,
        shortlisted_colleges,
        notifications,
        educationLevel,
        selectedStream,
        selectedCollegesData,
        setCollegeData,
        updateContextData,
        is_academic_detail_complete,
        is_personal_detail_complete,
        forms_applied,
        selectedCourse,
        selectedFiltersContext,
        selectedPackContext,
        setSelectedPackContext,
        redirect_url,
        setRedirectUrl,
        current,
        setCurrent,
      }}
    >
      {children}
    </Context.Provider>
  );
};
