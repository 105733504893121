import React from "react";
import CollegeSelection from "./CollegeSelectionCard";
const ShortlistedCollege = ({ shortlistedData = [] }) => {
  const ShortlistCollege = shortlistedData.map((data, index) => (
    <CollegeSelection data={data} key={index} />
  ));
  if (!shortlistedData.length) {
    return <div />;
  }
  return (
    <div style={{ marginBottom: "4rem" }}>
      <div
        className="shortlisted-recommended-text"
        style={{ marginBottom: "2rem" }}>
        Shortlisted Colleges
      </div>
      {ShortlistCollege}
    </div>
  );
};
export default ShortlistedCollege;
