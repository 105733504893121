import React from "react";
import { Modal } from "antd";
import CAFPhoneAuth from "./Login/CAFPhoneAuth";

const PhoneModal = ({
  showPhoneModal,
  onCancel,
  phone,
  fun_setPhoneNumber,
}) => {
  return (
    <Modal visible={showPhoneModal} onCancel={onCancel} footer={null}>
      <div>
        <CAFPhoneAuth
          type="signin"
          changeHeading="Verify Your Phone No."
          fun_setPhoneNumber={fun_setPhoneNumber}
          phone={phone}
          onCancel={onCancel}
        />
      </div>
    </Modal>
  );
};

export default PhoneModal;
